import React from "react";

import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import CategoryCustomControl from "../../../../../components/custom/CategoryCustomControl/CategoryCustomControl";
import ModalBody from "reactstrap/es/ModalBody";
import * as BB from "../../../../../components/BBComponent";
import {
    Button, Row, DropdownItem,
    CardBody,
    Col,
    Label,
    InputGroup,
    InputGroupAddon,
    Input,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import notify from "devextreme/ui/notify";
import { post } from "axios";
import TaskModel from "../../../../../models/Task";
import {
    callAPI,
    callApiGet,
    callApiPost,
    callApiPut
} from "../../../../../components/Axios";

const taskTermination = (event, t) => {
    //event.props.actions.isLoading(true);
    let ths = event;
    callAPI(
        callApiGet,
        'api/TaskTracking/ReminderJobCount?TaskID=' + event.state.taskID,
        {},
        (result) => {

            if (result.data > 0) {

                BB.askYesNo(t("talebinIcerisindeTamamlanmamısGorevlerMevcutSonlandirmakIstediginizeEminMisiniz"), t(""), t("evet"), t("hayır")).then(ask => {

                    if (ask !== true) {

                    }
                    else {

                        if (event.state.selectedTerminationType === '') {

                            return notify(event.languages.sonlandirmaTuruAlaniZorunludur, "error", 1500);

                        }
                        if (event.state.selectedTerminationDescription === '') {
                            return notify(event.languages.sonlandirmaAciklamasiAlaniZorunludur, "error", 1500);
                        }
                        if (event.state.customElements.length === 0) {

                        }
                        else {
                            if (event.state.selectedCustomControl.length != 0) {

                                for (let i = 0; i > event.state.selectedCustomControl.length; i++) {

                                    if (event.state.selectedCustomControl[i].length != 0) {

                                        if (event.state.selectedCustomControl[i].length === 0) {
                                            return notify(event.languages.ozelAlanZorunlu, "error", 1500);
                                        }
                                    }
                                }
                            }
                            else {
                                return notify(event.languages.ozelAlanZorunlu, "error", 1500);
                            }
                        }
                        let item = new TaskModel();
                        //const tasks = event.state.selectedTasks;
                        //console.log("sonlandirma2", tasks);
                        //for (let task of tasks) {
                        //    let formData = new FormData();
                        //    formData.append('TaskID', task.TaskID);
                        //    // Diğer form alanlarını ekleyin

                        //    const res = await callAPI(callApiPost, 'api/TaskDetail/termination', formData);
                        //    // Hata ve başarı durumu kontrolü...
                        //}
                        const url = `api/TaskDetail/termination`;
                        const formData = new FormData();
                        item.CategoryID = event.state.data.CategoryID;
                        item.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
                        formData.append('jsonText', JSON.stringify(item));
                        formData.append('TaskID', event.state.taskID);
                        formData.append('TerminationTypeID', event.state.selectedTerminationType);
                        formData.append('TerminationDescription', event.state.selectedTerminationDescription);
                        formData.append('NotifyDepartment', event.state.NotifyDepartment);

                        if (event.state.selectedTerminationFile) {

                            for (let i = 0; i < event.state.selectedTerminationFile.length; i++) {
                                formData.append('file', event.state.selectedTerminationFile[i])
                            }
                        }

                        event.props.actions.isLoading(true);
                        const config = { headers: { 'content-type': 'multipart/form-data' } };

                        callAPI(
                            callApiPost,
                            url,
                            formData,
                            async (res) => {
                                if (res.data.Status === 0) {
                                    event.props.actions.isLoading(false);

                                    return notify(res.data.Message, "error", 2000);
                                }
                                else if (res.data.Status === 1) {
                                    notify(event.languages.talepBasariylaSonlandirildi, "success", 2000);
                                    //event.props.actions.getPoolTaskCount();
                                    event.props.actions.isLoading(false);

                                    setTimeout(function () {

                                        if (ths.props.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb') {

                                            ths.props.history.push('/TaskTracking');
                                        }
                                        else {
                                            ths.props.history.push('/OwnTask');
                                        }
                                    }, 500)
                                }
                                else {

                                    notify(res.data.Message, "error", 2000);

                                    event.props.actions.isLoading(false);
                                }
                                event.props.actions.isLoading(false);

                            })
                        event.setState({
                            visibleSonlandir: false,
                            selectedTerminationType: undefined,
                            selectedTerminationTemplate: undefined,
                            selectedTerminationFile: undefined,
                            NotifyDepartment: false,

                            selectedTerminationDescription: '',

                        });
                    }
                })
            }

            else {
                if (event.state.selectedTerminationType === '') {
                    return notify(event.languages.sonlandirmaTuruAlaniZorunludur, "error", 1500);
                }
                if (event.state.selectedTerminationDescription === '') {
                    return notify(event.languages.sonlandirmaAciklamasiAlaniZorunludur, "error", 1500);
                }
                if (event.state.customElements.length === 0) { }
                else {
                    if (event.state.selectedCustomControl.length != 0) {
                        for (let i = 0; i > event.state.selectedCustomControl.length; i++) {
                            if (event.state.selectedCustomControl[i].length != 0) {
                                if (event.state.selectedCustomControl[i].length === 0) {
                                    return notify(event.languages.ozelAlanZorunlu, "error", 1500);
                                }
                            }
                        }
                    }
                    else {
                        return notify(event.languages.ozelAlanZorunlu, "error", 1500);
                    }
                }
                if (event.state.selectedTasks && event.state.selectedTasks.length > 0) {
                    for (let task of event.state.selectedTasks) {
                        let item = new TaskModel();
                        const url = `api/TaskDetail/termination`;
                        const formData = new FormData();

                        item.CategoryID = task.CategoryID;
                        item.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
                        formData.append('jsonText', JSON.stringify(item));
                        formData.append('TaskID', task.TaskID);
                        formData.append('TerminationTypeID', event.state.selectedTerminationType);
                        formData.append('TerminationDescription', event.state.selectedTerminationDescription);
                        formData.append('NotifyDepartment', event.state.NotifyDepartment);

                        if (event.state.selectedTerminationFile) {
                            for (let i = 0; i < event.state.selectedTerminationFile.length; i++) {
                                formData.append('file', event.state.selectedTerminationFile[i]);
                            }
                        }

                        // API'ye task'ı göndermek için çağrıyı yap
                        const config = { headers: { 'content-type': 'multipart/form-data' } };
                        event.props.actions.isLoading(true);

                        callAPI(callApiPost, url, formData, async (res) => {
                            if (res.data.Status === 0) {
                                notify(res.data.Message, "error", 2000);
                            } else if (res.data.Status === 1) {
                                notify(event.languages.talepBasariylaSonlandirildi, "success", 2000);
                            } else {
                                notify(res.data.Message, "error", 2000);
                            }
                            event.props.actions.isLoading(false);
                            event.setState({ visibleSonlandir: false });
                            window.location.reload(true);
                        });
                    }

                    // Tüm task işlemleri bittikten sonra yönlendirme
                    setTimeout(function () {
                        if (ths.props.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb') {
                            ths.props.history.push('/TaskTracking');
                        } else {
                            ths.props.history.push('/OwnTask');
                        }
                    }, 500);

                } else {

                    let item = new TaskModel();

                    const url = `api/TaskDetail/termination`;
                    const formData = new FormData();
                    item.CategoryID = event.state.data.CategoryID;
                    item.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
                    formData.append('jsonText', JSON.stringify(item));
                    formData.append('TaskID', event.state.taskID);
                    formData.append('TerminationTypeID', event.state.selectedTerminationType);
                    formData.append('TerminationDescription', event.state.selectedTerminationDescription);
                    formData.append('NotifyDepartment', event.state.NotifyDepartment);

                    if (event.state.selectedTerminationFile) {

                        for (let i = 0; i < event.state.selectedTerminationFile.length; i++) {
                            formData.append('file', event.state.selectedTerminationFile[i])
                        }
                    }

                    event.props.actions.isLoading(true);
                    const config = { headers: { 'content-type': 'multipart/form-data' } };

                    callAPI(
                        callApiPost,
                        url,
                        formData,
                        async (res) => {
                            if (res.data.Status === 0) {
                                event.props.actions.isLoading(false);

                                return notify(res.data.Message, "error", 2000);
                            }
                            else if (res.data.Status === 1) {
                                notify(event.languages.talepBasariylaSonlandirildi, "success", 2000);
                                //event.props.actions.getPoolTaskCount();
                                event.props.actions.isLoading(false);

                                setTimeout(function () {

                                    if (ths.props.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb') {

                                        ths.props.history.push('/TaskTracking');
                                    }
                                    else {
                                        ths.props.history.push('/OwnTask');
                                    }
                                }, 500)
                            }
                            else {

                                notify(res.data.Message, "error", 2000);

                                event.props.actions.isLoading(false);
                            }
                            event.props.actions.isLoading(false);

                        })
                    event.setState({
                        visibleSonlandir: false,
                        selectedTerminationType: undefined,
                        selectedTerminationTemplate: undefined,
                        selectedTerminationFile: undefined,
                        NotifyDepartment: false,

                        selectedTerminationDescription: '',

                    });
                }
                //debugger
                //let item = new TaskModel();
                //const tasks = event.state.selectedTasks;
                //if (event.state.taskID ==null ) {

                //    event.state.taskID = tasks[0].TaskID;
                //}

                //console.log("deneme", event.state.taskID);
                //console.log("sonlandirma2", tasks);
                //const url = `api/TaskDetail/termination`;
                //const formData = new FormData();
                //item.CategoryID = event.state.data.CategoryID;
                //item.CustomControlForms = event.state.selectedCustomControl.length > 0 ? event.state.selectedCustomControl : null;
                //formData.append('jsonText', JSON.stringify(item));
                //formData.append('TaskID', event.state.taskID);
                //formData.append('TerminationTypeID', event.state.selectedTerminationType);
                //formData.append('TerminationDescription', event.state.selectedTerminationDescription);
                //formData.append('NotifyDepartment', event.state.NotifyDepartment);
                //if (event.state.selectedTerminationFile) {
                //    for (let i = 0; i < event.state.selectedTerminationFile.length; i++) {
                //        formData.append('file', event.state.selectedTerminationFile[i])
                //    }
                //}

                //event.props.actions.isLoading(true);
                //const config = { headers: { 'content-type': 'multipart/form-data' } };

                //callAPI(
                //    callApiPost,
                //    url,
                //    formData,
                //    async (res) => {
                //        if (res.data.Status === 0) {
                //            event.props.actions.isLoading(false);

                //            return notify(res.data.Message, "error", 2000);
                //        }
                //        else if (res.data.Status === 1) {
                //            notify(event.languages.talepBasariylaSonlandirildi, "success", 2000);
                //            event.props.actions.isLoading(false);
                //            setTimeout(function () {
                //                if (ths.props.loginUser.Role !== 'd45342ab-14d1-e811-80f6-005056b839bb') {
                //                    ths.props.history.push('/TaskTracking');
                //                }
                //                else {
                //                    ths.props.history.push('/OwnTask');
                //                }
                //            }, 500)
                //        }
                //        else {
                //            notify(res.data.Message, "error", 2000);
                //            event.props.actions.isLoading(false);
                //        }
                //        event.props.actions.isLoading(false);
                //    })

                //event.setState({
                //    visibleSonlandir: false,
                //    selectedTerminationType: undefined,
                //    selectedTerminationTemplate: undefined,
                //    selectedTerminationFile: undefined,
                //    NotifyDepartment: false,
                //    selectedTerminationDescription: '',
                //});
            }
        },
        true
    );
}

const clearTerminationForm = (event) => {
    event.setState({
        visibleSonlandir: false,
        selectedTerminationType: undefined,
        selectedCustomControl: [],
        selectedTerminationTemplate: undefined,
        selectedTerminationFile: undefined,
        NotifyDepartment: false,
        selectedTerminationDescription: ''
    });
}

const TaskTerminationModal = ({ t, event }) => {
    return (
        <Modal
            centered={true}
            isOpen={event.state.visibleSonlandir}
        >
            <ModalHeader>
                {t('talepSonlandir')}
            </ModalHeader>

            <ModalBody>
                {BB.DxDropDownWithURL2('selectedTerminationType', t('sonlandirmaTuru'), event, event.state.terminationStatus, t('lütfenseçiniz'))}
                {BB.DxDropDownWithURL2('selectedTerminationTemplate', t('taslak'), event, event.state.terminateTemplate, t('lütfenseçiniz'))}
                {
                    <CategoryCustomControl customElementsData={event.state.customElements} event={event} />
                }
                {BB.TextAreaWithLabel('selectedTerminationDescription', t('sonlandirmaAciklamasi'), '', 6, event)}

                {BB.MultipleFileWithLabel3(
                    "selectedTerminationFile",
                    t("sonlandirmaDosyasi"),
                    event,

                )}
                {event.state.selectedTerminationFile?.length > 0 && (
                    <ListGroup style={{ overflowY: "scroll", height: "100px", width: "320px", display: event.state.fileUploaderVisible }}>
                        {event.state.selectedTerminationFile?.map((filter) => (
                            <ListGroupItem style={{ padding: 6 }}>
                                {filter.name}
                                {<Label
                                    color="danger"
                                    onClick={() => event.deleteSelectedTerminationFile(filter.name)}
                                    className="ml-2"
                                    style={{ cursor: "pointer" }}
                                >
                                    x
                                </Label>}
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
                {BB.CheckBoxWithLabel('NotifyDepartment', t('departmanabildir'), event)}
                <Row style={{ float: 'right' }}>
                    <Button
                        color={'info'}
                        onClick={() => clearTerminationForm(event)}>
                        {t('vazgec')}
                    </Button>
                    <Button
                        disabled={event.state.selectedTerminationType === undefined}
                        color={'success'}
                        style={{ marginLeft: 20, marginRight: 15 }}
                        onClick={() => taskTermination(event, t)}>
                        {t('sonlandir')}
                    </Button>
                </Row>
            </ModalBody>

        </Modal>
    )
}
export default TaskTerminationModal;
