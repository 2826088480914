import {
    Button,
    ButtonGroup,
    Col,
    FormGroup,
    Input,
    Label,
    PopoverBody,
    Row,
    textarea,
    UncontrolledPopover,
    ListGroup,
    ListGroupItem
} from "reactstrap";
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import React, { useState, useEffect } from "react";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import { SelectBox } from "devextreme-react/select-box";
import { CheckBox } from 'devextreme-react/check-box';
import DataGrid, {
    Column,
    ColumnChooser,
    Export,
    FilterPanel,
    FilterRow,
    GroupPanel,
    HeaderFilter,
    MasterDetail,
    Pager,
    Paging,
    SearchPanel,
    Sorting,
    FilterBuilderPopup,
    StateStoring,
    Selection
} from "devextreme-react/data-grid";
import { DateBox } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import Swal from "sweetalert2";
import metrics from "../common/metrics";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Template } from "devextreme-react/core/template";
import HtmlEditor, {
    Item,
    MediaResizing,
    Toolbar,
} from "devextreme-react/html-editor";
import ColorBox from "devextreme-react/color-box";
import { signOut } from "../containers/DefaultLayout";
import { loadMessages } from "devextreme/localization";
import TextBox from "devextreme-react/text-box";
import { callAPI, callApiGet } from "../components/Axios";
import { t } from "i18next";
import notify from "devextreme/ui/notify";

const { htmlToText } = require("html-to-text");
const TaskGrid = () => {
    const [selectedRows, setSelectedRows] = useState([]); // selectedRows burada tanımlandı
    const [data, setData] = useState([]); // Grid verisi
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        // Sayfa yüklendiğinde seçimleri sıfırla
        setSelectedRows([]);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // ekran boyutuna göre isMobile güncellenir
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize); // temizlik
    }, []);
    return (
        <DataGrid
            dataSource={data}
            keyExpr="id"
            selectedRowKeys={selectedRows} // Seçili satırlar state'e bağlı
            onSelectionChanged={(e) => {
                // Seçim değiştiğinde selectedRows state'i güncellenir
                setSelectedRows(e.selectedRowKeys);
            }}
        />
    );
};
export default TaskGrid;
const localizationDevExtreme = {
    tr: {
        "dxHtmlEditor-background": "Arkaplan",
        "dxHtmlEditor-bold": "Kalın",
        "dxHtmlEditor-color": "Renk",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Eğik",
        "dxHtmlEditor-link": "Bağlantı",
        "dxHtmlEditor-image": "Resim",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Üstünü Çiz",
        "dxHtmlEditor-underline": "Altını Çiz",
        "dxHtmlEditor-blockquote": "Alıntı",
        "dxHtmlEditor-header": "Başlık",
        "dxHtmlEditor-orderedList": "Sıralı Liste",
        "dxHtmlEditor-bulletList": "Noktalı Liste",
        "dxHtmlEditor-alignLeft": "Sola Yasla",
        "dxHtmlEditor-alignCenter": "Ortala",
        "dxHtmlEditor-alignRight": "Sağa Yasla",
        "dxHtmlEditor-alignJustify": "Satıra Sığdır",
        "dxHtmlEditor-codeBlock": "Kod Bloğu",
        "dxHtmlEditor-undo": "Geri Al",
        "dxHtmlEditor-redo": "İleri Al",
        "dxHtmlEditor-clear": "Temizle",
        "dxHtmlEditor-insertTable": "Tablo Oluştur",
        "dxHtmlEditor-insertRowAbove": "Üste Satır Ekle",
        "dxHtmlEditor-insertRowBelow": "Alta Satır Ekle",
        "dxHtmlEditor-insertColumnLeft": "Sola Kolon Ekle",
        "dxHtmlEditor-insertColumnRight": "Sağa Kolon Ekle",
        "dxHtmlEditor-deleteColumn": "Kolonu Sil",
        "dxHtmlEditor-deleteRow": "Satırı Sil",
        "dxHtmlEditor-deleteTable": "Tabloyu Sil",
        "dxDataGrid-columnChooserTitle": "Sütun Seçici",
        "dxDataGrid-columnChooserEmptyText": "Sütunu gizlemek için buraya sürükleyin",
        "dxDataGrid-groupContinuesMessage": "Sonraki sayfada devam ediyor",
        "dxDataGrid-groupContinuedMessage": "Önceki sayfadan devam ediyor",
        "dxDataGrid-editingEditRow": "Düzenle",
        "dxDataGrid-editingSaveRowChanges": "Kaydet",
        "dxDataGrid-editingCancelRowChanges": "İptal",
        "dxDataGrid-editingDeleteRow": "Sil",
        "dxDataGrid-editingUndeleteRow": "Geri Al",
        "dxDataGrid-editingConfirmDeleteMessage": "Bu kaydı silmek istediğinize emin misiniz?",
        "dxDataGrid-validationCancelChanges": "Değişiklikleri iptal et",
        "dxDataGrid-groupPanelEmptyText": "Bir sütunu buraya sürükleyerek gruplandır",
        "dxDataGrid-noDataText": "Gösterilecek veri yok",
        "dxDataGrid-searchPanelPlaceholder": "Ara...",
        "dxDataGrid-filterRowShowAllText": "(Hepsi)",
        "dxDataGrid-filterRowResetOperationText": "Sıfırla",
        "dxDataGrid-filterRowOperationEquals": "Eşittir",
        "dxDataGrid-filterRowOperationNotEquals": "Eşit Değil",
        "dxDataGrid-filterRowOperationLess": "Küçüktür",
        "dxDataGrid-filterRowOperationLessOrEquals": "Küçük veya Eşittir",
        "dxDataGrid-filterRowOperationGreater": "Büyüktür",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Büyük veya Eşittir",
        "dxDataGrid-filterRowOperationStartsWith": "Başlar ile",
        "dxDataGrid-filterRowOperationContains": "İçerir",
        "dxDataGrid-filterRowOperationNotContains": "İçermez",
        "dxDataGrid-filterRowOperationEndsWith": "Biter ile",
        "dxDataGrid-applyFilterText": "Filtreyi uygula",
        "dxDataGrid-trueText": "Doğru",
        "dxDataGrid-falseText": "Yanlış",
        "dxDataGrid-sortingAscendingText": "Artan Sıralama",
        "dxDataGrid-sortingDescendingText": "Azalan Sıralama",
        "dxDataGrid-sortingClearText": "Sıralamayı Temizle",
        "dxDataGrid-editingAddRow": "Satır Ekle",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "{1} için min: {0}",
        "dxDataGrid-summaryMax": "Maks: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "{1} için maks: {0}",
        "dxDataGrid-summaryAvg": "Ort: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "{1} için ort: {0}",
        "dxDataGrid-summarySum": "Toplam: {0}",
        "dxDataGrid-summarySumOtherColumn": "{1} için toplam: {0}",
        "dxDataGrid-summaryCount": "Sayım: {0}",
    },
};

let ths;
let languages = undefined;
let loginUser = undefined;

export const askYesNo = (title, content, confirmButtonText, cancelButtonText) =>
    new Promise((resolve) => {
        Swal.fire({
            title: title,
            text: content,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#4797e3",
            cancelButtonColor: "#e06464",
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
        }).then((result) => {
            resolve(result.value);
        });
    });
export const timeoutPopup = (content, type, confirmButtonText, interval) =>
    new Promise(() => {
        let timerInterval;

        Swal.fire({
            icon: type,
            html: content,
            timer: interval,
            timerProgressBar: true,
            confirmButtonText: confirmButtonText,
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result) => null);
    });
export const InputWithLabel = (id, text, type, event, placeHolder, min) => {
    if (min) {
        return (
            <FormGroup>
                {text && (
                    <Label htmlFor={id}>
                        <strong>{text}</strong>
                    </Label>
                )}
                <Input
                    placeholder={placeHolder}
                    min={min}
                    type={type}
                    id={id}
                    value={event.state[id] || ""}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup>
                {text && (
                    <Label htmlFor={id}>
                        <strong>{text}</strong>
                    </Label>
                )}
                <Input
                    placeholder={placeHolder}
                    type={type}
                    id={id}
                    value={event.state[id] || ""}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    }
};
export const InputWithoutLabelProjectNumber = (id, type, event, disabled) => {
    if (disabled) {
        return (
            <FormGroup className={"m-0"}>
                <Input
                    disabled
                    type={type}
                    id={id}
                    style={{
                        marginTop: -5,
                        border: "none",
                        borderBottom: "1px solid gray",
                        width: 200,
                    }}
                    value={event.state[id] || ""}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup className={"m-0"}>
                <Input
                    type={type}
                    id={id}
                    style={{
                        marginTop: -5,
                        border: "none",
                        borderBottom: "1px solid gray",
                        width: 200,
                    }}
                    value={event.state[id] || ""}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    }
};

export const InputWithoutLabelEffort = (id, type, event, disabled) => {
    if (disabled) {
        return (
            <FormGroup className={"m-0"}>
                <Input
                    disabled
                    type={type}
                    id={id}
                    style={{
                        marginTop: -5,
                        border: "none",
                        borderBottom: "1px solid gray",
                        width: 100,
                    }}
                    value={event.state[id] || ""}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup className={"m-0"}>
                <Input
                    type={type}
                    id={id}
                    style={{
                        marginTop: -5,
                        border: "none",
                        borderBottom: "1px solid gray",
                        width: 100,
                    }}

                    value={event.state[id] || event.state.effor}
                    onChange={(text) => event.handleChange(id, text.target.value)}
                />
            </FormGroup>
        );
    }
};


export const ColorSelectWithLabel = (id, text, event) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <ColorBox
                defaultValue={event.state[id]}
                onValueChanged={(e) => event.setState({ [id]: e.value })}
            />
        </FormGroup>
    );
};

export const FileWithLabel = (id, text, event) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <Input
                type="file"
                id={id}
                onChange={(e) => {

                    let files = e.target.files;
                    event.handleChange(id, files[0]);


                }}
            />
        </FormGroup>
    );
};
export const FileWithLabelByResolveFile = (id, text, event) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <Input
                type="file"
                id={id}
                onChange={(e) => {
                    var myArray = [];
                    var file = {};
                    let files = e.target.files;
                    for (var i = 0; i < files.length; i++) {

                        file = {
                            'lastMod': files[i].lastModified,
                            'lastModDate': files[i].lastModifiedDate,
                            'name': files[i].name,
                            'size': files[i].size,
                            'type': files[i].type,
                        }

                        //add the file obj to your array
                        myArray.push(file)
                    }

                    event.handleChange(id, JSON.stringify(myArray[0]));

                }}
            />
        </FormGroup>
    );
};
export const MultipleFileWithLabelIsDisable = (id, text, event) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <Input
                type="file"
                id={id}
                multiple
                onChange={(e) => {
                    let arr = [];
                    let lenght = e.target.files.length;
                    let files = e.target.files;

                    for (let i = 0; i < lenght; i++) {
                        arr.push(files[i]);
                    }

                    event.handleChange(id, arr);
                }}
                disabled
            />
        </FormGroup>
    );
};
export const MultipleFileWithLabel = (id, text, event) => {
    return (

        <FormGroup>

            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>

            <Input

                type="file"
                id={id}
                multiple
                onChange={(e) => {


                    let lenght = e.target.files.length;
                    let files = e.target.files;

                    for (let i = 0; i < lenght; i++) {
                        event.state.ComponentFileUpload.push(files[i]);
                    }

                    event.handleChange(id, event.state.ComponentFileUpload);
                }}
            />

        </FormGroup>
    );
};

const handleClick = event => {
    var hiddenFileInput = document.getElementById("selectedTaskFiles");

    hiddenFileInput.click();
};


export const MultipleFileWithLabel2 = (id, text, event, selectFile) => {
    return (

        <FormGroup>



            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>


            <div>
                <Button onClick={handleClick}> {selectFile}</Button>
            </div>





            <Input htmlFor={id}

                type="file"
                id={id}
                multiple
                style={{ display: 'none' }}
                onChange={(e) => {


                    let lenght = e.target.files.length;
                    let files = e.target.files;

                    for (let i = 0; i < lenght; i++) {
                        if (files[i].size > 10240000) {
                            notify(t("eklemeyeCalistiginizDosyaBoyutu"), "warning", 3000)
                        } else {
                            event.state.ComponentFileUpload.push(files[i]);
                        }
                    }
                    var idd = "selectedTaskFilesState";
                    var block = "block";
                    event.handleChange(idd, block);

                    event.handleChange(id, event.state.ComponentFileUpload);


                }}
            />


        </FormGroup>
    );
};


export const MultipleFileWithLabel4 = (id, text, event) => {
    return (

        <FormGroup>
            <Label >
                <strong>Eski Dosyalar</strong>
            </Label>
            <Input htmlFor={id}

                type="file"
                id={id}
                multiple
                style={{ display: 'none' }}

            />


        </FormGroup>
    );
};



const handleClick2 = event => {
    var hiddenFileInput = document.getElementById("selectedTerminationFile");

    hiddenFileInput.click();
};


export const MultipleFileWithLabel3 = (id, text, event) => {
    return (

        <FormGroup>



            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>


            <div>
                <Button onClick={handleClick2}> Dosya Seç</Button>
            </div>





            <Input htmlFor={id}

                type="file"
                id={id}
                multiple
                style={{ display: 'none' }}
                onChange={(e) => {


                    let lenght = e.target.files.length;
                    let files = e.target.files;

                    for (let i = 0; i < lenght; i++) {
                        event.state.ComponentFileUpload.push(files[i]);
                    }
                    var idd = "selectedTaskFilesState";
                    var block = "block";
                    event.handleChange(idd, block);

                    event.handleChange(id, event.state.ComponentFileUpload);


                }}
            />


        </FormGroup>
    );
};


export const CheckBoxWithLabel = (id, text, event, isDisable, isChecked) => {
    if (event.state[id] === true) {
        return (
            <FormGroup>
                <Label htmlFor={id}>
                    <strong>{text}</strong>
                </Label>
                <Input
                    key={323}
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    id={id}
                    checked={event.state[id]}
                    disabled={isDisable}
                    onChange={(e) => event.handleChange(id, e.target.value)}
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup>
                <Label htmlFor={id}>
                    <strong>{text}</strong>
                </Label>
                <Input
                    key={1213}
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    id={id}
                    checked={event.state[id]}
                    disabled={isDisable}
                    onChange={(e) => event.handleChange(id, e.target.value)}
                />
            </FormGroup>
        );
    }
};

export const CheckBoxWithLabelFormDesign = (id, text, event, isDisable, isChecked) => {
    if (event.state[id] === true) {
        return (
            <FormGroup>
                <Label htmlFor={id}>
                    <strong>{text}</strong>
                </Label>
                <Input
                    key={323}
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    id={id}
                    checked={isChecked}
                    disabled={isDisable}
                    onChange={(e) => event.handleChange(id, e.target.value)}
                />
            </FormGroup>
        );
    } else {
        return (
            <FormGroup>
                <Label htmlFor={id}>
                    <strong>{text}</strong>
                </Label>
                <Input
                    key={1213}
                    style={{ marginLeft: 10 }}
                    type="checkbox"
                    id={id}
                    checked={isChecked}
                    disabled={isDisable}
                    onChange={(e) => event.handleChange(id, e.target.value)}
                />
            </FormGroup>
        );
    }
};
export const DateSelectWithLabel = (
    id,
    text,
    event,
    placeholder,
    isDisable
) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <DateBox
                disabled={isDisable !== undefined ? isDisable : false}
                value={event.state[id]}
                placeholder={placeholder}
                displayFormat="dd.MM.yyyy"
                showClearButton={true}
                min={new Date()}
                onValueChanged={(e) => event.handleChange(id, e.value)}
                pickerType="calendar"
            />
        </FormGroup>
    );
};
export const TimeSelectWithLabel = (id, text, event, isDisable) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>

            <DateBox
                defaultValue={event.state[id]}
                readOnly={isDisable !== undefined ? isDisable : false}
                onValueChanged={(e) => event.handleChange(id, e.value)}
                type="time"
            />
        </FormGroup>
    );
};
export const DateTimeSelectWithLabel = (id, text, event) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <DateBox
                value={event.state[id]}
                displayFormat="dd-MM-yyyy HH:mm"
                showClearButton={true}
                type="datetime"
                onValueChanged={(e) => event.handleChange(id, e.value)}
                pickerType="calendar"
            />
        </FormGroup>
    );
};
export const DateTimeSelectWithLabelMain = (id, text, event, placeholder) => {
    return (
        <FormGroup className="main-date-time">
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <DateBox
                value={event.state[id]}
                placeholder={placeholder}
                displayFormat="dd-MM-yyyy HH:mm"
                showClearButton={true}
                type="datetime"
                onValueChanged={(e) => event.handleChange(id, e.value)}
                pickerType="calendar"
            />
        </FormGroup>
    );
};
export const NewDateTimeSelectWithLabel = (id, text, event, placeholder) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <DateBox
                value={event.state[id]}
                placeholder={placeholder}
                displayFormat="dd/MM/yyyy HH:mm"
                showClearButton={true}
                type="datetime"
                defaultValue={new Date()}
                onValueChanged={(e) => event.handleChange(id, e.value)}
                pickerType="calendar"
            />
        </FormGroup>
    );
};
export const DegreeOfUrencyButtons = (
    id,
    text,
    event,
    low,
    mid,
    high,
    state1,
    isDisable
) => {
    return (
        <FormGroup>
            <Row>
                <Label style={{ marginLeft: 15 }}>
                    <strong>{text}</strong>
                </Label>
            </Row>
            <ButtonGroup>
                <Button
                    disabled={isDisable !== undefined ? isDisable : false}
                    color="dark"
                    onClick={() => event.handleChange(id, 0)}
                    className={`${state1 !== 0 && state1 !== undefined && "opacity-task"
                        }`}
                >
                    {low}
                </Button>
                <Button
                    disabled={isDisable !== undefined ? isDisable : false}
                    color="warning"
                    onClick={() => event.handleChange(id, 1)}
                    className={`${state1 !== 1 && state1 !== undefined && "opacity-task"
                        }`}
                >
                    {mid}
                </Button>
                <Button
                    disabled={isDisable !== undefined ? isDisable : false}
                    color="danger"
                    onClick={() => event.handleChange(id, 2)}
                    className={`${state1 !== 2 && state1 !== undefined && "opacity-task"
                        }`}
                >
                    {high}
                </Button>
            </ButtonGroup>
        </FormGroup>
    );
};

export const TextAreaWithLabel2 = (
    id,
    text,
    placeholder,
    rows,
    event,
    bgColor,
    isDisable
) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <textarea
                readOnly={isDisable !== undefined ? isDisable : false}
                style={{
                    backgroundColor: bgColor !== null ? bgColor : "white",
                    display: "block",
                    width: "100%",
                    lineHeight: "0.5",
                }}
                value={event.state[id]}
                rows={rows}
                placeholder={placeholder}
                className="form-control"
                id={id}
                onChange={(text) => {
                    event.handleChange(id, text.target.value);
                }}
            />
        </FormGroup>
    );
};
export const TextAreaWithLabel = (
    id,
    text,
    placeholder,
    rows,
    event,
    bgColor,
    isDisable
) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <textarea
                readOnly={isDisable !== undefined ? isDisable : false}
                style={{ backgroundColor: bgColor !== null ? bgColor : "white" }}
                value={event.state[id]}
                rows={rows}
                placeholder={placeholder}
                className="form-control"
                id={id}
                onChange={(text) => {
                    event.handleChange(id, text.target.value);
                }}
            />
        </FormGroup>
    );
};

export const TextAreaWithMetrics = (
    id,
    text,
    placeholder,
    rows,
    event,
    bgColor,
    isDisable
) => {
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <textarea
                readOnly={isDisable !== undefined ? isDisable : false}
                style={{ backgroundColor: bgColor !== null ? bgColor : "white" }}
                rows={rows}
                placeholder={placeholder}
                className="form-control"
                id={id}
                onChange={(e) =>
                    (metrics.selectedDegreeOfUrencyDescription = e.target.value)
                }
            />
        </FormGroup>
    );
};

export const TextArea = (id, text, placeholder, rows, event, bgColor) => {
    return (
        <FormGroup>
            <textarea
                style={{ backgroundColor: bgColor !== null ? bgColor : "white" }}
                value={event.state[id] ? event.state[id] : ""}
                rows={rows}
                placeholder={placeholder}
                className="form-control"
                id={id}
                onChange={(text) => {
                    event.handleChange(id, text.target.value);
                }}
            />
        </FormGroup>
    );
};
export const Text = (text) => {
    return (
        <FormGroup>
            <strong>{text}</strong>
        </FormGroup>
    );
};
export const TranslationRender = (header) => {
    const { t } = useTranslation();
    const data = header;
    return t(data.header);
};
export const RenderDetailTemplate = (ths) => {
    const { t } = useTranslation();
    const event = ths.ths;
    const data = event.props.data;

    return (
        <React.Fragment>
            <div className={"card custom-card mb-0"} style={{ boxShadow: "none" }}>
                <div className={"card-body user-card p-0"}>
                    <Row className=" text-center">
                        <div className="col-sm-6 col-md-6 col-xl-2 border-right">
                            <div className="mt-2">
                                <h6 className="mb-1 font-weight-bold">
                                    {data.data.CreateUserName}{" "}
                                </h6>
                                <p className="mb-1 tx-inverse">{t("talepSahibi")}</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-2 border-right">
                            <div className="mt-2">
                                <h6 className="mb-1 font-weight-bold">
                                    {data.data.CompanyName}
                                </h6>
                                <p className="mb-1 tx-inverse">{t("firma")} </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-2 border-right">
                            <div className="mt-2">
                                <h6 className="mb-1 font-weight-bold">
                                    {data.data.DepartmentName}
                                </h6>
                                <p className="mb-1 tx-inverse">{t("departman")} </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-2 border-right">
                            <div className="mt-2">
                                <h6 className="mb-1 font-weight-bold">
                                    {data.data.DepartmentJobName}
                                </h6>
                                <p className="mb-1 tx-inverse">{t("görev")} </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-2 border-right">
                            <div className="mt-2">
                                <h6 className="mb-1 font-weight-bold">
                                    {data.data.CategoryName}{" "}
                                </h6>
                                <p className="mb-1 tx-inverse">{t("kategori")}</p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-xl-2">
                            <div className="mt-2">{event.renderActionButtons()}</div>
                        </div>
                    </Row>
                    <hr />
                    <Row>
                        <Col lg="12">
                            <div className="label-custom">
                                <label className="main-content-label mb-2 pt-1">{t("açıklama")}</label>
                                <span className="d-block tx-12 mb-2 text-muted">
                                    {data.data.NewDescription.substr(0, 200)}
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {/*<Col xl={2}>*/}
            {/*
         {/*</Col>*/}
            {/*<Col xl={2}>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('talepSahibi')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.CreateUserName}</Col>*/}
            {/*   </Row>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('firma')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.CompanyName}</Col>*/}
            {/*   </Row>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('departman')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.DepartmentName}</Col>*/}
            {/*   </Row>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('görev')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.DepartmentJobName}</Col>*/}
            {/*   </Row>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('kategori')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.CategoryName}</Col>*/}
            {/*   </Row>*/}
            {/*</Col>*/}
            {/*<Col xl={8}>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('aciliyet')}</Col>*/}
            {/*      <Col*/}
            {/*         style={{textAlign: 'start'}}>{data.data.DegreeOfUrgency === 0 ? t('düşük') : data.data.DegreeOfUrgency === 1 ? t('orta') : t('yüksek')}</Col>*/}
            {/*   </Row>*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('etki')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.Effect}</Col>*/}
            {/*   </Row>*/}
            {/*   /!*{data.data.TerminationDescription !== null && TextAreaForDetailTemplate(t('kapanisaciklamasi'), data.data.NewDescription)}*!/*/}
            {/*   <Row className='d-flex justify-content-between'>*/}
            {/*      <Col style={{textAlign: 'end'}} className='font-weight-bold'>{t('açıklama')}</Col>*/}
            {/*      <Col style={{textAlign: 'start'}}>{data.data.NewDescription.substr(0, 30)}</Col>*/}
            {/*   </Row>*/}
            {/*</Col>*/}
        </React.Fragment>
    );
};
export const makeAsyncDataSource = (url) => {
    return new CustomStore({
        loadMode: "raw",
        key: "ID",
        load: function () {
            return FetchJSON(url).then((data) => {
                //localStorage.setItem('loginTime', new Date())
                return data;
            });
        },
    });
};
export const DxDataGridForTaskLog = (data, event) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;
    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            onToolbarPreparing={event.onToolbarPreparing3}

            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <SearchPanel visible={true} width={100} searchVisibleColumnsOnly={true} />
            <Export enabled={true} fileName={t("talepIslemeListesi")} />

            <Column
                dataField="Status.Name"
                caption={t("talepDurumu")}
                width={"13%"}
                allowFiltering={true}
            />
            <Column
                caption={t("olusturmaTarihi")}
                dataField="CreateDate"
                width={"25%"}
                format={"yyyy-MM-dd HH:mm"}
                dataType="date"
                allowFiltering={true}
            />
            <Column
                caption={t("kaynak")}
                cellRender={sourceNameSurname}
                width={"7%"}
                allowFiltering={true}
            />
            <Column
                caption={t("hedef")}
                cellRender={targetNameSurname}
                width={"7%"}
                allowFiltering={true}
            />
            <Column
                caption={t("açıklama")}
                cellRender={(e) => description(e, event)}
                width={"55%"}
            />
            <Column caption={t("döküman")} cellRender={documentRender} width={"8%"}/>
        </DataGrid>
    );
};
export const DxDataGridForTaskLogDescription = (data, event, isMobile) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;
    isMobile = event.state.isMobile;
    console.log("ismobile", isMobile);
    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <SearchPanel visible={true} width={100} searchVisibleColumnsOnly={true} />
            <Export enabled={true} fileName={t("talepIslemeListesi")} />
            <Column
                caption={t("olusturmaTarihi")}
                dataField="CreateDate"
                width={"25%"}
                format={"yyyy-MM-dd HH:mm"}
                dataType="date"
                allowFiltering={true}
                visible={!isMobile}
            />
            <Column
                caption={t("olusturan")}
                cellRender={sourceNameSurname}
                width={"10%"}
                allowFiltering={true}
                visible={!isMobile}
            />
            <Column
                caption={t("hedef")}
                cellRender={targetNameSurname}
                width={"7%"}
                allowFiltering={true}
                visible={!isMobile}
            />

            <Column
                caption={t("açıklama")}
                cellRender={(e) => description(e, event)}
                width={"55%"}
                visible={true}
            />
            <Column
                caption={t("döküman")}
                cellRender={documentRender}
                width={"10%"}
                visible={!isMobile}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonsForUpdateDescription(e, event, t)}
                width={"6%"}
                visible={!isMobile}

            />
        </DataGrid>
    );
};

export const DxDataGridRecentlyViewed = (data, event) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;

    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <SearchPanel visible={true} width={100} />
            <Export enabled={true} fileName={t("talepIslemeListesi")} />

            <Column
                dataField="CreateUser"
                caption={t("goruntuleyenKullanici")}
                allowFiltering={true}
            />
            <Column
                caption={t("goruntulemeTarihi")}
                dataField="CreateDate"
                format={"yyyy-MM-dd HH:mm"}
                dataType="date"
                allowFiltering={true}
            />
        </DataGrid>
    );
};

export const DxDataGridJobs = (data, event) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;

    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            onToolbarPreparing={event.onToolbarPreparing2}

            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        // onRowPrepared={event.onRowPreparing}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <SearchPanel visible={true} width={100} />
            <Export enabled={true} fileName={"görevListesi"} />

            <Column
                dataField="TaskCreatorUserName"
                caption={t("GörevAtayanKullanıcı")}
                allowFiltering={true}
            />
            <Column
                caption={t("GörevliKullanıcı")}
                dataField="ResponsibleUserName"
                allowFiltering={true}
            />
            <Column
                caption={t("GörevAçıklaması")}
                dataField="Description"
                allowFiltering={true}
            />
            <Column
                caption={t("BaşlangıçZamanı")}
                dataField="strStartDate"

                allowFiltering={true}
            />
            <Column
                caption={t("BitişZamanı")}
                dataField="strEndDate"

                allowFiltering={true}
            />
            <Column
                caption={t("durum")}
                dataField="strIsFinish"
                allowFiltering={true}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonGörevBaşla(e, event, t)}
                width={"6%"}
                visibleIndex={24}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonGörevDurdur(e, event, t)}
                width={"6%"}
                visibleIndex={24}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonGörevBitir(e, event, t)}
                width={"6%"}
                visibleIndex={24}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonGörevIptalEt(e, event, t)}
                width={"6%"}
                visibleIndex={24}
            />
        </DataGrid>
    );
};
export const DxDataGridWatcherUsers = (data, event, loginUser) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;

    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            onToolbarPreparing={event.onToolbarPreparing}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <Export enabled={true} fileName={t("talepIslemeListesi")} />

            <Column dataField="Name" caption={t("isimOpt")} allowFiltering={true} />

            <Column dataField="Email" caption={t("emailAdresi")} allowFiltering={true} />

            <Column dataField="WatcherGroupName" caption="Grubu" allowFiltering={true} />

            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderWatcherButtons(e, event, loginUser)}
                width={"5%"}
                visibleIndex={3}
            />
        </DataGrid>
    );
};
export const DxDataGridForOwnTask = (
    url,
    event,
    lg,
    languageList,
    showDetail
) => {
    loginUser = lg;
    ths = event;
    languages = languageList;
    loadMessages(localizationDevExtreme);
    const { t } = event.props;
    return (
        <DataGrid
            dataSource={makeAsyncDataSource(url)}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />
            <Sorting mode="multiple" />
            <FilterRow visible={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} width={100} />
            <Export enabled={true} fileName="Talepler" />
            <MasterDetail
                enabled={true}
                component={DetailTemplateForOwnTask}
                autoExpandAll={showDetail}
            />
            <Column dataField="TaskNumber" caption="No" width={"7%"} />
            <Column dataField="StatusName" caption={t("talepDurumu")} width={"15%"} />
            <Column
                dataField="WorkStatus.Name"
                caption={t("islemDurumu")}
                width={"15%"}
            />
            <Column dataField="CompanyName" caption={t("firma")} width={"7%"} />
            <Column dataField="CreateUserName" caption={t("talepeden")} />
            <Column
                caption={t("devralan")}
                width={"10%"}
                calculateCellValue={(rowData) => {
                    if (rowData.ResponsibleUserName !== null) {
                        return rowData.ResponsibleUserName;
                    } else {
                        return "";
                    }
                }}
            />
            <Column dataField="CategoryName" caption={t("kategori")} width={"15%"} />
            <Column
                cellRender={aciliyetRender}
                caption={t("aciliyet")}
                width={"7%"}
            />
            <Column dataField="PoolName" caption={t("havuz")} width={"7%"} />
            <Column
                dataField="NewDescription"
                caption={t("açıklama")}
                width={"50%"}
            />
            <Column
                dataField="ExpectedEndDate"
                caption={t("beklenenkapanmazamani")}
                dataType="date"
                width={"10%"}
            />
            <Column
                dataField="CreateDate"
                caption={t("olusturmaTarihi")}
                dataType="datetime"
                width={"25%"}
            />
            <Column
                dataField="ApprovalDate"
                caption={t("onaylamatarihi")}
                width={"10%"}
            />
            <Column
                dataField="ManagerUserName"
                caption={t("onaylayanYonetici")}
                width={"10%"}
            />
            <Column
                dataField="ManagerUserName"
                caption="TalepAcanMail"
                width={"10%"}
            />
        </DataGrid>
    );
};
export const DxDataGridForOwnTaskTracking = (data, event, lg, showMultiselect, selectedMainDropdown,isMobile) => {
    loginUser = lg;
    ths = event;
    loadMessages(localizationDevExtreme);
    const { t } = event.props;
    isMobile = event.state.isMobile;
    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };
    const shouldShowMultiselect = !isMobile && ths.state.isMultiselect;

    return (
        <DataGrid
            id={"taskTacking"}
            ref={event.gridRef}
            dataSource={data}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            allowColumnReordering={true}
            onToolbarPreparing={event.onToolbarPreparing}
            onCellHoverChanged={(e) => null}
            showRowLines={true}
            showColumnLines={true}
        >
            <Template name="totalGroupCount" render={event.toolbarItemRender} />

            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dxTaskTrackingSettings2"
            />

            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
                allowSearch={true}
                allowHiding={true}
                showInColumnChooser={true}
            />

            <Paging defaultPageSize={15} />

            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 15, 20, 25]}
                showNavigationButtons={true}
            />

            <Sorting mode="multiple" />

            <FilterRow visible={true} showAllText={"Tümünü Seç"} />

            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <SearchPanel visible={true} width={100} />

            <Export enabled={true} fileName="Talepler" />

            <Selection mode="multiple" showCheckBoxesMode={shouldShowMultiselect ? "always" : "none"} />
            <Column
                type="selection"
                width={20}
                minWidth={20}
                maxWidth={20}
                visible={shouldShowMultiselect}
                visibleIndex={0}
                fixed={true}
                fixedPosition="left"
            />

            <Column
                dataField="TaskNumber"
                caption="No"
                width={isMobile ? "30%" : "10%"}
                allowSorting={true}
                defaultSortOrder="desc"
                dataType="number"
                cellRender={linkRenderer}
                showInColumnChooser={false}
                visibleIndex={1}
                visible={true}
            />
            <Column
                allowHeaderFiltering={false}
                allowFiltering={false}
                allowSearch={false}
                caption={""}
                dataField="GorevCount"
                cellRender={jobRender}
                width={35}
                visibleIndex={2}
                visible={!isMobile}
            />
            <Column
                dataField="StatusName"
                caption={t("talepDurumu")}
                width={"20%"}
                visibleIndex={3}
                visible={!isMobile}
            />

            <Column
                dataField="CompanyName"
                caption={t("firma")}
                width={"10%"}
                visibleIndex={4}
                visible={!isMobile}
            />

            <Column
                dataField="CreateUserName"
                caption={t("talepeden")}
                width={"20%"}
                visibleIndex={5}
                visible={!isMobile}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="AciklamaCount"
                cellRender={(e) => descriptionRender(e, event)}
                width={35}
                visibleIndex={6}
                visible={!isMobile}
            />

            <Column
                dataField={"ParentCategory"}
                caption={t("parentkategori")}
                cellRender={ParentkategoriRender}
                width={"25%"}
                visibleIndex={7}
                visible={!isMobile}
            />
            <Column
                dataField={"CategoryName"}
                caption={t("kategori")}
                cellRender={kategoriRender}
                width={"25%"}
                visibleIndex={7}
                visible={!isMobile}
            />

            <Column
                caption={t("aciliyet")}
                cellRender={aciliyetRender}
                width={"10%"}
                visibleIndex={8}
                visible={!isMobile}
            />

            <Column
                dataField="PoolName"
                caption={t("havuz")}
                width={"9%"}
                visibleIndex={9}
                visible={!isMobile}
            />
            <Column
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="NewDescription"
                caption={t("açıklama")}
                width={"40%"}
                cellRender={aciklamaRenderer}
                visibleIndex={10}
                visible={true}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="EkdosyaCount"
                cellRender={attachmentRender}
                width={35}
                visibleIndex={11}
                visible={!isMobile}
            />
            <Column
                dataField="CreateDate"
                caption={t("olusturmaTarihi")}
                dataType="datetime"
                width={"25%"}
                visibleIndex={12}
                defaultVisible={true}
                visible={!isMobile}
            />
            <Column
                dataField="WorkStatus.Name"
                caption={t("islemDurumu")}
                width={"15%"}
                visibleIndex={13}
                defaultVisible={false}
                
            />
            <Column
                dataField="CreateUserCompanyDepartmentJobName"
                caption={t("görev")}
                width={"14%"}
                visibleIndex={14}
                defaultVisible={false}
            />
            <Column
                dataField="ResponsibleUserName"
                caption={t("devralan")}
                width={"13%"}
                visibleIndex={15}
                defaultVisible={false}
            />
            <Column
                dataField="GorevliKisiler"
                caption={t("GörevAtayanKullanıcı")}
                allowFiltering={true}
                defaultVisible={false}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={"SLA"}
                dataField="SlaMinute"
                cellRender={SLARow}
                width={35}
                visibleIndex={16}
                defaultVisible={false}
            />
            <Column
                dataField="ManagerUserName"
                caption={t("onaylayanYonetici")}
                width={"15%"}
                cellRender={managerRender}
                visibleIndex={17}
                defaultVisible={false}
            />
            <Column
                dataField="TerminationDescription"
                caption={t("talepKapanisAciklamasi")}
                width={"15%"}
                cellRender={terminationDescriptionColumn}
                visibleIndex={17}
                defaultVisible={false}
            />
            <Column
                dataField="ApprovalDate"
                caption={t("onaylamatarihi")}
                dataType="datetime"
                width={"14%"}
                defaultVisible={false}
                visibleIndex={18}
            />
            <Column
                dataField="ExpectedEndDate"
                caption={t("beklenenkapanmazamani")}
                dataType="date"
                width={"15%"}
                defaultVisible={false}
                visibleIndex={19}
            />
            <Column
                dataField="ProjectNumber"
                caption={t("projeNo")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={20}
            />
            <Column
                dataField="TerminationDate"
                caption={t("sonlandirmaTarihi")}
                dataType="datetime"
                width={"13%"}
                defaultVisible={false}
                visibleIndex={21}
            />
            <Column
                dataField="CreateUserCompanyDepartmentName"
                caption={t("departman")}
                width={"14%"}
                visibleIndex={22}
                defaultVisible={false}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtons(e, event, lg)}
                width={"auto"}
                visibleIndex={23}
                defaultVisible={false}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonsİptal(e, event, lg)}
                width={"auto"}
                visibleIndex={23}
                defaultVisible={true}
                visible={!isMobile}
            />
        </DataGrid>
    );
};
export const DxDataGridTaskTrackingForOnlyOwnConfirm2 = (data, event, loginUser) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;

    return (
        <DataGrid
            id="gridContainer"
            dataSource={data}
            showRowLines={true}
            showColumnLines={true}
            onToolbarPreparing={event.onToolbarPreparing}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />

            <Export enabled={true} fileName={t("talepIslemeListesi")} />


        </DataGrid>
    );
};
export const DxDataGridTaskTrackingForOnlyOwnConfirm = (data, event, lg) => {
    loadMessages(localizationDevExtreme);
    loginUser = lg;
    ths = event;
    const { t } = event.props;
    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };

    return (
        <DataGrid
            id={"taskTacking"}
            ref={event.gridRef}
            dataSource={data}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            allowColumnReordering={true}
            onToolbarPreparing={event.onToolbarPreparing}
            onCellHoverChanged={(e) => null}
            showRowLines={true}
            showColumnLines={true}
        >

            <Template name="totalGroupCount" render={event.toolbarItemRender} />

            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dxTaskTrackingSettingse2"
            />
            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
                allowSearch={true}
                allowHiding={true}
                showInColumnChooser={true}
            />

            <Paging defaultPageSize={15} />

            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 15, 20, 25]}
                showNavigationButtons={true}
            />

            <Sorting mode="multiple" />

            <FilterRow visible={true} showAllText={"Tümünü Seç"} />

            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <SearchPanel visible={true} width={100} />

            <Export enabled={true} fileName="Talepler" />

            <Column
                dataField="TaskNumber"
                caption="No"
                width={65}
                allowSorting={true}
                defaultSortOrder="desc"
                dataType="number"
                cellRender={linkRenderer}
                showInColumnChooser={false}
                visibleIndex={0}
            />
            <Column
                allowHeaderFiltering={false}
                allowFiltering={false}
                allowSearch={false}
                caption={"Görev Sayısı"}
                dataField="GorevCount"
                cellRender={jobRender}
                width={35}
                visibleIndex={1}
            />
            <Column
                dataField="StatusName"
                caption={t("talepDurumuHeader")}
                width={"25%"}
                visibleIndex={2}
            />

            <Column
                dataField="CompanyName"
                caption={t("firma")}
                width={"10%"}
                visibleIndex={3}
            />

            <Column
                dataField="CreateUserName"
                caption={t("talepeden")}
                width={"25%"}
                visibleIndex={4}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="AciklamaCount"
                cellRender={(e) => descriptionRender(e, event)}
                width={35}
                visibleIndex={5}
            />

            <Column
                dataField={"ParentCategory"}
                caption={t("parentkategori")}
                cellRender={ParentkategoriRender}
                width={"25%"}
                visibleIndex={6}
            />
            <Column
                dataField={"CategoryName"}
                caption={t("kategori")}
                cellRender={kategoriRender}
                width={"25%"}
                visibleIndex={6}
            />

            <Column
                caption={t("aciliyet")}
                cellRender={aciliyetRender}
                width={"10%"}
                visibleIndex={7}
            />

            <Column
                dataField="PoolName"
                caption={t("havuz")}
                width={"9%"}
                visibleIndex={8}
            />
            <Column
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="NewDescription"
                caption={t("açıklama")}
                width={"50%"}
                cellRender={aciklamaRenderer}
                visibleIndex={9}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="EkdosyaCount"
                cellRender={attachmentRender}
                width={35}
                visibleIndex={10}
            />
            <Column
                dataField="CreateDate"
                caption={t("olusturmaTarihi")}
                dataType="datetime"
                width={"25%"}
                visibleIndex={11}
            />
            <Column
                dataField="WorkStatus.Name"
                caption={t("islemDurumu")}
                width={"15%"}
                visibleIndex={12}
                defaultVisible={false}
            />
            <Column
                dataField="CreateUserCompanyDepartmentJobName"
                caption={t("görev")}
                width={"14%"}
                visibleIndex={13}
                defaultVisible={false}
            />
            <Column
                dataField="ResponsibleUserName"
                caption={t("devralan")}
                width={"13%"}
                visibleIndex={14}
                defaultVisible={false}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={"SLA"}
                dataField="SlaMinute"
                cellRender={SLARow}
                width={35}
                visibleIndex={15}
                defaultVisible={false}
            />
            <Column
                dataField="ManagerUserName"
                caption={t("onaylayanYonetici")}
                width={"15%"}
                cellRender={managerRender}
                visibleIndex={16}
                defaultVisible={false}
            />
            <Column
                dataField="TerminationDescription"
                caption={t("talepKapanisAciklamasi")}
                width={"15%"}
                cellRender={terminationDescriptionColumn}
                visibleIndex={16}
                defaultVisible={false}
            />
            <Column
                dataField="ApprovalDate"
                caption={t("onaylamatarihi")}
                dataType="datetime"
                width={"14%"}
                defaultVisible={false}
                visibleIndex={17}
            />
            <Column
                dataField="ExpectedEndDate"
                caption={t("beklenenkapanmazamani")}
                dataType="date"
                width={"15%"}
                defaultVisible={false}
                visibleIndex={18}
            />
            <Column
                dataField="ProjectNumber"
                caption={t("projeNo")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={19}
            />
            <Column
                dataField="TerminationDate"
                caption={t("sonlandirmaTarihi")}
                dataType="datetime"
                width={"13%"}
                defaultVisible={false}
                visibleIndex={20}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={"test"}
                //dataField="SlaMinute"
                cellRender={SLARow}
                width={35}
                visibleIndex={15}

                defaultVisible={false}
            />
            <Column
                dataField="CreateUserCompanyDepartmentName"
                caption={t("departman")}
                width={"14%"}
                visibleIndex={21}
                defaultVisible={false}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtons(e, event, lg)}
                width={"auto"}
                visibleIndex={22}
                defaultVisible={false}
            />

        </DataGrid>
    );
};
export const DxDataGridForSurvey = (data, event, lg, isMobile) => {
    loadMessages(localizationDevExtreme);
    ths = event;
    const filterBuilderPopupPosition = {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
    };
    isMobile = event.state.isMobile;
    return (
        <DataGrid
            id={"survey"}
            dataSource={data}
            ref={event.gridRef}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            allowColumnReordering={true}
            onToolbarPreparing={event.onToolbarPreparing}
            onCellHoverChanged={(e) => null}
            showRowLines={true}
            showColumnLines={true}
        >
            <Template name="totalGroupCount" render={event.toolbarItemRender} />
            <Template name="pageName" render={event.pageNameRender} />

            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dxTaskTrackingSettings2"
            />

            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
                allowSearch={true}
                allowHiding={true}
                showInColumnChooser={true}
            />

            <Paging defaultPageSize={15} />

            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 15, 20, 25]}
                showNavigationButtons={true}
            />

            <Sorting mode="multiple" />

            <FilterRow visible={true} showAllText={"Tümünü Seç"} />

            <FilterPanel visible={true} />
            <FilterBuilderPopup position={filterBuilderPopupPosition} />

            <HeaderFilter visible={true} />

            <SearchPanel visible={true} width={100} />

            <Export enabled={true} fileName="Talepler" />

            <Column dataField="Name" caption={t("anketAdi")} visible={!isMobile} />
            <Column dataField="TaskNumber" caption={t("talepnumarası")} visible={true} />
            <Column dataField="TerminationDescription" caption={t("kapanışmesajı")} visible={true} />
            <Column dataField="CreateDate" caption={t("açılıştarihi")} visible={!isMobile} />
            <Column dataField="TerminationDate" caption={t("kapanıştarihi")} visible={!isMobile} />
            <Column
                dataField={"cevapla"}
                caption={t("cevapla")}
                width={100}
                cellRender={event.actionButton}
                visible={true}
            />
        </DataGrid>
    );
};
export const DxDataGridForOwnTaskPool = (data, event, lg, isMobile) => {
    loadMessages(localizationDevExtreme);
    loginUser = lg;
    ths = event;
    const { t } = event.props;
    isMobile = event.state.isMobile;

    return (
        <DataGrid
            id={"taskTacking"}
            ref={event.gridRef}
            dataSource={data}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            allowColumnReordering={true}
            onToolbarPreparing={event.onToolbarPreparing}
            onCellHoverChanged={(e) => null}
            showRowLines={true}
            showColumnLines={true}
        >
            <Template name="totalGroupCount" render={event.toolbarItemRender} />
            <Template name="pageName" render={event.pageNameRender} />

            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dxPoolSettings2"
            />

            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
                allowSearch={true}
                allowHiding={true}
                showInColumnChooser={true}
            />

            <Paging defaultPageSize={15} />

            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 15, 20, 25]}
                showNavigationButtons={true}
            />

            <Sorting mode="multiple" />

            <FilterRow visible={true} />

            <FilterPanel visible={true} />

            <HeaderFilter visible={true} />

            <SearchPanel visible={true} width={100} />

            <Export enabled={true} fileName="Talepler" />

            <Column
                dataField="TaskNumber"
                caption="No"
                width={65}
                allowSorting={true}
                defaultSortOrder="desc"
                dataType="number"
                cellRender={linkRenderer}
                showInColumnChooser={false}
                visibleIndex={0}
                visible={true}
            />
            <Column
                allowHeaderFiltering={false}
                allowFiltering={false}
                allowSearch={false}
                caption={t("gorevler")}
                dataField="GorevCount"
                cellRender={jobRender}
                width={35}
                visibleIndex={1}
                visible={!isMobile}
            />
            <Column
                dataField="CompanyName"
                caption={t("firma")}
                width={"7%"}
                visibleIndex={2}
                visible={!isMobile}
            />
            <Column
                dataField="CreateUserCompanyDepartmentJobName"
                caption={t("görev")}
                width={"20%"}
                visibleIndex={3}
                defaultVisible={false}
                visible={!isMobile}
            />

            <Column
                dataField="CreateUserName"
                caption={t("talepeden")}
                width={"11%"}
                visibleIndex={4}
                visible={!isMobile}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="AciklamaCount"
                cellRender={(e) => descriptionRender(e, event)}
                width={35}
                visibleIndex={5}
                visible={!isMobile}
            />
            <Column
                dataField={"ParentCategory"}
                caption={t("parentkategori")}
                cellRender={ParentkategoriRender}
                width={"15%"}
                visibleIndex={6}
                visible={!isMobile}
            />
            <Column
                dataField={"Category.Name"}
                caption={t("kategori")}
                cellRender={kategoriRender}
                width={"15%"}
                visibleIndex={6}
                visible={!isMobile}
            />
            <Column
                caption={t("aciliyet")}
                cellRender={aciliyetRender}
                width={"10%"}
                visibleIndex={7}
                visible={!isMobile}
            />

            <Column
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="NewDescription"
                caption={t("açıklama")}
                width={"20%"}
                cellRender={aciklamaRenderer}
                visibleIndex={8}
                visible={true}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                caption={""}
                dataField="EkdosyaCount"
                cellRender={attachmentRender}
                width={35}
                visibleIndex={9}
                visible={!isMobile}
            />
            <Column
                dataField="CreateDate"
                caption={t("olusturmaTarihi")}
                dataType="datetime"
                width={"11%"}
                visibleIndex={10}
                visible={!isMobile}
            />
            <Column
                dataField="ManagerUserName"
                caption={t("onaylayanYonetici")}
                width={"12%"}
                cellRender={managerRender}
                visibleIndex={11}
                visible={!isMobile}
            />
            <Column
                dataField="ExpectedEndDate"
                caption={t("tahminikapanmatarihi")}
                dataType="datetime"
                width={"11%"}
                visibleIndex={12}
                defaultVisible={false}
            />
            <Column
                dataField="TerminationDescription"
                caption={t("talepKapanisAciklamasi")}
                width={"12%"}
                defaultVisible={false}
                cellRender={terminationDescriptionColumn}
                visibleIndex={13}
            />
            <Column
                dataField="CreateUserCompanyDepartmentName"
                caption={t("departman")}
                width={"15%"}
                visibleIndex={14}
                defaultVisible={false}
            />
            <Column
                allowHeaderFiltering={false}
                allowSearch={false}
                allowFiltering={false}
                defaultVisible={false}
                caption={"SLA"}
                dataField="SlaMinute"
                cellRender={SLARow}
                width={35}
                visibleIndex={15}
            />
            <Column
                dataField="ApprovalDate"
                caption={t("onaylamatarihi")}
                dataType="datetime"
                width={"14%"}
                defaultVisible={false}
                visibleIndex={16}
            />
            <Column
                dataField="StatusName"
                caption={t("talepDurumuHeader")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={17}
            />
            <Column
                dataField="WorkStatus.Name"
                caption={t("islemDurumu")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={18}
            />
            <Column
                dataField="ResponsibleUserName"
                caption={t("devralan")}
                width={"13%"}
                defaultVisible={false}
                visibleIndex={19}
            />
            <Column
                dataField="PoolName"
                caption={t("havuz")}
                width={"9%"}
                defaultVisible={false}
                visibleIndex={20}
            />
            <Column
                dataField="ExpectedEndDate"
                caption={t("beklenenkapanmazamani")}
                dataType="date"
                width={"15%"}
                defaultVisible={false}
                visibleIndex={21}
            />
            <Column
                dataField="ProjectNumber"
                caption={t("projeNo")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={22}
            />
            <Column
                dataField="TaskTerminationDescription"
                caption={t("talepKapanisAciklamasi")}
                width={"15%"}
                defaultVisible={false}
                visibleIndex={23}
            />
            <Column
                dataField="TerminationDate"
                caption={t("sonlandirmaTarihi")}
                dataType="datetime"
                width={"13%"}
                defaultVisible={false}
                visibleIndex={24}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtons(e, event, lg)}
                width={"6%"}
                visibleIndex={25}
                visible={!isMobile}
            />
            <Column
                fixed={true}
                fixedPosition={"right"}
                cellRender={(e) => renderButtonAta(e, event, lg)}
                width={"6%"}
                visibleIndex={26}
                visible={!isMobile}
            />
        </DataGrid>
    );
};
export const managerRender = (cellData) => {
    if (
        cellData.data.ManagerUserName !== null &&
        cellData.data.ConfirmingManagerName !== null &&
        cellData.data.ManagerUserName !== cellData.data.ConfirmingManagerName
    ) {
        return (
            <div>
                <div className="current-value">
                    {cellData.data.ManagerUserName} ({cellData.data.ConfirmingManagerName}
                    )
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div className="current-value">{cellData.data.ManagerUserName}</div>
            </div>
        );
    }
};

export const terminationDescriptionColumn = (cellData) => {
    if (cellData.data.TerminationDescription != null) {
        return (
            <div>
                <div className="current-value">
                    {cellData.data.TerminationDescription}
                </div>
            </div>
        );
    } else {
        return (<div>
            <div className="current-value"></div>
        </div>
        );
    }
}
export const DxDataGridForErrors = (data) => {
    loadMessages(localizationDevExtreme);
    return (
        <DataGrid
            dataSource={data}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            showBorders={false}
        >
            <Paging defaultPageSize={10} />
            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 10, 20, 50]}
                showNavigationButtons={true}
            />
            <Sorting mode="multiple" />
            <FilterRow visible={true} />
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <GroupPanel visible={true} />
            <SearchPanel visible={true} width={100} />
            <Export enabled={true} fileName="Hatalar" />
            <Column
                dataField="CreateDate"
                caption={"Hata Zamanı"}
                dataType="datetime"
                width={"10%"}
            />
            <Column dataField="ErrorLog" caption={"Hata Detayı"} width={"90%"} />
        </DataGrid>
    );
};
export const DxDataGridWithDetailForConfirm = (data, event, showDetail, isMobile) => {
    loadMessages(localizationDevExtreme);
    const { t } = event.props;
    ths = event;
    isMobile  = event.state.isMobile;
    return (
        <DataGrid
            dataSource={data}
            showColumnLines={true}
            allowColumnResizing={true}
            columnResizingMode={"nextColumn"}
            onToolbarPreparing={event.onToolbarPreparing}
            onCellHoverChanged={(e) => null}
            showRowLines={true}
            id={"taskTacking"}
            ref={event.gridRef}
            allowColumnReordering={true}
        >
            <Template name="totalGroupCount" render={event.toolbarItemRender} />
            <Template name="pageName" render={event.pageNameRender} />

            <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="dxPoolSettings2"
            />

            <ColumnChooser
                enabled={true}
                mode="dragAndDrop"
                allowSearch={true}
                allowHiding={true}
                showInColumnChooser={true}
            />

            <Paging defaultPageSize={15} />

            <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[10, 15, 20, 25]}
                showNavigationButtons={true}
            />

            <Sorting mode="multiple" />

            <FilterRow visible={true} />

            <FilterPanel visible={true} />

            <HeaderFilter visible={true} />

            <Export enabled={true} fileName="Talepler" />

            <MasterDetail
                enabled={true}
                component={DetailTemplateForConfirm}
                data={data}
                autoExpandAll={showDetail}
            />

            <Column dataField="TaskNumber" caption="No" width={65} visible={true} />
            <Column dataField="Company" caption={t("kurum")} visible={!isMobile} />
            <Column dataField="Department" caption={t("departman")} visible={!isMobile} />
            <Column dataField="DepartmentJob" caption={t("görevi")} visible={!isMobile} />
            <Column dataField="Createname" caption={t("talepSahibi")} visible={!isMobile} />
            <Column dataField="CategoryName" caption={t("kategori")} visible={!isMobile} />
            <Column dataField="DegreeOfUrgency" caption={t("aciliyet")} width={100} visible={!isMobile} />
            <Column
                caption={t("talepAciklamasi")}
                cellRender={aciklamaRenderer}
                minWidth={250}
                visible={true }
            />
            <Column
                dataField="CreateDate"
                caption={t("açılıştarihi")}
                dataType="datetime"
                visible={!isMobile}
            />
            <Column dataField="AdditionalConfirmDescription" caption={t("ekOnayAciklama")}
                visible={!isMobile} />
        </DataGrid>
    );
};

export const DxTextBox = (id, text, event, isDisable) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <TextBox value={event.state[id]} disabled={isDisable} />
        </FormGroup>
    );
};

export const DxTextBoxEdit = (id, text, event) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <TextBox value={event} />
        </FormGroup>
    );
};
export const DxDropDownWithJSONForManager = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <DropDownBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                placeholder={onlyDropDown !== undefined ? placeHolder : t("seciniz")}
                valueExpr="ID"
                displayExpr="Name"
                disabled={isDisable !== undefined ? isDisable : false}
                dataSource={data}
                deferRendering={false}
                showClearButton={true}
                onValueChanged={(e) => { event.clrData("oldManager") }}
                //searchEnabled={true}
                allowSearch={true}
                showDataBeforeSearch={true}
                minSearchLength={2}
                contentRender={(e) => DxTreeView(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithJSON = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <DropDownBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                placeholder={onlyDropDown !== undefined ? placeHolder : t("seciniz")}
                valueExpr="ID"
                displayExpr="Name"
                disabled={isDisable !== undefined ? isDisable : false}
                dataSource={data}
                deferRendering={false}
                // showClearButton={true}
                // onValueChanged={(e)=>{   event.clrData("oldManager")}}
                //searchEnabled={true}
                allowSearch={true}
                showDataBeforeSearch={true}
                minSearchLength={2}
                contentRender={(e) => DxTreeView(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithJSON2 = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <DropDownBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                placeholder={onlyDropDown !== undefined ? placeHolder : t("seciniz")}
                valueExpr="ID"
                displayExpr="Name"
                disabled={isDisable !== undefined ? isDisable : false}
                dataSource={data}
                deferRendering={false}

                //searchEnabled={true}
                allowSearch={true}
                showDataBeforeSearch={true}
                minSearchLength={2}
                contentRender={(e) => DxTreeView(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithJSONFav = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <SelectBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                valueExpr="ID"
                displayExpr="Text"
                dataSource={data}
                searchEnabled={true}
                deferRendering={true}
                showDataBeforeSearch={true}
                searchMode={"contains"}
                onValueChanged={(e) => {
                    data = data.filter(
                        (x) => x.ID == "2bd6aed2-ad6a-4c06-bde8-aedaecbbdf40"
                    );
                    event.handleChange(id, e.previousValue);
                }}
                minSearchLength={2}
            />
        </FormGroup>
    );
};

export const DxSelectBoxWithJSON = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <SelectBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                valueExpr="ID"
                displayExpr="Name"
                dataSource={data}
                searchEnabled={true}
                deferRendering={false}
                showDataBeforeSearch={false}
                searchMode={"contains"}
                onValueChanged={(e) => {
                    data = data.filter(
                        (x) => x.ID == "2bd6aed2-ad6a-4c06-bde8-aedaecbbdf40"
                    );
                    event.handleChange(id, e.previousValue);
                }}
                minSearchLength={2}
            />
        </FormGroup>
    );
};

export const DxDropDownWithJSONClrButton = (
    id,
    text,
    event,
    data,
    isDisable,
    onlyDropDown,
    placeHolder,
    isTaskCreate
) => {
    return (
        <FormGroup>
            {onlyDropDown === undefined ? (
                <Label>
                    <strong>{text}</strong>
                </Label>
            ) : null}
            <DropDownBox
                style={
                    onlyDropDown !== undefined
                        ? isTaskCreate === true
                            ? {
                                marginBottom: -22,
                                marginTop: -5,
                                marginRight: -10,
                                zIndex: 9999999999999999999999,
                            }
                            : { zIndex: 9999999999999999999999 }
                        : { zIndex: 9999999999999999999999 }
                }
                value={event.state[id]}
                placeholder={onlyDropDown !== undefined ? placeHolder : t("seciniz")}
                valueExpr="ID"
                displayExpr="Name"
                disabled={isDisable !== undefined ? isDisable : false}
                dataSource={data}
                showClearButton={true}
                onValueChanged={(e) => {
                    if (e.value === null) {
                        event.setState({ [id]: undefined });
                    }
                }}
                contentRender={(e) => DxTreeView(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithJSONWithPlaceHolder = (
    id,
    text,
    event,
    data,
    placeHolder,
    isDisable
) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <DropDownBox
                value={event.state[id]}
                placeholder={placeHolder}
                valueExpr="ID"
                disabled={isDisable !== undefined ? isDisable : false}
                displayExpr="Name"
                dataSource={data}
                contentRender={(e) => DxTreeView(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithJSONWithPlaceHolderTaskCreate = (
    id,
    text,
    event,
    data,
    placeHolder,
    isDisable
) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <DropDownBox
                value={event.state[id]}
                placeholder={placeHolder}
                valueExpr="ID"
                disabled={isDisable !== undefined ? isDisable : false}
                displayExpr="Name"
                dataSource={data}
                contentRender={(e) => DxTreeViewTaskCreate(id, text, event, data, e)}
            />
        </FormGroup>
    );
};
export const DxDropDownWithURL = (
    id,
    text,
    event,
    url,
    placeholder,
    isDisable,
    hideLabel
) => {
    const dataSource = makeAsyncDataSource(url);
    return (
        <FormGroup>
            <Label>{!hideLabel && <strong>{text}</strong>}</Label>

            <DropDownBox
                disabled={isDisable !== undefined ? isDisable : false}
                value={event.state[id]}
                placeholder={placeholder}
                valueExpr="ID"
                displayExpr="Name"
                searchEnabled={true}
                dataSource={dataSource}
                contentRender={(e) => DxTreeView(id, text, event, dataSource, e)}
            />
        </FormGroup>
    );
};

export const DxDropDownWithURL2 = (
    id,
    text,
    event,
    data,
    placeholder,
    isDisable,
    hideLabel
) => {
    const dataSource = data;
    return (
        <FormGroup>
            <Label>{!hideLabel && <strong>{text}</strong>}</Label>

            <DropDownBox
                disabled={isDisable !== undefined ? isDisable : false}
                value={event.state[id]}
                placeholder={placeholder}
                valueExpr="ID"
                displayExpr="Name"
                searchEnabled={true}
                dataSource={dataSource}
                contentRender={(e) => DxTreeView(id, text, event, dataSource, e)}
            />
        </FormGroup>
    );
};
export const DxMultipleDropdown = (id, text, event, data, isDisable, ref) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <DropDownBox
                value={event.state[id]}
                valueExpr="ID"
                disabled={isDisable !== undefined ? isDisable : false}
                displayExpr="Name"
                placeholder={t("lutfenSeciniz")}
                showClearButton={true}
                dataSource={data}
                onValueChanged={(e) => {
                    let treeView =
                        (e.component.selectItem && e.component) ||
                        (event.treeView && event.treeView.instance);

                    if (treeView) {
                        if (e.value === null) {
                            treeView.unselectAll();
                        } else {
                            let values = e.value || event.state[id];

                            values &&
                                values.forEach(function (value) {
                                    treeView.selectItem(value);
                                });
                        }
                    }

                    if (e.value !== undefined) {
                        event.setState({ [id]: e.value });
                    }
                }}
                contentRender={(e) => {
                    return (
                        <TreeView
                            dataSource={data}
                            ref={(ref) => (event.treeView = ref)}
                            dataStructure="plain"
                            keyExpr="ID"
                            parentIdExpr="ParentID"
                            selectionMode="multiple"
                            showCheckBoxesMode="normal"
                            selectNodesRecursive={true}
                            displayExpr="Name"
                            selectByClick={true}
                            searchEnabled={true}
                            height={300}
                            onContentReady={(e2) => {
                                let treeView =
                                    (e2.component.selectItem && e2.component) ||
                                    (event.treeView && event.treeView.instance);

                                if (treeView) {
                                    if (e2.value === null) {
                                        treeView.unselectAll();
                                    } else {
                                        let values = e2.value || event.state[id];

                                        values &&
                                            values.forEach(function (value) {
                                                treeView.selectItem(value);
                                            });
                                    }
                                }

                                if (e2.value !== undefined) {
                                    event.setState({ [id]: e.value });
                                }
                            }}
                            onItemSelectionChanged={(e1) => {
                                event.setState({ [id]: e1.component.getSelectedNodeKeys() });
                            }}
                        />
                    );
                }}
            />
        </FormGroup>
    );
};

export const DxMultipleDropdownWithUrl = (
    id,
    text,
    event,
    url,
    isDisable,
    ref
) => {
    let data = makeAsyncDataSource(url);
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <DropDownBox
                value={event.state[id]}
                valueExpr="ID"
                disabled={isDisable !== undefined ? isDisable : false}
                displayExpr="Name"
                placeholder={t("lutfenSeciniz")}
                showClearButton={true}
                dataSource={data}
                onValueChanged={(e) => {
                    let treeView =
                        (e.component.selectItem && e.component) ||
                        (event.treeView && event.treeView.instance);

                    if (treeView) {
                        if (e.value === null) {
                            treeView.unselectAll();
                        } else {
                            let values = e.value || event.state[id];

                            values &&
                                values.forEach(function (value) {
                                    treeView.selectItem(value);
                                });
                        }
                    }

                    if (e.value !== undefined) {
                        event.setState({ [id]: e.value });
                    }
                }}
                contentRender={(e) => {
                    return (
                        <TreeView
                            dataSource={data}
                            ref={(ref) => (event.treeView = ref)}
                            dataStructure="plain"
                            keyExpr="ID"
                            parentIdExpr="ParentID"
                            selectionMode="multiple"
                            showCheckBoxesMode="normal"
                            selectNodesRecursive={true}
                            displayExpr="Name"
                            selectByClick={true}
                            searchEnabled={true}
                            height={300}
                            onContentReady={(e2) => {
                                let treeView =
                                    (e2.component.selectItem && e2.component) ||
                                    (event.treeView && event.treeView.instance);

                                if (treeView) {
                                    if (e2.value === null) {
                                        treeView.unselectAll();
                                    } else {
                                        let values = e2.value || event.state[id];
                                        values &&
                                            values.forEach(function (value) {
                                                treeView.selectItem(value);
                                            });
                                    }
                                }

                                if (e2.value !== undefined) {
                                    event.setState({ [id]: e.value });
                                }
                            }}
                            onItemSelectionChanged={(e1) => {
                                event.setState({ [id]: e1.component.getSelectedNodeKeys() });
                            }}
                        />
                    );
                }}
            />
        </FormGroup>
    );
};

export const DxMultipleDropdownTaskCreateTemplate = (
    id,
    text,
    event,
    data,
    isDisable
) => {
    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <DropDownBox
                value={event.state[id]}
                valueExpr="ID"
                disabled={isDisable !== undefined ? isDisable : false}
                displayExpr="Name"
                placeholder={t("lutfenSeciniz")}
                showClearButton={true}
                dataSource={data}
                onValueChanged={(e) => {
                    let treeView =
                        (e.component.selectItem && e.component) ||
                        (event.treeView && event.treeView.instance);

                    if (treeView) {
                        if (e.value === null) {
                            treeView.unselectAll();
                        } else {
                            let values = e.value || event.state[id];

                            values &&
                                values.forEach(function (value) {
                                    treeView.selectItem(value);
                                });
                        }
                    }

                    if (e.value !== undefined) {
                        event.handleChange(id, e.value);
                        // event.setState({[id]: e.value});
                    }
                }}
                contentRender={(e) => {
                    return (
                        <TreeView
                            dataSource={data}
                            ref={(ref) => (event.treeView = ref)}
                            dataStructure="plain"
                            keyExpr="ID"
                            parentIdExpr="ParentID"
                            selectionMode="multiple"
                            showCheckBoxesMode="normal"
                            selectNodesRecursive={true}
                            displayExpr="Name"
                            selectByClick={true}
                            searchEnabled={true}
                            height={300}
                            onContentReady={(e2) => {
                                let treeView =
                                    (e2.component.selectItem && e2.component) ||
                                    (event.treeView && event.treeView.instance);

                                if (treeView) {
                                    if (e2.value === null) {
                                        treeView.unselectAll();
                                    } else {
                                        let values = e2.value || event.state[id];
                                        values &&
                                            values.forEach(function (value) {
                                                treeView.selectItem(value);
                                            });
                                    }
                                }

                                if (e2.value !== undefined) {
                                    event.setState({ [id]: e.value });
                                }
                            }}
                            onItemSelectionChanged={(e1) => {
                                event.handleChange(id, e1.component.getSelectedNodeKeys());
                                // event.setState({[id]: e1.component.getSelectedNodeKeys()});
                            }}
                        />
                    );
                }}
            />
        </FormGroup>
    );
};

let selectedValues = [];
export const DxMultipleCheckBox = (id, text, data, event) => {
    if (event.state[id] == undefined) {
        selectedValues = [];
    }
    const handleCheckboxChange = (e, checkBoxId) => {
        let index = selectedValues.indexOf(e);
        if (index > -1) {
            selectedValues = selectedValues.slice(0, index).concat(selectedValues.slice(index + 1));
        } else {
            selectedValues.push(e);
            selectedValues = selectedValues.sort((a, b) => a - b);
        }

        if (event.state[id] == undefined) {
            event.setState({ [id]: selectedValues });
        } else {
            event.state[id] = selectedValues;
        }
    }

    return (
        <FormGroup>
            <Label>
                <strong>{text}</strong>
            </Label>
            <br />
            {data.map((item) => (
                <CheckBox key={item.ID} onValueChange={() => handleCheckboxChange(item.ID)}>
                    <label>{item.Name}</label>
                </CheckBox>
            ))}
        </FormGroup>
    );
}

export const DxTreeViewToAll = (id, text, event, data) => {
    return (
        <TreeView
            dataSource={data}
            dataStructure="plain"
            keyExpr="ID"
            valueExpr="ID"
            parentIdExpr="ParentID"
            selectionMode="single"
            displayExpr="Name"
            selectByClick={true}
            searchEnabled={true}
            selectNodesRecursive={true}
            //onSelectionChanged={(selectedItem) => {
            //    e.component.close();
            //}}
            onItemSelectionChanged={(e) => {
                if (e.component.getSelectedNodesKeys()[0] !== undefined) {
                    let selected = e.component.getSelectedNodesKeys()[0];

                    if (id === "selectedTerminationTemplate") {
                        let selectedID = e.component.getSelectedNodesKeys()[0];
                        let selected = data.__rawData.filter((x) => x.ID === selectedID)[0];
                        event.handleChange(id, selectedID, selected.Description);
                    } else {
                        event.handleChange(id, selected);
                    }
                }
            }}
        />
    );
};

const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = [
    "Arial",
    "Courier New",
    "Georgia",
    "Impact",
    "Lucida Console",
    "Tahoma",
    "Times New Roman",
    "Verdana",
];
const headerValues = [false, 1, 2, 3, 4, 5];

export const DxHtmlEditor = (id, text) => {
    loadMessages(localizationDevExtreme);
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <HtmlEditor
                defaultValue={metrics[id]}
                height="400px"
                onValueChanged={(e) => (metrics.htmlDescription = e.value)}
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={false}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={[
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ]}
                    />
                    <Item
                        name="font"
                        acceptedValues={[
                            "Arial",
                            "Courier New",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ]}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    {/*<Item name="separator" />*/}
                    {/*<Item name="insertTable" />*/}
                    {/*<Item name="deleteTable" />*/}
                    {/*<Item name="insertRowAbove" />*/}
                    {/*<Item name="insertRowBelow" />*/}
                    {/*<Item name="deleteRow" />*/}
                    {/*<Item name="insertColumnLeft" />*/}
                    {/*<Item name="insertColumnRight" />*/}
                    {/*<Item name="deleteColumn" />*/}
                </Toolbar>
            </HtmlEditor>
        </FormGroup>
    );
};

export const DxHtmlEditor2 = (id, text, value) => {
    loadMessages(localizationDevExtreme);
    metrics.selectedTaskdes = value;
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <HtmlEditor
                defaultValue={value}
                height="400px"
                onValueChanged={(e) => (metrics.selectedTaskdes = e.value)}
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={false}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={[
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ]}
                    />
                    <Item
                        name="font"
                        acceptedValues={[
                            "Arial",
                            "Courier New",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ]}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    {/*<Item name="separator" />*/}
                    {/*<Item name="insertTable" />*/}
                    {/*<Item name="deleteTable" />*/}
                    {/*<Item name="insertRowAbove" />*/}
                    {/*<Item name="insertRowBelow" />*/}
                    {/*<Item name="deleteRow" />*/}
                    {/*<Item name="insertColumnLeft" />*/}
                    {/*<Item name="insertColumnRight" />*/}
                    {/*<Item name="deleteColumn" />*/}
                </Toolbar>
            </HtmlEditor>
        </FormGroup>
    );
};

export const DxHtmlEditorForTaskDetail = (id, text, event) => {
    loadMessages(localizationDevExtreme);
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <HtmlEditor defaultValue={event.state.htmlDescription} height="300px">
                <MediaResizing enabled={true} />
                <Toolbar multiline={false}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={[
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ]}
                    />
                    <Item
                        name="font"
                        acceptedValues={[
                            "Arial",
                            "Courier New",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ]}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    {/*<Item name="separator" />*/}
                    {/*<Item name="insertTable" />*/}
                    {/*<Item name="deleteTable" />*/}
                    {/*<Item name="insertRowAbove" />*/}
                    {/*<Item name="insertRowBelow" />*/}
                    {/*<Item name="deleteRow" />*/}
                    {/*<Item name="insertColumnLeft" />*/}
                    {/*<Item name="insertColumnRight" />*/}
                    {/*<Item name="deleteColumn" />*/}
                </Toolbar>
            </HtmlEditor>
        </FormGroup>
    );
};
export const DxHtmlEditorViewer = (id, text, event) => {
    loadMessages(localizationDevExtreme);
    return (
        <FormGroup>
            <Label htmlFor={id}>
                <strong>{text}</strong>
            </Label>
            <HtmlEditor
                value={metrics[id]}
                valueType="html"
                height="300px"
                onValueChanged={(e) => (metrics.selectedTaskdes = e.value)}
            >
                <MediaResizing enabled={true} />
                <Toolbar multiline={false}>
                    <Item name="undo" />
                    <Item name="redo" />
                    <Item name="separator" />
                    <Item
                        name="size"
                        acceptedValues={[
                            "8pt",
                            "10pt",
                            "12pt",
                            "14pt",
                            "18pt",
                            "24pt",
                            "36pt",
                        ]}
                    />
                    <Item
                        name="font"
                        acceptedValues={[
                            "Arial",
                            "Courier New",
                            "Georgia",
                            "Impact",
                            "Lucida Console",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                        ]}
                    />
                    <Item name="separator" />
                    <Item name="bold" />
                    <Item name="italic" />
                    <Item name="strike" />
                    <Item name="underline" />
                    <Item name="separator" />
                    <Item name="alignLeft" />
                    <Item name="alignCenter" />
                    <Item name="alignRight" />
                    <Item name="alignJustify" />
                    <Item name="separator" />
                    <Item name="orderedList" />
                    <Item name="bulletList" />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
                    <Item name="separator" />
                    <Item name="color" />
                    <Item name="background" />
                    <Item name="separator" />
                    <Item name="link" />
                    <Item name="image" />
                    <Item name="separator" />
                    <Item name="clear" />
                    <Item name="codeBlock" />
                    <Item name="blockquote" />
                    {/*<Item name="separator" />*/}
                    {/*<Item name="insertTable" />*/}
                    {/*<Item name="deleteTable" />*/}
                    {/*<Item name="insertRowAbove" />*/}
                    {/*<Item name="insertRowBelow" />*/}
                    {/*<Item name="deleteRow" />*/}
                    {/*<Item name="insertColumnLeft" />*/}
                    {/*<Item name="insertColumnRight" />*/}
                    {/*<Item name="deleteColumn" />*/}
                </Toolbar>
            </HtmlEditor>
        </FormGroup>
    );
};
export const FetchJSON = (url, isThirdPartLogin) =>

    new Promise((resolve) => {

        let sessionTimeOut = sessionCalculate();

        if (isThirdPartLogin === true) {
            callAPI(
                callApiGet,
                url,
                {},
                async (res) => {

                    resolve(res.data);
                },
                true
            );
        } else {
            if (sessionTimeOut <= 20) {
                callAPI(
                    callApiGet,
                    url,
                    {},
                    async (res) => {

                        resolve(res.data);
                    },
                    true
                );
            } else {
            }
        }
    });

export const FetchTEXT = (url) =>
    new Promise((resolve) => {
        let sessionTimeOut = sessionCalculate();
        if (sessionTimeOut <= 20) {
            fetch(url)
                .then((res) => res.text())
                .then((result) => {
                    //localStorage.setItem('loginTime', new Date())
                    resolve(result);
                });
        } else {
        }
    });
const DxTreeViewFav = (id, text, event, data, e) => {
    return (
        <TreeView
            style={{ zIndex: 99999999999999999999999 }}
            dataSource={data}
            dataStructure="plain"
            keyExpr="ID"
            valueExpr="ID"
            parentIdExpr="ParentID"
            selectionMode="single"
            displayExpr="Text"
            height={250}
            selectByClick={true}
            searchEnabled={true}
            selectNodesRecursive={true}
            onSelectionChanged={() => {
                e.component.close();
            }}
            onItemSelectionChanged={(e) => {
                if (e.component.getSelectedNodesKeys()[0] !== undefined) {
                    let selected = e.component.getSelectedNodesKeys()[0];

                    if (id === "selectedTerminationTemplate") {
                        let selectedID = e.component.getSelectedNodesKeys()[0];
                        let selected = data.__rawData.filter((x) => x.ID === selectedID)[0];
                        event.handleChange(id, selectedID, selected.Description);
                    } else {
                        event.handleChange(id, selected);
                    }
                }

                if (id === "selectedMainTaskID") {
                    try {
                        let selectedID = e.component.getSelectedNodesKeys()[0];
                        let selected = data.filter((x) => x.ID === selectedID)[0];
                        event.handleChange("selectedTaskDescription", selected.Description);
                    } catch (e) { }
                }
            }}
        />
    );
};
const DxTreeView = (id, text, event, data, e) => {
    return (
        <TreeView
            style={{ zIndex: 99999999999999999999999 }}
            dataSource={data}
            dataStructure="plain"
            keyExpr="ID"
            valueExpr="ID"
            parentIdExpr="ParentID"
            selectionMode="single"
            displayExpr="Name"
            height={250}
            selectByClick={true}
            searchEnabled={true}
            selectNodesRecursive={true}

            onSelectionChanged={() => {
                e.component.close();
            }}
            onItemSelectionChanged={(e) => {

                if (e.component.getSelectedNodesKeys()[0] !== undefined) {
                    let selected = e.component.getSelectedNodesKeys()[0];

                    if (id === "selectedTerminationTemplate") {
                        let selectedID = e.component.getSelectedNodesKeys()[0];
                        let selected = data.__rawData.filter((x) => x.ID === selectedID)[0];
                        event.handleChange(id, selectedID, selected.Description);
                    } else {
                        event.handleChange(id, selected);
                    }
                }

                if (id === "selectedMainTaskID") {
                    try {
                        let selectedID = e.component.getSelectedNodesKeys()[0];
                        let selected = data.filter((x) => x.ID === selectedID)[0];
                        event.handleChange("selectedTaskDescription", selected.Description);
                    } catch (e) { }
                }
            }}
        />
    );
};
const DxTreeViewTaskCreate = (id, text, event, data, e) => {
    return (
        <TreeView
            style={{ zIndex: 99999999999999999999999 }}
            dataSource={data}
            dataStructure="plain"
            keyExpr="ID"
            valueExpr="ID"
            parentIdExpr="ParentID"
            selectionMode="single"
            displayExpr="Name"
            height={420}
            selectByClick={true}
            searchEnabled={true}
            selectNodesRecursive={true}
            onSelectionChanged={(selectedItem) => {
                if (event.state[id]) {
                    e.component.close();
                }
            }}
            onItemClick={(e) => {
                if (e.node.items.length > 0) {
                    event.handleChange(id, undefined);
                    e.component.expandItem(e.itemData.ID);
                } else {
                    e.component.selectItem(e.itemData.ID);

                    if (e.component.getSelectedNodesKeys()[0] !== undefined) {
                        let selected = e.component.getSelectedNodesKeys()[0];

                        if (id === "selectedTerminationTemplate") {
                            let selectedID = e.component.getSelectedNodesKeys()[0];
                            let selected = data.__rawData.filter(
                                (x) => x.ID === selectedID
                            )[0];
                            event.handleChange(id, selectedID, selected.Description);
                        } else {
                            event.handleChange(id, selected);
                        }
                    }

                    if (id === "selectedMainTaskID") {
                        try {
                            let selectedID = e.component.getSelectedNodesKeys()[0];
                            let selected = data.filter((x) => x.ID === selectedID)[0];
                            event.handleChange(
                                "selectedTaskDescription",
                                selected.Description
                            );
                        } catch (e) { }
                    }
                }
            }}
            onItemSelectionChanged={(e) => { }}
        />
    );
};
const TextForDetailTemplate = (labelText, value) => {
    return (
        <div className="bd-example">
            <dl
                className="row"
                style={{
                    marginBottom: -3,
                }}
            >
                <dt className="col-sm-1"> {labelText} :</dt>
                <dd className="col-sm-11" style={{ paddingLeft: 0 }}>
                    {value}
                </dd>
            </dl>
        </div>
    );
};
const TextAreaForDetailTemplate = (labelText, value) => {
    return (
        <p style={{ whiteSpace: "break-spaces" }}>
            <strong>{labelText} : </strong> {value}
        </p>
    );
};
const elapsedTime = (cellData) => {
    return (
        <div>
            <div className="current-value">{cellData.data.Second}</div>
        </div>
    );
};
const sourceNameSurname = (cellData) => {
    if (cellData.data.SourceUser !== null) {
        return (
            <div>
                <div className="current-value">
                    {cellData.data.SourceUser.Name} {cellData.data.SourceUser.SurName}
                </div>
            </div>
        );
    } else {
        if (cellData.data.SourcePool) {
            return (
                <div>
                    <div className="current-value">
                        {cellData.data.SourcePool.Name} Havuzu
                    </div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }
};
const descriptionRender = (e) => {
    if (
        (e.data.AciklamaCount && e.data.AciklamaCount > 0) ||
        (e.data.AciklamaCount && e.data.AciklamaCount !== undefined)
    ) {
        return (
            <div
                // onClick={() => event.setState({taskID: e.data.TaskID, visibleAciklamaEkle: true})}
                style={{ color: "#35b500", textAlignLast: "center", fontSize: 16 }}
            >
                <i
                    className="icon-bubble"
                    title={
                        "Talebe ait " + e.data.AciklamaCount + " adet açıklama eklenmiştir"
                    }
                />
            </div>
        );
    } else {
        return (
            <div
                // onClick={() => event.setState({taskID: e.data.TaskID, visibleAciklamaEkle: true})}
                style={{ color: "lightgray", textAlignLast: "center", fontSize: 16 }}
            >
                <div>
                    <i className="icon-bubble" title={t("talebeaciklamaeklenmemistir")} />
                </div>
            </div>
        );
    }
};
const SLARow = (e) => {
    if (e.data.SlaMinute > e.data.Category.SLA) {
        return (
            <div>
                <div style={{ color: "red", textAlignLast: "center", fontSize: 16 }}>
                    <i
                        className="icon-hourglass"
                        title={t("talebinslasuresi") + e.data.SlaMinute + t("dakikadir")}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{ color: "lightgray", textAlignLast: "center", fontSize: 16 }}
            >
                <div>
                    <i
                        className="icon-hourglass"
                        title={t("talebinslasuresi") + e.data.SlaMinute + t("dakikadir")}
                    />
                </div>
            </div>
        );
    }
};
const renderWatcherButtons = (e, event, loginUser) => {
    let Model = e.data;

    if (Model.CreateUserID == loginUser.ID || Model.UserID == loginUser.ID) {
        return (
            <div
                style={{ color: "#6b88da", cursor: "pointer" }}
                onClick={() => event.deleteWatcher(Model)}
            >
                <u>{t("sil")}</u>
            </div>
        );
    }
};

const renderButtons = (e, event, loginUser) => {
    let Model = e.data;
    let usr = loginUser;
    let confirmed = true;

    Model.TaskConfirmation.map((item, i) => {
        if (item.ApprovalDate === null) {
            confirmed = false;
        }
    });

    if (Model.TerminationDate === null) {
        if (
            Model.TaskConfirmation !== null &&
            Model.TaskConfirmation.UserID === usr.ID &&
            Model.TaskConfirmation.ApprovalDate == null
        ) {
            return (
                <Row className="align-items-center">
                    <Row>
                        <div
                            style={{ color: "#6b88da", cursor: "pointer" }}
                            onClick={() => null}
                        >
                            <u>{t("onayla")}</u>
                        </div>
                        <div
                            style={{ color: "#6b88da", cursor: "pointer" }}
                            onClick={() => null}
                        >
                            <u>{t("reddet")}</u>
                        </div>
                    </Row>
                </Row>
            );
        } else {
            if (usr.IsLastUser === "False") {
                if (Model.ResponsibleUserID == null) {
                    if (Model.StatusID !== "A62DF37B-8AC8-E811-80F6-005056B839BB") {
                        if (confirmed) {
                            return (
                                <div
                                    style={{
                                        backgroundColor: "#c3d4fc",
                                        color: "#000",
                                        cursor: "pointer",
                                        border: "solid 0px #000",
                                        borderRadius: 10,
                                        paddingLeft: 5,
                                        paddingRight: 5,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                    }}
                                    onClick={() => {
                                        Swal.fire({
                                            title: t("eminMisiniz"),
                                            text: t("talebiDevralacaksiniz"),
                                            icon: "warning",
                                            showCancelButton: true,
                                            cancelButtonText: t("iptal"),
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: t("devral"),
                                        }).then((result) => {
                                            if (result.isConfirmed === true) {

                                                FetchJSON(
                                                    "api/DataCheck/OverCheck2?TaskID=" + e.data.ID
                                                ).then((res) => {
                                                    if (res === true) {
                                                        let url = "/api/TaskDetail/Over?id=" + e.data.ID;

                                                        event.setState({ isLoading: true });
                                                        event.props.actions.isLoading(true);

                                                        FetchTEXT(url).then((data) => {
                                                            if (data) {
                                                                Swal.fire({
                                                                    position: "center",
                                                                    icon: "success",
                                                                    title: t("talepDevralindi"),
                                                                    showConfirmButton: false,
                                                                    timer: 1500,
                                                                });
                                                                //event.props.history.push(
                                                                //    "/TaskDetail?" + e.data.ID
                                                                //);
                                                                window.location.reload();
                                                            } else {
                                                                Swal.fire({
                                                                    position: "center",
                                                                    icon: "danger",
                                                                    title: t("hata"),
                                                                    showConfirmButton: false,
                                                                    timer: 1500,
                                                                });
                                                            }
                                                        });
                                                    } else {
                                                        timeoutPopup(
                                                            t("dahaOnceBuTalebiSizdenOnceBirKullanıcıÜzerineAlmışVeyaİslemYapmış.BuTalepUzerindeIslemYapamazsınız!"),
                                                            "warning",
                                                            t("tamam"),
                                                            7000
                                                        );
                                                    }
                                                });
                                            }
                                        });
                                    }}
                                >
                                    <i
                                        className="icon-arrow-down-circle"
                                        style={{ marginTop: 5 }}
                                    ></i>{" "}
                                    {t("devral")}
                                </div>
                            );
                        }
                    }
                }
            }
        }
    }
};
const renderButtonsİptal = (e, event) => {

    return (
        <div style={{
            backgroundColor: "#F92121",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2
            , visibility: event.state.taskTrackingButtonVis
        }}
            onClick={() => {
                event.TaskCanceled(e.data.ID)

                //event.SetStartDate(e.data.ID);
                // // // this.state.isAssignJob
                // // event.setState({ isAssignJob: true });
                // // event.setState({taskID :e.data.ID})
                //e.data.strIsFinish
                //this.state.jobResponseUser != undefined ? "Talep "+this.state.jobResponseUser.UserName+"' Kullanıcısına Görev Olarak Atanmıştır" :"" 
            }}>

            <i

                className="fa fa-close"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("iptal")}
        </div>
    );
};
const renderButtonsForUpdateDescription = (e, event, t) => {

    return (
        <div style={{
            backgroundColor: "#3495eb",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2
            , visibility: e.data.IconVisible

        }}
            onClick={() => {

                event.GetTaskDescription(e.data.ID)

            }}
        >
            <i

                className="fa fa-pencil"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("guncelle")}
        </div>
    );


};

const renderButtonGörevBaşla = (e, event, t) => {
    return (
        <div style={{
            backgroundColor: "#80d190",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2
            , visibility: e.data.ButtonStart
        }}
            onClick={() => {
                event.SetStartDate(e.data.ID);

                // // // this.state.isAssignJob
                // // event.setState({ isAssignJob: true });
                // // event.setState({taskID :e.data.ID})
                //e.data.strIsFinish
                //this.state.jobResponseUser != undefined ? "Talep "+this.state.jobResponseUser.UserName+"' Kullanıcısına Görev Olarak Atanmıştır" :"" 
            }}>

            <i
                className="fa fa-check"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("başla")}
        </div>
    );
};

const renderButtonGörevDurdur = (e, event, t) => {
    return (
        <div style={{
            backgroundColor: "#d68080",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2
            , visibility: e.data.ButtonStop
        }}
            onClick={() => {
                Swal.fire({
                    title: t("durdurmakiçinaçıklamagiriniz."),
                    input: "textarea",
                    inputAttributes: {
                        "aria-label": "Type your message here",
                    },
                    showCancelButton: true,
                    confirmButtonText: t("kaydet"),
                    cancelButtonText: t("iptal"),
                    showLoaderOnConfirm: true
                }).then((result) => {
                    if (result.isConfirmed) {
                        var Description = result.value;
                        event.SetStopDate(e.data.ID, Description);
                    }
                });
            }}>
            <i
                className="fa fa-stop"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("durdur")}
        </div>
    );
};



const renderButtonGörevIptalEt = (e, event, t) => {
    return (
        <div style={{
            backgroundColor: "#f0b859",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2,
            visibility: e.data.ButtonDelete

        }}
            onClick={() => {
                event.deleteJob(e.data.ID);
            }}>

            <i

                className="fa fa-close"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("iptal")}
        </div>
    );
};

const renderButtonGörevBitir = (e, event, t) => {
    return (
        <div style={{
            backgroundColor: "#ECC01C",
            color: "#000",
            cursor: "pointer",
            border: "solid 0px #000",
            borderRadius: 10,
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 2,
            paddingBottom: 2
            , visibility: e.data.ButtonFinish
        }}
            onClick={() => {
                event.SetFinishDate(e.data.ID);
                // // // this.state.isAssignJob
                // // event.setState({ isAssignJob: true });
                // // event.setState({taskID :e.data.ID})

            }}>

            <i

                className="fa fa-close"
                aria-hidden="true"
                style={{ marginTop: 5 }}
            ></i>{" "}
            {t("bitir")}
        </div>
    );
};

const renderButtonAta = (e, event, loginUser) => {

    let Model = e.data;
    let usr = loginUser;
    let confirmed = true;

    Model.TaskConfirmation.map((item, i) => {
        if (item.ApprovalDate === null) {
            confirmed = false;
        }
    });

    if (Model.TerminationDate === null) {

        if (usr.IsLastUser === "False") {
            if (Model.ResponsibleUserID == null) {
                if (Model.StatusID !== "A62DF37B-8AC8-E811-80F6-005056B839BB") {
                    if (confirmed) {
                        return (
                            <div style={{
                                backgroundColor: "#c3d4fc",
                                color: "#000",
                                cursor: "pointer",
                                border: "solid 0px #000",
                                borderRadius: 10,
                                paddingLeft: 5,
                                paddingRight: 5,
                                paddingTop: 2,
                                paddingBottom: 2,
                            }}
                                onClick={() => {
                                    event.checkAssignAJob(e.data.ID);
                                    // this.state.isAssignJob
                                    event.setState({ isAssignJob: true });
                                    event.setState({ taskID: e.data.ID })


                                }}>

                                <i
                                    className="icon-arrow-up-circle"
                                    style={{ marginTop: 5 }}
                                ></i>{" "}
                                {t("görevAta")}
                            </div>
                        );
                    }
                }
            }
        }
    }
};











const attachmentRender = (e) => {
    if (
        (e.data.EkdosyaCount && e.data.EkdosyaCount > 0) ||
        (e.data.EkdosyaCount && e.data.EkdosyaCount == undefined)
    ) {
        return (
            <div>
                <div style={{ color: "red", textAlignLast: "center", fontSize: 16 }}>
                    <i
                        className="icon-paper-clip"
                        title={
                            "Talebe ait " + e.data.EkdosyaCount + " adet dosya eklenmiştir"
                        }
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{ color: "lightgray", textAlignLast: "center", fontSize: 16 }}
            >
                <div>
                    <i
                        className="icon-paper-clip"
                        title={"Talebe herhangi bir dosya eklenmemiştir"}
                    />
                </div>
            </div>
        );
    }
};
const linkRenderer = (e) => {

    return (
        <Link to={"/TaskDetail?" + e.data.ID}>

            <p className={"m-0"} style={{ color: "#0075bf", textAlign: "center" }}>
                <strong>
                    <u>{e.data.TaskNumber}</u>
                </strong>
            </p>

        </Link>
    );
};

const jobRender = (e) => {
    if (e.data.GorevliKisiler) {
        return (
            <div>
                <div style={{ color: "red", textAlignLast: "center", fontSize: 16 }}>
                    <i
                        className="icon-people"
                        title={
                            // "Talebe ait " + e.data.GorevCount + " adet görev eklenmiştir"
                            e.data.GorevliKisiler
                        }
                    />
                </div>
            </div>
        );
    } else {
        return (
            <div
                style={{ color: "lightgray", textAlignLast: "center", fontSize: 16 }}
            >
                <div>
                    <i
                        className="icon-people"
                        title={"Talebe herhangi bir görev eklenmemiştir"}
                    />
                </div>
            </div>
        );
    }
};

const aciklamaRenderer = (e) => {
    if (e.data.HTMLDescription) {
        let a = e.data.HTMLDescription;
        let str = a.length > 0 ? a.split("[")[0] : "";
        let end =
            +a.length > 0 ? (a.split("]").length > 0 ? a.split("]")[1] : "") : "";
        let calc = str + end;
        let id = "PopoverFocus" + e.data.ID;

        return (
            <div>
                <Button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    className={"p-0 m-0 taskPoolBtns"}
                    id={id}
                    type="button"
                >
                    {e.data.HTMLDescription}
                </Button>
                <UncontrolledPopover trigger="focus" placement="left" target={id}>
                    <PopoverBody style={{ backgroundColor: "#fdfaea", fontSize: "14px" }}>
                        {e.data.HTMLDescription}
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    } else {
        let a = htmlToText(e.data.Description)
            .replaceAll("//", "/")
            .replaceAll("[", "")
            .replaceAll("]", "");
        let str = a.length > 0 ? a.split("[")[0] : "";
        let end =
            +a.length > 0 ? (a.split("]").length > 0 ? a.split("]")[1] : "") : "";
        let calc = str + end;
        let id = "PopoverFocus" + e.data.ID;

        return (
            <div>
                <Button
                    style={{ backgroundColor: "transparent", border: "none" }}
                    className={"p-0 m-0 taskPoolBtns"}
                    id={id}
                    type="button"
                >
                    {str}
                </Button>
                <UncontrolledPopover trigger="focus" placement="left" target={id}>
                    <PopoverBody style={{ backgroundColor: "#fdfaea", fontSize: "14px" }}>
                        {str}
                    </PopoverBody>
                </UncontrolledPopover>
            </div>
        );
    }
};
const aciliyetRender = (e) => {
    if (e.data.DegreeOfUrgencyStr === "Yüksek") {
        return (
            <div
                style={{ cursor: "default" }}
                className="ml-0 btn btn-danger aciliyetButton mr-0"
            >
                {t(e.data.DegreeOfUrgencyStr.toLowerCase())}
            </div>
        );
    }
    if (e.data.DegreeOfUrgencyStr === "Orta") {
        return (
            <div
                style={{ cursor: "default" }}
                className="ml-0 btn btn-warning aciliyetButton mr-0"
            >
                {t(e.data.DegreeOfUrgencyStr.toLowerCase())}
            </div>
        );
    }
    if (e.data.DegreeOfUrgencyStr === "Düşük") {
        return (
            <div
                style={{ cursor: "default" }}
                className="ml-0 btn btn-success aciliyetButton mr-0"
            >
                {t(e.data.DegreeOfUrgencyStr.toLowerCase())}
            </div>
        );
    }
};
const kategoriRender = (e) => {

    if (e.data.Category.Color) {
        return (
            <div
                style={{
                    fontSize: 12,
                    borderRadius: 20,
                    width: "auto",
                    backgroundColor: e.data.Category.Color,
                    padding: "0px 7px 0px 7px",
                    cursor: "default",
                }}
                className="btn taskPoolBtns"
            >
                {e.data.Category.Name}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    fontSize: 12,
                    borderRadius: 20,
                    width: "auto",
                    backgroundColor: e.data.Category.Color,
                    padding: "0px 7px 0px 7px",
                    cursor: "default",
                }}
                className="btn"
            >
                {e.data.Category.Name}
            </div>
        );
    }
};

const ParentkategoriRender = (e) => {
    if (e.data.Category.Color) {
        return (
            <div
                style={{
                    fontSize: 12,
                    borderRadius: 20,
                    width: "auto",
                    backgroundColor: e.data.Category.Color,
                    padding: "0px 7px 0px 7px",
                    cursor: "default",
                }}
                className="btn taskPoolBtns"
            >
                {e.data.ParentCategory}
            </div>
        );
    } else {
        return (
            <div
                style={{
                    fontSize: 12,
                    borderRadius: 20,
                    width: "auto",
                    backgroundColor: e.data.Category.Color,
                    padding: "0px 7px 0px 7px",
                    cursor: "default",
                }}
                className="btn"
            >
                {e.data.ParentCategory}
            </div>
        );
    }
};
const targetNameSurname = (cellData) => {

    if (cellData.data.TargetUser !== null) {
        return (
            <div>
                <div className="current-value">
                    {cellData.data.TargetUser.Name} {cellData.data.TargetUser.SurName}
                </div>
            </div>
        );
    } else {
        if (cellData.data.TargetPool) {
            return (
                <div>
                    <div className="current-value">
                        {cellData.data.TargetPool.Name} Havuzu
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
};
const description = (cellData, event) => {
    return (
        <div>
            <div className="current-value">
                {cellData.data.Description ? (
                    cellData.data.Description.length > 150 ? (
                        <a
                            onClick={() => {
                                event.setState({
                                    selectedDescription: cellData.data.Description,
                                    visibleDescription: true,
                                });
                            }}
                        >
                            {" "}
                            {cellData.data.Description}
                        </a>
                    ) : (
                        cellData.data.Description
                    )
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};









const documentRender = (cellData) => {
    if (cellData.data.TaskLogDocument.length > 0) {
        return (
            <div>
                {cellData.data.TaskLogDocument.map((item, i) => {
                    let length = item.Document.FilePath.toString().split("/").length;
                    return (
                        <a
                            key={i}
                            style={{ height: 10 }}
                            href={item.Document.FilePath}
                            download={item.Document.FilePath.split('_')[item.Document.FilePath.split('_').length - 1].toString()}
                        >
                            <img
                                title={item.Document.FilePath.toString().split("/")[length - 1]}
                                style={{ height: 20 }}
                                src={metrics.taskLogDocumentImage}
                            />
                        </a>
                    );
                })}
            </div>
        );
    }
};
const sessionCalculate = () => {
    if (localStorage.getItem("loginTime")) {
        var dif = new Date() - new Date(localStorage.getItem("loginTime"));
        var dif = Math.round(dif / 1000 / 60);
        return dif;
    } else {
        return 0;
    }
};

class DetailTemplateForOwnTask extends React.Component {
    componentDidMount() {
        this.renderActionButtons();
        this.setState({ selectedRows: [] });
    }

    onClick = (key, value) => {
        ths.handleChange(key, value);
    };

    taskActions(actionType) {
        let taskID = this.props.data.data.ID;

        if (actionType === "onayla") {
            Swal.fire({
                title: languages.onayAciklamasiGiriniz,
                input: "textarea",
                inputAttributes: {
                    "aria-label": "Type your message here",
                },
                showCancelButton: true,
                confirmButtonText: languages.onayVer,
                cancelButtonText: languages.iptal,
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    let url =
                        "/api/OwnConfirmTask/Confirm?id=" +
                        taskID +
                        "&Description=" +
                        login;

                    return FetchTEXT(url).then((data) => {
                        return data;
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.getData();

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: languages.onayBasariylaVerilmistir,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            });
        }
        if (actionType === "reddet") {
            Swal.fire({
                title: languages.iptalAciklamasiGiriniz,
                input: "textarea",
                inputAttributes: {
                    "aria-label": "Type your message here",
                },
                showCancelButton: true,
                confirmButtonText: languages.iptal,
                cancelButtonText: languages.vazgec,
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    let url =
                        "/api/OwnConfirmTask/Reject?id=" + taskID + "&Description=" + login;

                    return FetchTEXT(url).then((data) => {
                        return data;
                    });
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.getData();

                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: languages.talepBasariylaIptalEdilmistir,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            });
        }
        if (actionType === "devral") {
            Swal.fire({
                title: languages.eminMisiniz,
                text: languages.talebiDevralacaksiniz,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: languages.iptal,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: languages.devral,
            }).then((result) => {
                if (result.isConfirmed === true) {
                    let url = "/api/TaskDetail/Over?id=" + taskID;

                    FetchJSON(url).then((res) => {
                        if (res) {
                            this.onClick("redirect", taskID);

                            Swal.fire({
                                position: "center",
                                icon: "success",
                                title: languages.talepDevralindi,
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        } else {
                            Swal.fire({
                                position: "center",
                                icon: "danger",
                                title: languages.hata,
                                showConfirmButton: false,
                                timer: 1500,
                            });
                        }
                    });
                }
            });
        }
    }

    renderActionButtons() {
        let Model = this.props.data.data;
        return (
            <Row className="align-items-center">
                <Col>
                    <Link to={"/TaskDetail?" + Model.ID}>
                        <Button block color="info">
                            {<TranslationRender header={"incele"} />}
                        </Button>
                    </Link>
                </Col>
                <Col></Col>
            </Row>
        );
    }

    render() {
        return <RenderDetailTemplate ths={this} />;
    }
}

class DetailTemplateForConfirm extends React.Component {
    onClick = (key, value) => {
        ths.handleChange(key, value);
    };

    render() {
        const data = this.props.data;

        let a = htmlToText(data.data.NewDescription).replaceAll("//", "/");
        let str = a.length > 0 ? a.split("[")[0] : "";

        return (
            <>
                <Row>
                    <Col
                        style={{ marginTop: 15, marginLeft: -50 }}
                        xs="12"
                        sm="12"
                        md="12"
                        lg="3"
                        xl="3"
                    >
                        <Row style={{ justifyContent: "center" }}>
                            <Button
                                onClick={() => this.onClick("confirm", data.data.ID)}
                                color="success"
                            >
                                <i className="fa fa-check"></i>
                            </Button>
                            <Button
                                style={{ marginLeft: 10 }}
                                onClick={() => this.onClick("reject", data.data.ID)}
                                color="danger"
                            >
                                <i className="fa fa-close"></i>
                            </Button>
                        </Row>
                        <Row style={{ justifyContent: "center", marginTop: 10 }}>

                            <Link to={"/TaskDetail?" + data.data.ID}>
                                <Button color="info">{t("incele")}</Button>
                            </Link>
                        </Row>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="5">
                        {TextAreaForDetailTemplate(t("talepSahibi"), data.data.Createname)}
                        {TextAreaForDetailTemplate(t("firma"), data.data.Company)}
                        {TextAreaForDetailTemplate(t("departman"), data.data.Department)}
                        {TextAreaForDetailTemplate(t("görev"), data.data.DepartmentJob)}
                        {TextAreaForDetailTemplate(t("kategori"), data.data.CategoryName)}
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="3" xl="4">
                        {TextAreaForDetailTemplate(t("açıklama"), str)}
                        {TextAreaForDetailTemplate(
                            t("aciliyet"),
                            data.data.DegreeOfUrgency === 0
                                ? t("düşük")
                                :
                                data.data.DegreeOfUrgency === 1
                                    ? t("orta")
                                    : data.data.DegreeOfUrgency === 2
                                        ? t("yüksek")
                                        : t("yüksek")
                        )}
                        {TextForDetailTemplate(t("etki"), data.data.Effect)}
                    </Col>
                </Row>
            </>
        );
    }
}
