import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as BB from "../../../../components/BBComponent";
import { timeoutPopup } from "../../../../components/BBComponent";
import Swal from "sweetalert2";
import { bindActionCreators } from "redux";
import notify from "devextreme/ui/notify";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import ReactHtmlParser from "react-html-parser";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { CustomCard, LabelGroup } from "../../../../components/custom";
import classNames from "classnames";
import "../../../../scss/_taskDetail.scss";
import TaskTerminationModal from "./modals/termination";
import TaskUpdateDescription from "./modals/updateDescription";
import AddJobModal from "./modals/addJob";
import TakeOverUser from "./modals/takeOverUser";
import ChangeCategoryByTask from "./modals/changeCategoryByTask";
import TaskChangeUrgencyModal from "./modals/urgency";
import AdditionalConfirmationModal from "./modals/additionalConfirmation";
import TaskTransferModal from "./modals/transfer";
import TaskAddWatcher from "./modals/watcher";
import TaskAddDescription from "./modals/addDescription";
import TaskViewDescription from "./modals/viewDescription";
import TaskChangeProcessStatus from "./modals/changeProcessStatus";
import TaskViewForm from "./modals/viewForm";
import AdditionalDescription from "./modals/additionalDescription";
import ReactTooltip from 'react-tooltip';
import TaskViewSheet from "./modals/viewSheet";
import CustomStore from "devextreme/data/custom_store";
import jwt_decode from "jwt-decode";
import { callAPI, callApiGet, callApiPost } from "../../../../components/Axios";

import { data } from "jquery";
import "devextreme/integration/jquery";

class TaskDetail extends Component {
  constructor(props) {
    super(props);

    let user = null;
    var token = localStorage.getItem("token");

    if (token) {
      user = jwt_decode(token);
    }


    this.state = {
      data: [],
      status: [],
      customFormData: [],
      taskLog: [],
      taskLogDescription: [],
      userList: [],
      taskID: this.props.location.state
        ? this.props.location.state.taskID
        : this.props.location.search
        ? this.props.location.search.toString().replace("?", "")
        : null,
      redirect: this.props.location.state
        ? this.props.location.state.taskID
        : this.props.location.search
        ? false
        : true,
      taskNumber: "",
      visibleAciklamaGüncelle: false,
      visibleAciliyet: false,
      selectedCompanyUserID: undefined,
      selectedAdditionalConfirmationDescription: "",
      selectedCategoryID: undefined,
      visibleSonlandir: false,
      visibleDevret: false,
      visibleAciklamaEkle: false,
      visibleDurumDegistir: false,
      visibleAdditionalConfirmation: false,
      selectedOverUserId: undefined,
      visiblePoolUser: false,
      ComponentFileUpload: [],
      selectedDegreeOfUrency: 0,
      selectedDegreeOfUrencyDesc: "",
      selectedTerminationType: undefined,
      selectedTerminationTemplate: "",
      selectedTerminationFile: [],
      selectedPoolDevret: null,
      selectedDescriptionDevret: "",
      selectedCommentFiles: [],
      selectedCommentDescription: "",
      selectedShareOwner: false,
      selectedShareOther: false,
      selectedShareOtherMails: [],
      selectedCustomControl: [],
      selectedStatus: "",
      poolUsers: [],
      selectedTaskFiles: [],
      customElements: null,
      selectedPoolUserID: null,
      NotifyDepartment: false,
      visibleDescription: false,
      selectedDescription: "",
      sonlandirStatus: false,
      changeStatus: null,
      selectedEndDate: new Date(),
      selectedLastStatus: null,
      selectedTimed: false,
      following: 0,
      iconVisible: "none",
      myJobs: [],
      jobStatus: [],
      selectedProjectNumber: "",
      isLastUser: "block",
      additionalConfirmationVisibleToolbar: false,
      previewVisible: false,
      answer_data: [],
      selectedForm: [],
      selectedFormName: undefined,
      selectedFormID: undefined,
      changeCategoryModalVisible: false,
      selectedCompanyUserID: undefined,
      companyUser: [],
      preview2Visible: false,
      rows: [],
      selectedSheet: [],
      selectedSheetName: undefined,
      selectedSheetID: undefined,
      WatcherUsers: [],
      visibleWatcher: false,
      visibleJob: false,
      selectedWatcherUsers: [],
      activeTab: "1",
      selectedTerminationDescription: "",
      recentlyViewed: [],
      terminationStatus: [],
      terminateTemplate: [],
      customElementItemsDatas: "",
      denemeDropdown: [],
      loginUser: user,
      zorunluOlan: [],
      taskCreator: false,
      sla: 0,
      fileUploaderVisible: "none",
      poolUsers2: [],
      selectedUserId: undefined,
      takeOverUserVisible: false,
      selectedMessage: "",
      companies: [],
      selectedCompanyID: undefined,
      selectedParentCategory: undefined,
      selectedChildCategory1: undefined,
      selectedChildCategory2: undefined,
      selectedChildCategory3: undefined,
      selectedChildCategory4: undefined,
      selectedChildCategory5: undefined,
      selectedChildCategory6: undefined,
      taskCategories: [],
      selectedCategoryName: undefined,
      selectCategory: false,
      childCategory1Arr: [],
      childCategory2Arr: [],
      childCategory3Arr: [],
      childCategory4Arr: [],
      childCategory5Arr: [],
      childCategory6Arr: [],
      categoryPath: "",
      selectedCategory: undefined,
      iconCategoryVisible: "none",
      additionalUsersForTask: [],
      updatedDescription: "",
      updateViewClient: false,
      selectedTaskLogId: undefined,
      updatedTaskDocuments: [],
      tempViewClient: false,
      taskDescDocumentsName: [],
      taskDescDocuments: [],
      deletedDocuments: [],
      updateDescriptionButtonVis: "none",
      companySelectTool: "",
      companySelectToolListVisible: false,
      taskCompaniesTemp: [],
      effor:"",
      additionalDescriptionValue:"",
      visibleAdditionalDescription:false,
      languagesArr:[],
      selectedWatcherGroups:[],
      watcherList:[],
      selectedWatcherUserID: [],
      selectedWatcherUserList: [],
      oldGroupUsers: [],
      selectedWatcherGroupsID: [],
      isMobile: window.innerWidth <= 768
    };

    this.customElement = React.createRef();
    let { t } = this.props;



    this.languages = {
      hata: t("hata"),
      onayVer: t("onayVer"),
      iptal: t("iptal"),
      vazgec: t("vazgec"),
      vazgec2: t("talepAciklamasi"),
      devral: t("devral"),
      eminMisiniz: t("eminMisiniz"),
      talepDevralindi: t("talepDevralindi"),
      talebiDevralacaksiniz: t("talebiDevralacaksiniz"),
      onayAciklamasiGiriniz: t("onayAciklamasiGiriniz"),
      iptalAciklamasiGiriniz: t("iptalAciklamasiGiriniz"),
      onayBasariylaVerilmistir: t("onayBasariylaVerilmistir"),
      talepBasariylaIptalEdilmistir: t("talepBasariylaIptalEdilmistir"),
      talepBasariylaDevredildi: t("talepBasariylaDevredildi"),
      havuzAlaniZorunludur: t("havuzAlaniZorunludur"),
      durumAlaniZorunludur: t("durumAlaniZorunludur"),
      talepDurumDegistirmeBasarili: t("talepDurumDegistirmeBasarili"),
      aciklamaAlaniZorunludur: t("aciklamaAlaniZorunludur"),
      dosyaEklemeZorunludur: t("dosyaEklemeZorunludur"),
      hizliTalepOlusturAciklama:t("hizliTalepOlusturAciklama"),
      EkOnay: t("EkOnay"),
      talepBasariylaSonlandirildi: t("talepBasariylaSonlandirildi"),
      sonlandirmaAciklamasiAlaniZorunludur: t(
        "sonlandirmaAciklamasiAlaniZorunludur"
      ),
      talebinAciliyetiBasariylaDegistirildi: t(
        "talebinAciliyetiBasariylaDegistirildi"
      ),
      talebinAciliyetiDegistirilirkenHataAlindi: t(
        "talebinAciliyetiDegistirilirkenHataAlindi"
      ),
      sonlandirmaTuruAlaniZorunludur: t("sonlandirmaTuruAlaniZorunludur"),
      talepDurumuBeklemedeOlanHavuzaGonderilmez: t(
        "talepDurumuBeklemedeOlanHavuzaGonderilmez"
      ),
      ozelAlanZorunlu: t("ozelAlanZorunlu"),
      TalepSorumlusuOlmanızVeyaTalebinOnaySürecininBitmesiGrekmektedir:t("TalepSorumlusuOlmanızVeyaTalebinOnaySürecininBitmesiGrekmektedir"),

      göreveBaslanmıştır:t("göreveBaslanmıştır."),
      görevDurduruldu: t("görevDurduruldu."),
      göreveBaslatılırkenHata:t("göreveBaslatılırkenHata."),
      gorevTamamlanmıstır:t("gorevTamamlanmıstır."),
      goreveTamamlanırkenHata:t("goreveTamamlanırkenHata."),
      lutfenTalebiOnceIslemeAlınız:t("lutfenTalebiOnceIslemeAlınız"),
      onaylıyorMusunuz:t("onaylıyorMusunuz?"),
      kategoriBasarıIleDegistirildi:t("kategoriBasarıIleDegistirildi"),
      talepSahibininYöneticisiBosOlamaz:t("talepSahibininYöneticisiBosOlamaz"),
      kategoriDegistirilemedi:t("kategoriDegistirilemedi"),
      sonlandırılmısBirTalebinKategorisiDegistirilemez:t("sonlandırılmısBirTalebinKategorisiDegistirilemez."),
      secmisOldugunuzKategoriTalebinKategorisiIleAynıdır:t("secmisOldugunuzKategoriTalebinKategorisiIleAynıdır"),
      secmisOldugunuzKategorininOnaySeviyesiMevcutKategoridenFarklıdırLutfenIptalEdipTekrarTalepAcınız:t("secmisOldugunuzKategorininOnaySeviyesiMevcutKategoridenFarklıdırLutfenIptalEdipTekrarTalepAcınız"),
      gorevIptalEdilirkenHata:t("gorevIptalEdilirkenHata!!!"),
      sonlandırılmısBirTalebinSorumlusuDegistirilemez:t("sonlandırılmısBirTalebinSorumlusuDegistirilemez."),
      talepDevredilirkenHata:t("talepDevredilirkenHata!!!"),
      gorevAtanamadı:t("gorevAtanamadı"),
      talepDahaOnceOnaylanmıştır:t("talepDahaOnceOnaylanmıştır"),
      evet:t("evet"),
      hayır:t("hayır"),
      talebintakibibırakılacaktır: t("talebintakibibırakılacaktır"),
      Görev:t("Görev"),
      redAcıklamasıGiriniz:t("redAcıklamasıGiriniz"),
      dahaOnceBuTalebiSizdenOnceBirKullanıcıÜzerineAlmışVeyaİslemYapmışBuTalepUzerindeIslemYapamazsınız:t("dahaOnceBuTalebiSizdenOnceBirKullanıcıÜzerineAlmışVeyaİslemYapmış.BuTalepUzerindeIslemYapamazsınız!"),
      talepIslemDurumuTalebiKapatmayaUygunDegilOncelikleIslemeAlınız:t("talepIslemDurumuTalebiKapatmayaUygunDegil,OncelikleIslemeAlınız"),
      durumDegistir:t("durumDegistir"),
      formGoruntule:t("formGoruntule"),
      dahaOncekiBirZamanSecemezsiniz:t("dahaOncekiBirZamanSecemezsiniz"),
      talepTakibiBasarılı:t("talepTakibiBasarılı"),
      eforKaydınıSadeceTalepSorumlusuGüncelleyebilir:t("eforKaydınıSadeceTalepSorumlusuGüncelleyebilir."),
      Görevbaşarıylasilindi:t("Görevbaşarıylasilindi"),
      göreviptaledilirkenhata:t("göreviptaledilirkenhata"),
      talebbasarıiledevredildi:t("talebbasarıiledevredildi"),
      Görevbaşarıylaatandı:t("Görevbaşarıylaatandı"),
      görevatanamadi:t("görevatanamadi"),
      redded:t("redded"),
      talepdahaöncereddedilmiştir:t("talepdahaöncereddedilmiştir"),
      tamam:t("tamam"),
      talepdevralınmış:t("talepdevralınmış"),
      talebdahaöncesindeişlemealımıs:t("talebdahaöncesindeişlemealımıs"),
      TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız:t("TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız"),
      Dahaöncekibirzamanseçemezsiniz:t("Dahaöncekibirzamanseçemezsiniz"),
      görevatanamadi:t("görevatanamadi"),
      Dahaöncekibirzamanseçemezsiniz: t("Dahaöncekibirzamanseçemezsiniz"),
      eforKaydınıSadeceTalepSorumlusuGüncelleyebilir:t("eforKaydınıSadeceTalepSorumlusuGüncelleyebilir."),
      eforKaydınıSadeceTalepSorumlusuGüncelleyebilir:t("eforKaydınıSadeceTalepSorumlusuGüncelleyebilir."),
      basarili:t("Basarılı!"),
      birHataAlındı: t("birHataAlındı!"),
      DTiçinUygunKategorilerSeciliDegildir: t("DTiçinUygunKategorilerSeciliDegildir."),
      buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur: t("buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur."),
      projeNumarasıAlanınıSadeceTalepSorumlusuDegistirebilir:t("projeNumarasıAlanınıSadeceTalepSorumlusuDegistirebilir!"),
      EkOnayaGönder: t("EkOnayaGönder"),
      izleyiciEkle: t("izleyiciEkle"),
      izleyici:t("izleyici"),
      GörevEkle: t("GörevEkle"),
      yukleniyor: t("yukleniyor")
    };

  }

   handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768  // Eğer ekran genişliği 768'den küçükse mobil olarak kabul et
        });
    }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getData(nextProps.location.search.slice(1));
    }
  }
  
  GetWatcherGroups() {
    callAPI(
      callApiGet,
      `api/User/GetWatcherGroupsByUserId`,
      {},
      async (res) => {
        if (res?.data) {
          this.setState({
            oldGroupUsers: res?.data
          });
        }
      },
      true
    );
  }

  ///api/User/GetUserList

  GetAllUsersForWatcher() {
    callAPI(
      callApiGet,
      `api/User/GetUserList`,
      {},
      async (res) => {
        if (res?.data) {
          this.setState({
            watcherList: res?.data
          });
        }
      },
      true
    );
  }

  GetWatcherGroupUsers(group) {
    callAPI(
      callApiGet,
      `api/User/GetGroupUsersByGroupId`,
      {watchersGroupID: JSON.stringify(group)},
      async (res) => {
       if(res?.data != null){
        this.setState({selectedWatcherGroups:res?.data});
       }
      },
      true
    );
  }
  
  componentDidMount() {
    this.getAdditionalConfirmationUsers(this.state.taskID);
    this.checkIsLastUser();
    this.getData();
    this.checkChangeCategoryAccept(this.state.taskID);
    //await this.GetCompanyUsers(this.state.taskID);
    this.GetMyJobs(this.state.taskID);

    //this.GetCustomForm(this.state.taskID);

    this.GetCustomSheet(this.state.taskID);
    this.GetRecentlyViewed(this.state.taskID);
    this.GetWatcherUsers(this.state.taskID);
    this.GetCustomElementsForClosing(this.state.data);
    this.renderActionButtons();
    this.GetWatcherGroups();
    this.GetAllUsersForWatcher();
    //this.GetSlaMinuteByTaskNumber(this.state.data);
  }

  getData(TaskID) {
    let taskID = TaskID !== undefined ? TaskID : this.state.taskID;

    this.props.actions.isLoading(true);

    this.props.history.push("/TaskDetail?" + taskID);

    this.isOwnTask = this.props.location.state
      ? this.props.location.state.ownTask
      : null;

    let url2 = "/api/TaskDetail/AddRecentlyViewed?TaskID=" + taskID;
    callAPI(
      callApiGet,
      url2,
      {},
      async (res) => {
        let url =
          "api/TaskDetail/GetGridData?ID=" + taskID + "&TaskDetail=true";

        callAPI(
          callApiGet,
          url,
          {},
          async (res) => {
            if (res?.data) {


              let changeIconVisible = "none";
              let all = res?.data.TaskLog;
              let meID = this.state.loginUser.ID;
              let pool = res?.data;
              let descrtiption = [];
              let descrtiption2 = [];
              let status = false;
              let poolID = res?.data.PoolID;
              let viewerUserPool = res?.data.ViewerUserPool;
              let pools = [];

              let isMyTasks = false;


              // burada sla ile yapıaclak

              if(res?.data.Effort === null){
 
                this.setState({effor:0})

              }
              else{

                this.setState({effor:res?.data.Effort})

              }



              if (res?.data.ChangeResponsibleUserVisible === true) {
                changeIconVisible = "block";
              }
              if (res?.data.CreateUserID === meID) {
                isMyTasks = true;
              }

              if (isMyTasks) {
                descrtiption = all.filter(
                  (x) =>
                    (x.LogType === 1 && x.TargetUserID === meID) ||
                    (x.LogType === 1 && x.CreateUserID === meID) ||
                    (x.ViewClient === true && x.LogType === 1) ||
                    (viewerUserPool.includes(x.TargetPoolID) && x.LogType === 1)
                );
              } else {
                descrtiption = all.filter((x) => x.LogType === 1);
              }
              let otherLogs = all.filter((x) => x.LogType !== 1);
              this.GetCustomForm(res?.data.ID);
              
              var dataBekliyor =res?.data;

              if(res?.data.ProjectNumber !== null || res?.data.ProjectNumber !== "null"){

                  if(res?.data.ProjectNumber ==="dt" || res?.data.ProjectNumber === "DT"){

                      if(res?.data.VstsID !=="null" || res?.data.VstsID !==null){
                        this.setState({selectedProjectNumber:res?.data.VstsID})
                      }else{
                        this.setState({selectedProjectNumber:res?.data.ProjectNumber})
                      }
                  }
                  else{
                    this.setState({selectedProjectNumber:res?.data.ProjectNumber})
                  }

              }
              else{
                this.setState({selectedProjectNumber:""})
              }

              this.setState({
                //updateDescriptionButtonVis: res?.data.TaskLog.IconVisible,
                data: res?.data,


                // selectedProjectNumber:
                //   res?.data.ProjectNumber !== null
                //     ? res?.data.ProjectNumber !== "null"
                      
                //       ? res?.data.ProjectNumber
                //       : ""
                //     : "",
                


                taskLog: otherLogs,
                iconVisible: changeIconVisible,
                taskID: res?.data.ID,
                taskLogDescription: descrtiption,

                taskCreator: res?.data.TaskCreator,
              });
            }
          },
          true
        );
        this.props.actions.isLoading(false);
      },
      true
    );
  }
  checkChangeCategoryAccept = (TaskID) => {
    let url = "/api/TaskDetail/CheckChangeCategoryAccept?TaskID=" + TaskID;

    callAPI(
      callApiGet,
      url,
      {},
      (res) => {
        if (res?.data) {
          this.setState({ iconCategoryVisible: res?.data });
        }
      },
      true
    );
  };
  checkAdditionalUserPermission = () => {
    let url =
      "/api/TaskDetail/CheckAdditionalUserPermission?taskID=" +
      this.state.taskID;
    callAPI(
      callApiGet,
      url,
      {},
      (res) => {
        if (res?.data) {
          this.setState(
            { visibleAdditionalConfirmation: true },
            this.getCompanies()
          );
          //this.setState({ additionalConfirmationVisibleToolbar: res?.data })
        } else {
          notify(
            this.languages.TalepSorumlusuOlmanızVeyaTalebinOnaySürecininBitmesiGrekmektedir,
            "error",
            4000
          );
        }
      },
      true
    );
  };
  getAdditionalConfirmationUsers = (TaskID) => {
    let url = "/api/TaskDetail/GetAdditionalConfirmationUsers?taskID=" + TaskID;
    callAPI(
      callApiGet,
      url,
      {},
      (res) => {
        if (res?.data) {
          this.setState({ additionalUsersForTask: res?.data });
        }
      },
      true
    );
  };
  getCategoriesForChangeCategories = () => {
    let url =
      "/api/TaskDetail/GetCategoriesForChangeCategories?taskID=" +
      this.state.taskID;
    callAPI(
      callApiGet,
      url,
      {},
      (res) => {
        if (res?.data) {
          this.setState({ taskCategories: res?.data });
        }
      },
      true
    );
  };

  getParentCategories = (TaskID, ParentID) => {
    let url =
      TaskID !== null &&
      "/api/Task/GetCategoriesForChangeTaskCategories?TaskID=" +
        TaskID +
        "&ParentID=" +
        ParentID;

    this.setState({ parentCategoryLoading: true });

    callAPI(
      callApiGet,
      url,
      {},
      async (res) => {
        if (res?.data) {
          this.setState({
            taskCategories: res?.data,
            parentCategoryLoading: false,
          });

          this.props.actions.isLoading(false);
        }
      },
      true
    );
  };

  
  GetSlaMinuteByTaskNumber = (data) =>
    new Promise((resolve) => {
      var url =
        "/api/TaskDetail/GetSlaMinuteByTaskNo?taskNumber=" + data.TaskNumber;
      callAPI(callApiGet, url, {}, async (res) => {
        this.setState({
          sla: res.data ? res.data : null,
        });
        resolve(true);
      });
    });

  GetCustomElementsForClosing = (data) =>
    new Promise((resolve) => {
      var url =
        "/api/TaskDetail/GetCustomElementsForClosing?id=" + data.CategoryID;
      callAPI(callApiGet, url, {}, async (customElements) => {
        this.setState({
          customElements: customElements.data ? customElements.data : null,
        });
        resolve(true);
      });
    });

  GetProccessStatus = () =>
    new Promise((resolve) => {
      var url = "api/TaskDetail/GetProccessStatus";
      callAPI(callApiGet, url, {}, async (status) => {
        this.setState({
          status: status.data,
        });
        resolve(true);
      });
    });

  GetCompanyUsers = (taskID) =>
    new Promise((resolve) => {
      var url = "api/Main/GetCompanyUsers?TaskID=" + taskID;
      callAPI(callApiGet, url, {}, async (poolUsers) => {
        this.setState({
          poolUsers: poolUsers.data,
        });
        resolve(true);
      });
    });

  GetMyJobs = (taskID) => {
    var url = "api/TaskTracking/GetJobsByTaskID?TaskID=" + taskID;
    callAPI(callApiGet, url, {}, (GetMyJobs) => {
      this.setState({
        myJobs: GetMyJobs.data,
      });
    });
  };

    SetStartDate = (jobID) =>
        new Promise((resolve) => {
            var url = "api/TaskTracking/SetStartDate?jobID=" + jobID;
            callAPI(callApiGet, url, {}, (res) => {
                if (String(res.data) !== "00000000-0000-0000-0000-000000000000") {
                    notify(this.languages.göreveBaslanmıştır, "success", 5000);

                    var url = "api/TaskTracking/GetJobsByTaskID?TaskID=" + res.data;
                    callAPI(callApiGet, url, {}, (GetMyJobs) => {
                        this.setState({
                            myJobs: GetMyJobs.data,
                        });
                        resolve(true);
                    });
                } else {
                    notify(this.languages.göreveBaşlatılırkenHata, "error", 5000);
                }
                resolve(true);
            });
        });
    SetStopDate = (jobID, Description) =>
        new Promise((resolve) => {
            var url = "api/TaskTracking/SetStopDate?jobID=" + jobID + "&Description=" + encodeURIComponent(Description);
            callAPI(callApiGet, url, {}, (res) => {
                console.log("res", res);
                if (String(res.data) !== "00000000-0000-0000-0000-000000000000") {
                    notify(this.languages.görevDurduruldu, "success", 5000);

                    var url = "api/TaskTracking/GetJobsByTaskID?TaskID=" + res.data;
                    callAPI(callApiGet, url, {}, (GetMyJobs) => {
                        this.setState({
                            myJobs: GetMyJobs.data,
                        });
                        resolve(true);
                    });
                } else {
                    notify(this.languages.görevDurdurulurkenHata, "error", 5000);
                }
                resolve(true);
            });
        });

  SetFinishDate = (jobID) =>
    new Promise((resolve) => {
      var url = "api/TaskTracking/SetFinishDate?jobID=" + jobID;
      callAPI(callApiGet, url, {}, (res) => {
        if (String(res.data) !== "00000000-0000-0000-0000-000000000000") {
          notify(this.languages.gorevTamamlanmıstır, "success", 5000);

          var url = "api/TaskTracking/GetJobsByTaskID?TaskID=" + res.data;
          callAPI(callApiGet, url, {}, (GetMyJobs) => {
            this.setState({
              myJobs: GetMyJobs.data,
            });
            resolve(true);
          });
        } else {
          notify(this.languages.goreveTamamlanırkenHata, "error", 5000);
        }
        resolve(true);
      });
    });
  GetCustomForm = (taskID) => {
    BB.FetchJSON("/api/TaskDetail/GetCustomForm?TaskID=" + taskID).then(
      (GetCustomForm) => {
       
        this.setState({
          selectedFormID:
            GetCustomForm.length > 0 ? GetCustomForm[0].FormID : undefined,
          customFormData: GetCustomForm.length > 0 ? GetCustomForm : [],
          selectedFormName:
            GetCustomForm.length > 0 ? GetCustomForm[0].Name : undefined,
          answer_data:
            GetCustomForm.length > 0
              ? JSON.parse(GetCustomForm[0].Values)
              : undefined,
          selectedForm:
            GetCustomForm.length > 0
              ? JSON.parse(GetCustomForm[0].Form).task_data
              : undefined,
          previewVisible: false,
        });
        // resolve(true);
      }
    );
   
  };

  GetCustomSheet = (taskID) =>
    new Promise((resolve) => {
      BB.FetchJSON("/api/TaskDetail/GetCustomSheet?TaskID=" + taskID).then(
        (GetCustomSheet) => {
          this.setState({
            selectedSheetID:
              GetCustomSheet.length > 0 ? GetCustomSheet[0].FormID : undefined,
            customSheetData: GetCustomSheet.length > 0 ? GetCustomSheet : [],
            selectedSheetName:
              GetCustomSheet.length > 0 ? GetCustomSheet[0].Name : undefined,
            rows:
              GetCustomSheet.length > 0
                ? JSON.parse(GetCustomSheet[0].Values)
                : [],
            selectedSheet:
              GetCustomSheet.length > 0
                ? JSON.parse(GetCustomSheet[0].Form)
                : undefined,
          });
          resolve(true);
        }
      );
    });
  deleteSelectedFile = (name) => {
    let newFiles = [];
    this.state.selectedTaskFiles.map((item, i) => {
      if (item.name !== name) {
        newFiles.push(item);
      }
    });
    
    this.setState({ selectedTaskFiles: newFiles });
    this.setState({ ComponentFileUpload: newFiles });
  };

  deleteSelectedTerminationFile = (name) => {
    let newFiles = [];
    this.state.selectedTerminationFile.map((item, i) => {
      if (item.name !== name) {
        newFiles.push(item);
      }
    });
    this.setState({ selectedTerminationFile: newFiles });
    this.setState({ ComponentFileUpload: newFiles });
  };

  GetTaskDescription = (ID) => {
    let taskDescDocuments1 = [];
    let taskDescDocumentsName1 = [];
    let deletedDocuments = [];
    let dataArray = this.state.updatedTaskDocuments;
    var url = "/api/TaskDetail/GetTaskDescriptionByID?ID=" + ID;
    callAPI(callApiGet, url, {}, (res) => {
      if (res.data.Documents.length > 0) {
        for (let i = 0; i < res.data.Documents.length; i++) {
          let currentDocument = res.data.Documents[i];
          if (currentDocument.DocumentType === "2") {
            taskDescDocuments1.push(currentDocument);
            taskDescDocumentsName1.push(
              currentDocument.FilePath.toString().split("/")[
                currentDocument.FilePath.toString().split("/").length - 1
              ]
            );
            this.setState({
              taskDescDocumentsName: taskDescDocumentsName1,
              taskDescDocuments: taskDescDocuments1,
            });
          }
        }
      }

      this.setState({
        visibleAciklamaGüncelle: true,
        updatedDescription: res.data.Description,
        updateViewClient: res.data.ViewClient,
        selectedTaskLogId: ID,
        tempViewClient: res.data.ViewClient,
        updatedTaskDocuments: res.data.Documents,
      });
    });
  };

  GetRecentlyViewed = (taskID) =>
    new Promise((resolve) => {
      var url = "/api/TaskDetail/GetRecentlyViewed?TaskID=" + taskID;
      callAPI(callApiGet, url, {}, async (res) => {
        this.setState({
          recentlyViewed: res.data,
        });
        //this.getData()
        resolve(true);
      });
    });

  changeTaskCanceled = () => {
    this.setState({ changeCategoryModalVisible: false });
    this.props.actions.isLoading(false);
  };

  changeCategoryByTask = () => {
    if (
      this.state.data.WorkStatusID === "a4a9cfb1-f85a-eb11-a2cb-005056b81088"
    ) {
      return notify(this.languages.lutfenTalebiOnceIslemeAlınız, "error", 3000);
    }
    let changeTaskCtegoryDto = {
      TaskID: this.state.taskID,
      CategoryID: this.state.selectedCategoryID,
    };
    let formData = new FormData();
    formData.append("jsonText", JSON.stringify(changeTaskCtegoryDto));
    this.props.actions.isLoading(true);

    callAPI(
      callApiPost,
      "/api/TaskDetail/ChangeTaskCategory",
      formData,
      (res) => {
        if (res.data) {
          if (res.data === "Seçmiş Olduğunuz Kategori Onaya Tabidir !!!") {
            BB.askYesNo(res.data, this.languages.onaylıyorMusunuz, this.languages.evet, this.languages.vazgec).then(
              (ask) => {
                if (ask === true) {
                  callAPI(
                    callApiPost,
                    "/api/TaskDetail/ChangeTaskStatusAndManagerID",
                    formData,
                    (res) => {
                      if (res.data) {
                        if (res.data === "Başarılı") {
                          this.setState({ changeCategoryModalVisible: false });

                          notify(
                            this.languages.kategoriBasarıIleDegistirildi,
                            "success",
                            2000
                          );

                          this.setState({ changeCategoryModalVisible: false });
                          this.getData();
                          this.props.actions.isLoading(false);
                        } else if (res.data === "Hata") {
                          this.setState({ changeCategoryModalVisible: false });

                          notify(
                            this.languages.talepSahibininYöneticisiBosOlamaz,
                            "error",
                            2000
                          );

                          this.setState({ changeCategoryModalVisible: false });
                          this.getData();
                          this.props.actions.isLoading(false);
                        } else {
                          this.getData();

                          notify(
                            this.languages.kategoriBasarıIleDegistirildi,
                            "success",
                            2000
                          );
                          this.props.actions.isLoading(false);
                        }
                      } else {
                        notify(this.languages.kategoriDegistirilemedi, "error", 2000);
                        this.props.actions.isLoading(false);
                      }
                    }
                  );
                }
                else{
                  this.props.actions.isLoading(false);

                }
              }
            );
          } else if (
            res.data === "Sonlandırılmış Bir Talebin Kategorisi Değiştirilemez."
          ) {
            notify(
              this.languages.sonlandırılmısBirTalebinKategorisiDegistirilemez,
              "error",
              2000
            );

            this.setState({ changeCategoryModalVisible: false });
            this.props.actions.isLoading(false);
          } else if (
            res.data ===
            "Seçmiş Olduğunuz Kategori Talebin Kategorisi İle Aynıdır ."
          ) {
            notify(
              this.languages.secmisOldugunuzKategoriTalebinKategorisiIleAynıdır,
              "error",
              2000
            );

            this.setState({ changeCategoryModalVisible: false });
            this.props.actions.isLoading(false);
          } else if (
            res.data ===
            "Seçmiş Olduğunuz Kategorinin Onay Seviyesi Mevcut Kategoriden Farklıdır Lütfen İptal Edip Tekrar Talep Açınız !!!"
          ) {
            notify(
              this.languages.secmisOldugunuzKategorininOnaySeviyesiMevcutKategoridenFarklıdırLutfenIptalEdipTekrarTalepAcınız,
              "error",
              4000
            );

            this.setState({ changeCategoryModalVisible: false });
            this.props.actions.isLoading(false);
          } else if (res.data === "Başarılı") {
            this.setState({ changeCategoryModalVisible: false });

            notify(this.languages.kategoriBasarıIleDegistirildi, "success", 2000);
            this.getData();
            this.props.actions.isLoading(false);
          } else {
            notify(this.languages.kategoriDegistirilemedi, "error", 2000);
            this.props.actions.isLoading(false);
          }
        } else {
          notify(this.languages.kategoriDegistirilemedi, "error", 2000);
          this.props.actions.isLoading(false);
        }
      }
    );
  };

  GetWatcherUsers = (taskID) =>
    new Promise((resolve) => {
      var url = "/api/TaskDetail/GetWatcherUsers?TaskID=" + taskID;
      callAPI(callApiGet, url, {}, async (res) => {
        this.setState({
          WatcherUsers: res.data,
        });
        resolve(true);
      });

      // BB.FetchJSON('/api/TaskDetail/GetWatcherUsers?TaskID=' + taskID).then(GetWatcherUsers => {
      //     this.setState({
      //         WatcherUsers: GetWatcherUsers
      //     })
      //     resolve(true);
      // });
    });

  deleteJob(ID) {
    this.props.actions.isLoading(true);

    callAPI(
      callApiGet,
      "/api/TaskTracking/DeleteJob?jobID=" + ID,
      {},
      (res) => {
        if (res?.data) {
          this.props.actions.isLoading(false);

          this.setState({
            selectedUserId: undefined,
            //taskID: undefined,
            selectedMessage: "",
          });
          var url =
            "api/TaskTracking/GetJobsByTaskID?TaskID=" + this.state.taskID;
          callAPI(callApiGet, url, {}, (GetMyJobs) => {
            if (GetMyJobs.data) {
              this.setState({
                myJobs: GetMyJobs.data,
              });
            }
          });
          notify(this.languages.Görevbaşarıylasilindi, "success", 2000);
        } else {
          notify(this.languages.gorevIptalEdilirkenHata, "error", 2000);
        }
      },
      true
    );
  }

  changeResponsibleUserByTask() {
    let responsibleUserChange = {
      TaskID: this.state.taskID,
      NewResponsibleUserID: this.state.selectedOverUserId,
    };
    let formData = new FormData();
    formData.append("jsontext", JSON.stringify(responsibleUserChange));

    callAPI(
      callApiPost,
      "api/TaskDetail/ChangeResponsibleUserByTask",
      formData,
      (res) => {
        if (
          res.data === "Sonlandırılmış Bir Talebin Sorumlusu Değiştirilemez."
        ) {
          this.setState({
            takeOverUserVisible: false,
            selectedOverUserId: undefined,
          });
          // this.setState({ visibleJob: false });
          this.getData();
          notify(
            this.languages.sonlandırılmısBirTalebinSorumlusuDegistirilemez,
            "error",
            2000
          );
        } else if (res.data) {
          this.setState({
            takeOverUserVisible: false,
            selectedOverUserId: undefined,
          });
          // this.setState({ visibleJob: false });
          this.getData();
          notify(this.languages.talebbasarıiledevredildi, "success", 2000);

          // var url =
          //   "api/TaskTracking/GetJobsByTaskID?TaskID=" + this.state.taskID;
          // callAPI(callApiGet, url, {}, (GetMyJobs) => {
          //   this.setState({
          //     myJobs: GetMyJobs.data,
          //   });
          // });
          // this.setState({
          //   selectedUserId: undefined,
          //   selectedMessage: "",
          //   visibleJob: false,
          // });
        } else {
          notify(this.languages.talepDevredilirkenHata, "error", 2000);
        }
      }
    );
  }

  addAssignAJob() {
    let jobDto = {
      TaskID: this.state.taskID,
      ResponsibleUserID: this.state.selectedUserId,
      Description: this.state.selectedMessage,
    };
    let formData = new FormData();
    formData.append("jsonText", JSON.stringify(jobDto));
    this.props.actions.isLoading(true);

    callAPI(callApiPost, "api/TaskTracking/AddAssignAJob", formData, (res) => {
      if (res.data) {
        this.setState({ visibleJob: false });

        notify(this.languages.Görevbaşarıylaatandı, "success", 2000);
        this.props.actions.isLoading(false);

        var url =
          "api/TaskTracking/GetJobsByTaskID?TaskID=" + this.state.taskID;
        callAPI(callApiGet, url, {}, (GetMyJobs) => {
          this.setState({
            myJobs: GetMyJobs.data,
          });
        });
        this.setState({
          selectedUserId: undefined,
          selectedMessage: "",
          visibleJob: false,
        });
      } else {
        notify(this.languages.görevatanamadi, "error", 2000);
      }
    });
  }

  taskActions(actionType) {
    let ths = this;
    let taskID = this.state.data.ID;
    let categoryID = this.state.data.CategoryID;

    if (actionType === "aciklamaekle") {
      this.GetCompanyUsers(this.state.taskID);
      this.setState({ visibleAciklamaEkle: true });
    }
    if (actionType === "devret") {
      this.setState({ visibleDevret: true });
    }
    if (actionType === "onayla") {
      Swal.fire({
        title: this.languages.onayAciklamasiGiriniz,
        input: "textarea",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        confirmButtonText: this.languages.onayVer,
        cancelButtonText: this.languages.iptal,
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          ths.props.actions.isLoading(true);

          let url =
            "/api/OwnConfirmTask/Confirm?id=" +
            taskID +
            "&Description=" +
            login;

          return BB.FetchTEXT(url).then((res) => {
            return res;
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed === true && result.value === "false") {
          timeoutPopup(
            this.languages.talepDahaOnceOnaylanmıştır,
            "warning",
            this.languages.tamam,
            4000
          );
        } else if (result.isConfirmed) {
          ths.getData();

          Swal.fire({
            position: "center",
            icon: "success",
            title: this.languages.onayBasariylaVerilmistir,
            showConfirmButton: false,
            timer: 1500,
          });
          ths.props.actions.isLoading(false);
        }
      });
    }
    if (actionType === "reddet") {
      Swal.fire({
        title:  this.languages.redAcıklamasıGiriniz,
        input: "textarea",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        confirmButtonText:  this.languages.redded,
        cancelButtonText: this.languages.vazgec,
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          let url =
            "/api/OwnConfirmTask/Reject?id=" + taskID + "&Description=" + login;

          return BB.FetchTEXT(url).then((res) => {
            ths.props.actions.isLoading(false);
            return res;
          });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed === true && result.value === "false") {
          timeoutPopup(
            this.languages.talepdahaöncereddedilmiştir,
            "warning",
            this.languages.tamam,
            4000
          );
        } else if (result.isConfirmed) {
          ths.getData();

          Swal.fire({
            position: "center",
            icon: "success",
            title: this.languages.talepBasariylaIptalEdilmistir,
            showConfirmButton: false,
            timer: 1500,
          });
          ths.props.actions.isLoading(true);
        }
      });
    }
    if (actionType === "ownerReddet") {
      callAPI(
        callApiGet,
        "api/DataCheck/OverCheck?TaskID=" + taskID,
        {},
        (res) => {
          if (res.data === true) {
            Swal.fire({
              title: this.languages.iptalAciklamasiGiriniz,
              input: "textarea",
              inputAttributes: {
                "aria-label": "Type your message here",
              },
              showCancelButton: true,
              confirmButtonText:  this.languages.tamam,
              cancelButtonText: this.languages.vazgec,
              showLoaderOnConfirm: true,
              preConfirm: (login) => {
                callAPI(
                  callApiGet,
                  "/api/TaskDetail/RejectTaskByOwner?TaskID=" +
                    taskID +
                    "&StatusID=AA2DF37B-8AC8-E811-80F6-005056B839BB&Description=" +
                    login,
                  {},
                  (data) => {
                    ths.props.actions.isLoading(false);
                    return data.data;
                  }
                );
              },
              allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.reload(true);

                ths.getData();

                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: this.languages.talepBasariylaIptalEdilmistir,
                  showConfirmButton: false,
                  timer: 1500,
                });
                ths.props.actions.isLoading(true);
              }
            });
          } else {
            timeoutPopup(
              this.languages.talepdevralınmış,
              "warning",
              this.languages.tamam,
              6000
            );
          }
        }
      );
    }
    if (actionType === "devral") {
      Swal.fire({
        title: this.languages.eminMisiniz,
        text: this.languages.talebiDevralacaksiniz,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: this.languages.iptal,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.languages.devral,
      }).then((result) => {
        if (result.isConfirmed === true) {
          var url1 = "api/DataCheck/OverCheck2?TaskID=" + taskID;
          callAPI(callApiGet, url1, {}, async (res) => {
            if (res.data === true) {
              var url2 = "/api/TaskDetail/Over?id=" + taskID;
              ths.props.actions.isLoading(true);

              callAPI(callApiGet, url2, {}, async (result) => {
                if (result.data.Status === 1) {
                 
                  ths.getData();
                  notify(result.data.Message, "success", 2000);
                } else {
                  
                  notify(result.data.Message, "error", 2000);
                }
                ths.getData();
                ths.props.actions.isLoading(false);
              });
            } else {
              timeoutPopup(
                this.languages.talebdahaöncesindeişlemealımıs,
                "warning",
                this.languages.tamam,
                6000
              );
            }
          });
        }
      });
    }
    if (actionType === "aciliyetdegistir") {
      this.setState({ visibleAciliyet: true });
    }

    if (actionType === "sonlandir") {
      var url = "/api/TaskDetail/GetCustomElementsForClosing?id=" + categoryID;
      callAPI(callApiGet, url, {}, async (customElements) => {
        if (customElements.data) {
          //let customControls = JSON.parse(customElements.data);
          let sortedCustomControls = customElements.data.sort(function (a, b) {
            return a.OrderNumber - b.OrderNumber;
          });
          this.setState({ customElements: sortedCustomControls });
        } else {
          this.setState({ customElements: null });
        }
      });

      const terminationStatusData = new CustomStore({
        loadMode: "raw",
        key: "ID",
        load: function () {
          return BB.FetchJSON("/api/TaskDetail/GetTerminationStatuses").then(
            (data) => {
              return data;
            }
          );
        },
      });

      this.setState({ terminationStatus: terminationStatusData });

      const terminateTemplateData = new CustomStore({
        loadMode: "raw",
        key: "ID",
        load: function () {
          return BB.FetchJSON("/api/TaskDetail/GetTerminateTemplates").then(
            (data) => {
              return data;
            }
          );
        },
      });

      this.setState({ terminateTemplate: terminateTemplateData });

      if (this.state.data.StatusID === "ac2df37b-8ac8-e811-80f6-005056b839bb") {
        this.setState({ visibleSonlandir: true });
      } else {
        notify(
          this.languages.TalepişlemdurumutalebikapatmayauygundeğilÖncelikleİşlemeAlınız,
          "error",
          5000
        );
      }
    }

    if (actionType === "durumDegistir") {
      this.GetProccessStatus();
      //İşlem Durumu : işleme alındı
      if (
        this.state.data.WorkStatusID !==
          "b22df37b-8ac8-e811-80f6-005056b839bb" &&
        this.state.data.WorkStatusID !== null
      ) {
        this.props.actions.isLoading(true);

        // callAPI(
        //     callApiGet,
        //     url,
        //     {},
        //     async (data) => {
        //         return data.data;

        // })

        let url =
          "api/TaskDetail/UpdateStatus?TaskID=" +
          taskID +
          "&StatusID=b22df37b-8ac8-e811-80f6-005056b839bb";
        callAPI(callApiGet, url, {}, async (data) => {
          if (data.data.Status) {
            notify(
              this.languages.talepDurumDegistirmeBasarili,
              "success",
              2000
            );
          } else {
            notify(data.data.Message, "error", 2000);
          }
          this.getData();
          this.GetProccessStatus();
        });

        // BB.FetchJSON(url).then(data => {
        //     notify(this.languages.talepDurumDegistirmeBasarili, "success", 2000);
        //     this.getData();
        //     this.GetProccessStatus();
        // });
      } else {
        this.setState({ visibleDurumDegistir: true });
      }
    }
    if (actionType === "formGoruntule") {
      this.setState({ previewVisible: true });
    }
    if (actionType === "sheetGoruntule") {
      this.setState({ preview2Visible: true });
    }
  }

  renderActionButtons() {
    const { t } = this.props;

    let Model = this.state.data;
    let usr = this.state.loginUser;
    let isConfirmed = true;
    let isCancel =
      Model.ResponsibleUserID === null
        ? Model.TaskLog.length === 2
          ? Model.CreateUserID === usr.ID
            ? true
            : false
          : false
        : false;

    if (!this.usr) {
      //Talep çözümlenmediyse
      if (!Model.TerminationDate) {
        if (Model.TaskConfirmation && Model.TaskConfirmation.length > 0) {
          for (let i = 0; i < Model.TaskConfirmation.length; i++) {
            if (Model.TaskConfirmation[i].ApprovalDate === null) {
              isConfirmed = false;
            }
          }
        }

        //Talep çözümlenmediyse ve Onay verecek birisi var ise
        if (
          Model.TaskConfirmation &&
          Model.TaskConfirmation.length > 0 &&
          !isConfirmed
        ) {
          let showConfirmButtons = false;
          let previousConfirmed = false;

          //Eğer login olan kullanıcı sıradaki onay verecek kişi ise
          if (
            (Model.TaskConfirmation[0].ApprovalDate === null &&
              Model.TaskConfirmation[0].UserID === this.state.loginUser.ID) ||
            Model.TaskConfirmation[0].ProcurationUserID ===
              this.state.loginUser.ID
          ) {
            showConfirmButtons = true;
          } else {
            for (let i = 0; i < Model.TaskConfirmation.length; i++) {
              if (
                Model.TaskConfirmation[i].UserID === this.state.loginUser.ID ||
                (Model.TaskConfirmation[i].ProcurationUserID ===
                  this.state.loginUser.ID &&
                  previousConfirmed === true)
              ) {
                if (Model.TaskConfirmation[i].ApprovalDate === null) {
                  showConfirmButtons = true;
                } else {
                  showConfirmButtons = false;
                }
              }
              if (Model.TaskConfirmation[i].ApprovalDate !== null) {
                previousConfirmed = true;
              } else {
                previousConfirmed = false;
              }
            }
          }

          // for (let i = 0; i < Model.TaskConfirmation.length; i++) {
          //   if (
          //     Model.TaskConfirmation[i].ApprovalDate === null &&
          //     Model.ConfirmingManagerID !== this.state.loginUser.ID
          //   ) {
          //     showConfirmButtons = false;
          //   }

          // }

          return (
            <Row className="align-items-center">
              {showConfirmButtons && (
                <Col>
                  <Button
                    block
                    outline
                    color="primary"
                    onClick={() => this.taskActions("onayla")}
                  >
                    {t("onayla")}
                  </Button>
                </Col>
              )}
              {showConfirmButtons && (
                <Col>
                  <Button
                    block
                    outline
                    color="primary"
                    onClick={() => this.taskActions("reddet")}
                  >
                    {t("reddet")}
                  </Button>
                </Col>
              )}
              {isCancel === true && (
                <Col>
                  <Button
                    block
                    color="danger"
                    onClick={() => this.taskActions("ownerReddet")}
                  >
                    {t("iptal")}
                  </Button>
                </Col>
              )}
            </Row>
          );
        } else {
          if (usr.IsLastUser === "False") {
            //Talep devranılmadıysa ve Yönetici reddetmediyse
            if (
              Model.ResponsibleUserID === null &&
              Model.StatusID !== "A62DF37B-8AC8-E811-80F6-005056B839BB"
            ) {
              if (isCancel === true) {
                return (
                  <Row>
                    <Col style={{ height: 37, marginTop: 5 }}>
                      <Button
                        block
                        color="danger"
                        onClick={() => this.taskActions("ownerReddet")}
                      >
                        {t("iptal")}
                      </Button>
                    </Col>
                    <Col style={{ height: 37, marginTop: 5 }}>
                      <Button
                        block
                        color="warning"
                        onClick={() => this.taskActions("devral")}
                      >
                        {t("devral")}
                      </Button>
                    </Col>
                  </Row>
                );
              } else {
                //Talep sahibi tarafından iptal edilmemişse
                if (Model.StatusID !== "aa2df37b-8ac8-e811-80f6-005056b839bb") {
                  return (
                    <Col style={{ height: 37, marginTop: 5 }}>
                      <Button
                        block
                        color="warning"
                        onClick={() => this.taskActions("devral")}
                      >
                        {t("devral")}
                      </Button>
                    </Col>
                  );
                }
              }
            }

            //Talep Yönetici onayında beklemiyorsa
            if (
              Model.ResponsibleUserID !== null &&
              Model.StatusID !== "a42df37b-8ac8-e811-80f6-005056b839bb"
            ) {
              if (Model.ResponsibleUserID === usr.ID) {
                return (
                  <Row style={{ marginTop: 8 }}>
                    <Col>
                      <Button
                        block
                        outline
                        color="primary"
                        onClick={() => this.taskActions("devret")}
                      >
                        {t("devret")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        outline
                        block
                        color="primary"
                        onClick={() => this.taskActions("aciliyetdegistir")}
                      >
                        {t("aciliyetdeğiştir")}
                      </Button>
                    </Col>
                  </Row>
                );
              }
            }
          } else {
            return (
              <Row>
                {isCancel === true && (
                  <Col>
                    <Button
                      block
                      color="danger"
                      onClick={() => this.taskActions("ownerReddet")}
                    >
                      {t("iptal")}
                    </Button>
                  </Col>
                )}
              </Row>
            );
          }
        }
      }
    } else {
      return (
        <Row>
          {isCancel === true && (
            <Col>
              <Button
                block
                color="danger"
                onClick={() => this.taskActions("ownerReddet")}
              >
                {t("iptal")}
              </Button>
            </Col>
          )}
        </Row>
      );
    }
  }

  handleChange(key, value, description) {
    let currentSelectedCustomControl = this.state.selectedCustomControl;
    let isCustomControl = this.state.customElements.filter((x) => x.ID == key);

    if (isCustomControl.length > 0) {
      let hasAlready = currentSelectedCustomControl.filter((x) => x.ID == key);
  
      if (hasAlready.length > 0) {
        let currentIndex = currentSelectedCustomControl.indexOf(hasAlready);
        currentSelectedCustomControl.splice(currentIndex, 1);

        if (isCustomControl[0].CustomControlType === 2) {
          let item = { ID: key, Value: value };
          currentSelectedCustomControl.push(item);
        } else {
          let item = { ID: key, Value: value };
          currentSelectedCustomControl.push(item);
        }
      } else {
        if (isCustomControl[0].CustomControlType === 2) {
          let item = { ID: key, Value: value };
          currentSelectedCustomControl.push(item);
        } else {
          let item = { ID: key, Value: value };
          currentSelectedCustomControl.push(item);
        }
      }
      this.setState({ selectedCustomControl: currentSelectedCustomControl });
    }
    if(key === "additionalDescription"){
      this.setState({ additionalDescriptionValue: value });
    }
    if (key === "selectedCategoryID") {
      this.setState({ selectedCategoryID: value });
    }
    if (key === "selectedDegreeOfUrency") {
      this.setState({ selectedDegreeOfUrency: value });
    }
    if (key === "selectedTaskFiles") {
      this.setState({ selectedTaskFiles: value });
    }
    if (key === "selectedProjectNumber") {
      
      var s = this.state.data
      
      this.setState({ selectedProjectNumber: value });
    }
    if (key === "effor") {
     
        this.setState({ effor: value});
    }
    if (key === "descriptionUpdate") {
      this.setState({ visibleAciklamaGüncelle: value });
    }
    if (key === "selectedUserId") {
      this.setState({ selectedUserId: value });
    }
    if (key === "selectedMessage") {
      this.setState({ selectedMessage: value });
    }
    if (key === "selectedOverUserId") {
      this.setState({ selectedOverUserId: value });
    }
    if (key === "selectedDegreeOfUrencyDesc") {
      this.setState({ selectedDegreeOfUrencyDesc: value });
    }
    if (key === "selectedTerminationDescription") {
      this.setState({ selectedTerminationDescription: value });
    }
    if (key === "selectedTerminationType") {
      this.setState({ selectedTerminationType: value });
    }
    if (key === "selectedTerminationTemplate") {
      this.setState({
        selectedTerminationTemplate: value,
        selectedTerminationDescription: description,
      });
    }
    if (key === "selectedTerminationFile") {
      this.setState({ selectedTerminationFile: value });
    }
    if (key === "selectedPoolDevret") {
      this.setState({ selectedPoolDevret: value, selectedPoolUserID: null });
    }
    if (key === "selectedUserDevret") {
      this.setState({ selectedUserDevret: value });
    }
    if (key === "selectedDescriptionDevret") {
      this.setState({ selectedDescriptionDevret: value });
    }
    if (key === "selectedAdditionalConfirmationDescription") {
      this.setState({ selectedAdditionalConfirmationDescription: value });
    }
    if (key === "selectedCompanyUserID") {
      this.setState({ selectedCompanyUserID: value });
    }
    if (key === "selectedCommentDescription") {
      this.setState({ selectedCommentDescription: value });
    }
    if (key === "selectedCommentFiles") {
      this.setState({ selectedCommentFiles: value });
    }
    if (key === "customElementItemsDatas") {
      this.setState({ customElementItemsDatas: value });
    }
    if (key === "selectedStatus") {
      this.setState({ selectedStatus: value });
    }
    if (key === "selectedPoolUserID") {
      this.setState({ selectedPoolUserID: value });
    }
    if (key === "selectedTaskFilesState") {
      this.setState({ fileUploaderVisible: value });
    }
    if (key === "selectedEndDate") {
      if (value < new Date()) {
        notify(this.languages.Dahaöncekibirzamanseçemezsiniz, "warning", 2000);
      } else {
        this.setState({ selectedEndDate: value });
      }
    }
    if (key === "selectedLastStatus") {
      this.setState({ selectedLastStatus: value });
    }
    if (key === "selectedShareOtherMails") {
      this.setState({ selectedShareOtherMails: value });
    }
    if (key === "selectedWatcherUsers") {
      this.setState({ selectedWatcherUsers: value });
    }
    if (key === "selectedCategory") {
      this.setState({ selectedCategory: value });
    }
    if (key === "selectedWatcherUserID") {
      this.setState({ selectedWatcherUserID: value });
    } else {
      this.setState({ [key]: value });
    }
    if (key === "selectedShareOwner") {
      this.setState({ selectedShareOwner: !this.state.selectedShareOwner });
    }
    if (key === "NotifyDepartment") {
      this.setState({ NotifyDepartment: !this.state.NotifyDepartment });
    }
    if (key === "selectedShareOther") {
      this.setState({ selectedShareOther: !this.state.selectedShareOther });
    }
    if (key === "selectedCompanyID") {
      callAPI(
        callApiGet,
        `/api/Task/GetCompanyUsersForAdditionalConfirmation?id=` + value,
        {},
        (res) => {
          if (res?.data) {
            this.setState({ companyUser: res?.data });

            this.props.actions.isLoading(false);
          }
        },
        true
      );
    }
  }

  checkIsLastUser = () => {
    let url = "api/TaskDetail/IsLastUser";
    callAPI(callApiGet, url, {}, (res) => {
      if (res.data) {
        this.setState({ isLastUser: "none" });
      } else {
        this.setState({ isLastUser: "block" });
      }
    });
  };

  getCompanies = () => {
    callAPI(
      callApiGet,
      `/api/Task/GetCompanies`,
      {},
      async (res) => {
        if (res?.data) {
          this.setState({ companies: res?.data });
          this.props.actions.isLoading(false);
        }
      },
      true
    );
  };
  getCompanyUser = () => {
    callAPI(
      callApiGet,
      `/api/Task/GetCompanyUsersForAdditionalConfirmation?id=` +
        this.state.selectedCompanyID,
      {},
      (res) => {
        if (res?.data) {
          this.setState({ companyUser: res?.data });

          this.props.actions.isLoading(false);
        }
      },
      true
    );
  };
  add = () => {
    store.addNotification({
      title: "Talep Takibi başarılı!",
      message:
        "18 Numaralı talep hakkında bütün işlemler size bildirim & mail olarak gönderilecektir",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 10000,
        onScreen: true,
      },
    });
  };
  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    this.setState((state) => {
      const rows = state.rows.slice();
      for (let i = fromRow; i <= toRow; i++) {
        rows[i] = { ...rows[i], ...updated };
      }
      return { rows };
    });
  };
  getCustomSheet() {
    BB.FetchJSON(
      "/api/TaskDetail/GetCustomSheet?TaskID=" + this.state.taskID
    ).then((GetCustomSheet) => {
      this.setState({
        selectedSheetID: GetCustomSheet ? GetCustomSheet[0].FormID : undefined,
        customSheetData: GetCustomSheet ? GetCustomSheet : undefined,
        selectedSheetName: GetCustomSheet ? GetCustomSheet[0].Name : undefined,
        rows: GetCustomSheet ? JSON.parse(GetCustomSheet[0].Values) : [],
        selectedSheet: GetCustomSheet
          ? JSON.parse(GetCustomSheet[0].Form)
          : undefined,
      });
    });
  }
  changeEffort =() => {

 if(this.state.data.ResponsibleUser === null){
  this.getData();

  return notify(
    this.languages.eforKaydınıSadeceTalepSorumlusuGüncelleyebilir,
    "warning",
    2000
  );
 }
if(this.state.data.ResponsibleUser.ID !== this.state.loginUser.ID){

  this.getData();


return notify(
 this.languages.eforKaydınıSadeceTalepSorumlusuGüncelleyebilir,
  "warning",
  2000
);

}
else{
  if(this.state.effor.length===0){
  
  this.setState({ effor: 0});
  var url = "/api/TaskDetail/ChangeEffor?TaskID=" +this.state.taskID +"&effor="+"0"

  callAPI(
    callApiGet,
    url,
    {},
    (res) => {
      if (res?.data) {
        if(res?.data === true){
          notify(
            this.languages.basarili,
            "success",
            2000
          );
        }
        else{
          notify(
            this.languages.birHataAlındı,
            "warning",
            2000
          );
        }
      }
    },
    true
  );
 
    
}
else if (this.state.effor.length > 0)
{
  this.setState({ effor: this.state.effor});


  var url = "/api/TaskDetail/ChangeEffor?TaskID=" +this.state.taskID +"&effor="+this.state.effor

  callAPI(
    callApiGet,
    url,
    {},
    (res) => {
      if (res?.data) {
         if(res?.data === true){
          notify(
            this.languages.basarili,
            "success",
            2000
          );
        }
        else{
          notify(
            this.languages.birHataAlındı,
            "warning",
            2000
          );
        }
      }
    },
    true
  );
}
else{

}
}




  


  }
  changeProjectNumber = () => {
    //
    let isResponsibleUser =
      this.state.loginUser.ID === this.state.data.ResponsibleUserID;
    let { taskID, selectedProjectNumber } = this.state;

      if(selectedProjectNumber ==="DT" || selectedProjectNumber==="dt")
         {
           this.setState({visibleAdditionalDescription:true})
         }
       else{
        let URL =
        "api/TaskDetail/ChangeTaskProjectNumber?TaskID=" +
        taskID +
        "&ProjectNumber=" +
        selectedProjectNumber;
  
      if (isResponsibleUser === true) {
        BB.FetchJSON(URL).then((res) => {
          debugger;
          if (res === "DT için uygun kategoriler seçili değildir.") {
            notify(this.languages.DTiçinUygunKategorilerSeciliDegildir, "warning", 2000);
          } else if (
            res ===
            "Bu Talep İçin Önceden Oluşturulmuş Bir Azure Kaydı Mevcuttur. "
          ) {
            notify(
              this.languages.buTalepicinÖncedenOluşturulmuşBirAzureKaydıMevcuttur,
              "warning",
              2000
            );
          } else if (res === true) {
            notify(this.languages.basarili, "success", 2000);
          } else {
            notify(this.languages.birHataAlındı, "warning", 2000);
          }
        });
      } else {
        notify(
          this.languages.projeNumarasıAlanınıSadeceTalepSorumlusuDegistirebilir,
          "warning",
          3000
        );
      }
       }  



    
  };

  onToolbarPreparing = (e) => {
    let ths = this;
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      widget: "dxButton",
      options: {
        text: this.languages.izleyiciEkle,
        value: this.languages.izleyici,
        color: "success",
        showClearButton: true,
        onClick: function (e) {
          ths.setState({ visibleWatcher: true });
        },
        width: 175,
      },
      location: "after",
    });
  };
  onToolbarPreparing2 = (e) => {
    callAPI(
      callApiGet,
      "/api/TaskTracking/GetMyPoolUsers",
      {},
      (poolUserss) => {
        this.setState({ poolUsers2: poolUserss.data });
      },
      true
    );

    let ths = this;
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      widget: "dxButton",
      options: {
        text: this.languages.GörevEkle,
        value: this.languages.Görev,
        color: "success",
        showClearButton: true,
        onClick: function (e) {
          ths.setState({ visibleJob: true });
        },
        width: 175,
      },
      location: "after",
    });
  };
  onToolbarPreparing3 = (e) => {
    let ths = this;
    let toolbarItems = e.toolbarOptions.items;

    toolbarItems.push({
      widget: "dxButton",
      options: {
        text:this.languages.EkOnayaGönder,
        value: this.languages.EkOnay,
        color: "success",
        showClearButton: true,
        onClick: function (e) {
          ths.checkAdditionalUserPermission();
        },
        width: 175,
      },
      location: "after",
    });
  };

  deleteWatcher(Data) {
    BB.askYesNo(
      this.languages.talebintakibibırakılacaktır,
      this.languages.eminMisiniz,
      this.languages.evet,
      this.languages.vazgec,
    ).then((ask) => {
      if (ask === true) {
        BB.FetchJSON(
          "api/TaskDetail/DeleteWatcherUser?ID=" + Data.WatcherID
        ).then((res) => {
          BB.FetchJSON(
            "/api/TaskDetail/GetWatcherUsers?TaskID=" + this.state.taskID
          ).then((GetWatcherUsers) => {
            this.setState({ WatcherUsers: GetWatcherUsers });
          });
        });
      }
    });
  }

  render() {
    const { customElements } = this.state;
      const { t } = this.props;
      const tooltipId = 'taskOwnerTooltip';
    let { activeTab } = this.state;

    let taskDocuments = [];
    let taskDocumentsName = [];
    const { isMobile } = this.state;
    let taskDescDocuments = [];
    let taskDescDocumentsName = [];

    let taskClosingDocuments = [];
    let taskClosingDocumentsName = [];
    let cUSer = this.state.data.CreateUser
      ? this.state.data.CreateUser.Name + this.state.data.CreateUser.SurName
      : null;
    let oUSer = this.state.data.OwnerUser
      ? this.state.data.OwnerUser.Name + this.state.data.OwnerUser.SurName
      : null;
    const DATE_OPTIONS = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    let otherUserCreated = cUSer
      ? oUSer
        ? cUSer === oUSer
          ? true
          : false
        : false
      : false;
  

    if (
      this.props.location.state !== undefined ||
      this.props.location.search !== undefined
    ) {
      if (this.state.data.length !== 0) {
        if (this.state.data.TaskDocument.length > 0) {
          for (let i = 0; i < this.state.data.TaskDocument.length; i++) {
            let currentDocument = this.state.data.TaskDocument[i];

            if (currentDocument.Document.DocumentType === "1") {
              taskDocuments.push(currentDocument);
              taskDocumentsName.push(
                currentDocument.Document.FilePath.toString().split("/")[
                  currentDocument.Document.FilePath.toString().split("/")
                    .length - 1
                ]
              );
            }
            if (currentDocument.Document.DocumentType === "2") {
              taskDescDocuments.push(currentDocument);
              taskDescDocumentsName.push(
                currentDocument.Document.FilePath.toString().split("/")[
                  currentDocument.Document.FilePath.toString().split("/")
                    .length - 1
                ]
              );
            }
            if (currentDocument.Document.DocumentType === "3") {
              taskClosingDocuments.push(currentDocument);
              taskClosingDocumentsName.push(
                currentDocument.Document.FilePath.toString().split("/")[
                  currentDocument.Document.FilePath.toString().split("/")
                    .length - 1
                ]
              );
            }
          }
        }

        return (
          <Row>
            <Col lg={12}>
              <Row>
                <Col xs="12" sm="3">
                  <Card
                    className="text-center"
                    style={{
                      height: this.renderActionButtons() ? "auto" : 84,
                      marginBottom: this.renderActionButtons() ? "auto" : 0,
                    }}
                  >
                    <CardBody>{this.renderActionButtons()}</CardBody>
                  </Card>
                </Col>

                <Col xs="12" sm="6">
                  <Card style={{ backgroundColor: "beige" }}>
                    <CardBody>
                      <Row>
                        <Col>
                          <Row style={{ marginLeft: 0 }}>
                            <strong>{t("talepDurumu")}</strong>
                          </Row>
                          <Row style={{ marginLeft: 0 }}>
                            {this.state.data.StatusName != null?this.state.data.StatusName.toString():this.state.data.StatusName}
                          </Row>
                        </Col>
                        <Col>
                          {this.state.loginUser.ID ===
                          this.state.data.ResponsibleUserID ? (
                            !this.state.data.TerminationDate ? (
                              <Button
                                style={{
                                  width: 100,
                                  float: "right",
                                  marginTop: 5,
                                }}
                                outline
                                color="danger"
                                onClick={() => this.taskActions("sonlandir")}
                              >
                                {t("sonlandır")}
                              </Button>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col xs="12" sm="3">
                  <Card style={{ backgroundColor: "azure" }}>
                    <CardBody>
                      <Row>
                        <Col sm={8}>
                          <Row style={{ marginLeft: 0 }}>
                            <strong>{t("islemdurum")}</strong>
                          </Row>
                          <Row style={{ marginLeft: 0 }}>
                            {this.state.data.WorkStatus.Name
                              ? this.state.data.WorkStatus.Name
                              : t("İşlemDurumuSeçilideğil")}
                            {/* {this.state.status.filter(statu => statu.ID === this.state.data.WorkStatusID).length > 0 ? this.state.status.filter(statu => statu.ID === this.state.data.WorkStatusID)[0].Name : 'İşlem durumu seçili değil'} */}
                          </Row>
                        </Col>
                        <Col sm={4}>
                          {this.state.loginUser.ID ===
                          this.state.data.ResponsibleUserID ? (
                            !this.state.data.TerminationDate ? (
                              <Button
                                block
                                outline
                                color={
                                  this.state.data.WorkStatusID !==
                                  "b22df37b-8ac8-e811-80f6-005056b839bb"
                                    ? this.state.data.WorkStatusID !== null
                                      ? "success"
                                      : "primary"
                                    : "primary"
                                }
                                onClick={() =>
                                  this.taskActions("durumDegistir")
                                }
                              >
                                {this.state.data.WorkStatusID !==
                                "b22df37b-8ac8-e811-80f6-005056b839bb"
                                  ? this.state.data.WorkStatusID !== null
                                    ? t("islemeAl")
                                    : t("durumdeğiştir")
                                  : t("durumdeğiştir")}
                              </Button>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Row>
                <Col xs="12" sm="3">
                  <CustomCard cardStyle="card-dashboard-calendar pb-0">
                    <LabelGroup
                      title={t("ozet")}
                      description={t("talebinOzetBilgisi")}
                    />
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("talepno")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="6">
                        {this.state.data.TaskNumber}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("açılış")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="6">
                        {new Date(
                          this.state.data.CreateDate
                        ).toLocaleDateString() +
                          " " +
                          new Date(
                            this.state.data.CreateDate
                          ).toLocaleTimeString()}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("sahibi")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                                    <Col xs="6" sm="6">
                                        <span
                                            data-tip=
                                            {this.state.data.CreateUser.Name +
                                                " " +
                                                this.state.data.CreateUser.SurName + " ("
                                                + (this.state.data.RequesterEmail == null ? this.state.data.CreateUser.Email : this.state.data.RequesterEmail) + ")"
                                            }
                                            data-for={tooltipId}>
                                            {this.state.data.CreateUser.Name +
                                                " " +
                                                this.state.data.CreateUser.SurName + " ("
                                                + (this.state.data.RequesterEmail == null ? this.state.data.CreateUser.Email : this.state.data.RequesterEmail) + ")"
                                            }
                                        </span>
                                        <ReactTooltip id={tooltipId} place="top" effect="solid">
                                            <div>{this.state.data.CreateUser.UserName}</div>
                                        </ReactTooltip>
                                    </Col>
                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("kurum")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="6">
                        {this.state.data.CreateUser.CompanyName}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("gsm")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      {this.state.data.CreateUser.MobilPhone === null ? (
                        <Col xs="6" sm="6">
                          {this.state.data.CreateUser.Phone}
                        </Col>
                      ) : this.state.data.CreateUser.Phone === null ? (
                        <Col xs="6" sm="6">
                          {" "}
                          {this.state.data.CreateUser.MobilPhone}{" "}
                        </Col>
                      ) : (
                        <Col xs="6" sm="6">
                          {" "}
                          {this.state.data.CreateUser.MobilPhone +
                            " / " +
                            this.state.data.CreateUser.Phone}{" "}
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>
                          {t("talepkategorisi")}{" "}
                          <i
                            className="fa fa-refresh"
                            onClick={() => {
                              //this.getParentCategories(this.state.taskID,null)
                              this.getCategoriesForChangeCategories();
                              this.setState({
                                changeCategoryModalVisible: true,
                              });
                            }}
                            aria-hidden="true"
                            style={{
                              marginLeft: 8,
                              display: this.state.iconCategoryVisible,
                            }}
                          ></i>
                        </strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="6">
                        {this.state.data.CategoryTree}
                      </Col>
                    </Row>
                    {this.state.data.ResponsibleUser ? (
                      <Row>
                        <Col xs="4" sm="4">
                          <strong>{t("talepsorumlusu")}</strong>
                        </Col>
                        <Col xs="1" sm="1">
                          :
                        </Col>
                        <Col xs="6" sm="6">
                          {this.state.data.ResponsibleUser.Name}{" "}
                          {this.state.data.ResponsibleUser.SurName}
                          <i
                            className="fa fa-refresh"
                            onClick={() => {
                              this.setState({ takeOverUserVisible: true });
                            }}
                            aria-hidden="true"
                            style={{
                              marginLeft: 8,
                              display: this.state.iconVisible,
                            }}
                          ></i>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("sla")}</strong>
                      </Col>

                      <Col xs="1" sm="1">
                        :
                      </Col>

                      <Col xs="6" sm="6">
                        {this.state.data.Category.SLA}
                      </Col>

                    </Row>
                    <Row>
                      <Col xs="4" sm="4">
                        <strong>{t("Efor")}</strong>
                      </Col>

                      <Col xs="1" sm="1">
                        :
                      </Col>

                      <Col xs="4" sm="4">
                  
                       {BB.InputWithoutLabelEffort(
                          "effor",
                          "text",
                          this,
                          false
                        )} 
                      
                      </Col>

                      <Col xs="1" sm="1">
                      <i
                            className="fa fa-check"
                            onClick={() => {
                              this.changeEffort()
                            }}
                            aria-hidden="true"
                            style={{
                              display: this.state.iconVisible,
                            }}
                          ></i>
                      </Col>

                    </Row>
                    {this.state.data.ExpectedEndDate ? (
                      <Row>
                        <Col xs="4" sm="4">
                          <strong>{t("tahminibitiştarihi")}</strong>
                        </Col>
                        <Col xs="1" sm="1">
                          :
                        </Col>
                        <Col xs="6" sm="6">
                          {new Date(
                            this.state.data.ExpectedEndDate
                          ).toLocaleDateString()}
                        </Col>
                      </Row>
                    ) : null}

                    {this.state.data.TaskConfirmation
                      ? this.state.data.TaskConfirmation.length > 0
                        ? this.state.data.TaskConfirmation.ProcurationUserID !==
                          null
                          ? this.state.data.TaskConfirmation.map((item, i) => {
                              return (
                                <div key={i}>
                                  <Row style={{ marginLeft: -15 }}>
                                    <Col xs="4" sm="4">
                                      <strong>{i + 1}.{t("Onay")}</strong>
                                    </Col>
                                    <Col xs="1" sm="1">
                                      :
                                    </Col>
                                    <Col xs="6" sm="6">
                                      {item.User.Name} {item.User.SurName}{" "}
                                      {item.ProcurationUserID !== null
                                        ? "VEK. " +
                                          item.ProcurationUser.Name +
                                          " " +
                                          item.ProcurationUser.SurName
                                        : ""}
                                    </Col>
                                  </Row>

                                  {item.ApprovalDate !== null && (
                                    <Row style={{ marginLeft: -15 }}>
                                      <Col xs="4" sm="4">
                                        <strong>{i + 1}.{t("OnayTarihi")}</strong>
                                      </Col>
                                      <Col xs="1" sm="1">
                                        :
                                      </Col>
                                      <Col xs="6" sm="6">
                                        {new Date(
                                          item.ApprovalDate
                                        ).toLocaleDateString() +
                                          " " +
                                          new Date(
                                            item.ApprovalDate
                                          ).toLocaleTimeString()}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              );
                            })
                          : this.state.data.TaskConfirmation.map((item, i) => {
                              return (
                                <div key={i}>
                                  <Row style={{ marginLeft: -15 }}>
                                    <Col xs="4" sm="4">
                                      <strong>{i + 1}.{t("Onay")}</strong>
                                    </Col>
                                    <Col xs="1" sm="1">
                                      :
                                    </Col>
                                    <Col xs="6" sm="6">
                                      {item.User.Name} {item.User.SurName}
                                    </Col>
                                  </Row>
                                  {item.ApprovalDate !== null && (
                                    <Row style={{ marginLeft: -15 }}>
                                      <Col xs="4" sm="4">
                                        <strong>{i + 1}.{t("OnayTarihi")}</strong>
                                      </Col>
                                      <Col xs="1" sm="1">
                                        :
                                      </Col>
                                      <Col xs="6" sm="6">
                                        {new Date(
                                          item.ApprovalDate
                                        ).toLocaleDateString() +
                                          " " +
                                          new Date(
                                            item.ApprovalDate
                                          ).toLocaleTimeString()}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              );
                            })
                        : null
                      : null}

                    {/* {this.state.additionalUsersForTask.map((item,i)=>{
                            return (<div key={i}>
                              <Row style={{ marginLeft: -15 }}>
                                <Col xs="4" sm="4">
                                  <strong>{i + 1}.Ek Onay</strong>
                                </Col>
                                <Col xs="1" sm="1">
                                  :
                                </Col>
                                <Col xs="6" sm="6">
                                  {item.Name} {item.SurName}
                                </Col>
                              </Row>
                              {item.ApprovalDate !== null && (
                                <Row style={{ marginLeft: -15 }}>
                                  <Col xs="4" sm="4">
                                    <strong>{i + 1}.Ek Onay Tarihi</strong>
                                  </Col>
                                  <Col xs="1" sm="1">
                                    :
                                  </Col>
                                  <Col xs="6" sm="6">
                                    {new Date(
                                      item.ApprovalDate
                                    ).toLocaleDateString() +
                                      " " +
                                      new Date(
                                        item.ApprovalDate
                                      ).toLocaleTimeString()}
                                  </Col>
                                </Row>
                              )}
                            </div>)

                          })} */}

                    {!otherUserCreated && this.state.data.OwnerUser && (
                      <Row style={{ marginTop: 10, color: "chocolate" }}>
                        <Col>
                          <i className={"icon-info"}></i>
                          {" Bu Talep "}{" "}
                          {this.state.data.CreateUser.Name +
                            " " +
                            this.state.data.CreateUser.SurName}{" "}
                          {" Adına "}
                          {this.state.data.OwnerUser.Name +
                            " " +
                            this.state.data.OwnerUser.SurName}
                          {" Tarafından açılmıştır"}
                        </Col>
                      </Row>
                    )}
                    {this.state.data.MainTaskID && (
                      <Row style={{ marginTop: 10, color: "chocolate" }}>
                        <Col>
                          <i className={"icon-info"}></i>
                          {" Bu Talep "}
                          <a
                            href={"#!/TaskDetail?" + this.state.data.MainTaskID}
                            target="_blank"
                          >
                            {this.state.data.MainTask.TaskNumber}
                          </a>
                          {" numaralı talebin iş tekrarı olarak açılmıştır"}
                        </Col>
                      </Row>
                    )}
                  </CustomCard>
                </Col>

                <Col xs="12" sm="6">
                  <CustomCard cardStyle="card-dashboard-calendar pb-0">
                    {this.state.customFormData.length > 0 && (
                      <div className="card-header-actions">
                        <Button
                          style={{
                            width: 170,
                            backgroundColor: "darkseagreen",
                          }}
                        >
                          <Row
                            style={{ justifyContent: "center" }}
                            onClick={() => this.taskActions("formGoruntule")}
                          >
                            {t("formlar")}
                          </Row>
                        </Button>
                      </div>
                    )}

                    {this.state.customSheetData.length > 0 && (
                      <div className="card-header-actions">
                        <Button
                          style={{
                            width: 140,
                            backgroundColor: "darkseagreen",
                            marginRight: 10,
                          }}
                        >
                          <Row
                            style={{ justifyContent: "center" }}
                            onClick={() => this.taskActions("sheetGoruntule")}
                          >
                            {t("tablolar")}
                          </Row>
                        </Button>
                      </div>
                    )}

                    <LabelGroup
                      title={t("ekbilgi")}
                      description={
                        t("Talebeaittemelbilgilerburadayeralmaktadır")
                      }
                    />
 
                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("talepAciklamasi")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {ReactHtmlParser(
                          this.state.data.HTMLDescription
                            ? this.state.data.HTMLDescription
                            : this.state.data.Description
                        )}
                      </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("aciliyet")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {this.state.data.DegreeOfUrgency === 0
                          ? t("düşük")
                          : this.state.data.DegreeOfUrgency === 1
                          ? t("orta")
                          : t("yüksek")}
                      </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("aciliyetaçıklaması")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {this.state.data.UrgencyDescription}
                      </Col>
                    </Row>

                    {this.state.data.Effect !== null && (
                      <>
                        <DropdownItem divider />
                        <Row>
                          <Col xs="4" sm="3">
                            <strong>{t("etki")}</strong>
                          </Col>
                          <Col xs="1" sm="1">
                            :
                          </Col>
                          <Col xs="6" sm="8">
                            {this.state.data.Effect.Name}
                          </Col>
                        </Row>
                      </>
                    )}

                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("talepbelgeleri")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {taskDocuments.map((item, i) => (
                          <a
                            key={i}
                            style={{ marginRight: 10 }}
                            href={item.Document.FilePath}
                            download = {taskDocumentsName[i].split("_")[taskDocumentsName[i].split("_").length - 1]}
                          >
                            {taskDocumentsName[i].split("_")[taskDocumentsName[i].split("_").length - 1]}
                          </a>
                        ))}
                      </Col>
                    </Row>
                    {this.state.data.CustomValue.length > 0 &&
                      this.state.data.CustomValue.map((item, i) => {
                        let itemType =
                          item.CategoryCustomControl.CustomControlType;
                        return (
                          <div key={i}>
                            <DropdownItem divider />
                            <Row>
                              <Col xs="4" sm="3">
                                <strong style={{ color: "cadetblue" }}>
                                  {item.CategoryCustomControl.Value + " : "}
                                </strong>
                              </Col>
                              <Col xs="1" sm="1">
                                :
                              </Col>
                              <Col xs="6" sm="8">
                                {itemType === 1
                                  ? new Date(item.Value).toLocaleDateString(
                                      "tr-TR",
                                      DATE_OPTIONS
                                    )
                                  : item.Value}
                              </Col>
                            </Row>
                          </div>
                        );
                        // }
                      })}

                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("kapanışbelgeleri")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {taskClosingDocuments.map((item, i) => (
                          <a
                            key={i}
                            style={{ marginRight: 10 }}
                            href={item.Document.FilePath}
                            download = {item.Document.FilePath.split("_")[item.Document.FilePath.split("_").length - 1]}
                          >
                            {item.Document.FilePath.split("_")[item.Document.FilePath.split("_").length - 1]}
                          </a>
                        ))}
                      </Col>
                    </Row>
                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("kapanışmesajı")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="6" sm="8">
                        {this.state.data.TerminationDescription}
                      </Col>
                    </Row>

                    <DropdownItem divider />
                    <Row>
                      <Col xs="4" sm="3">
                        <strong>{t("projeNumarası")}</strong>
                      </Col>
                      <Col xs="1" sm="1">
                        :
                      </Col>
                      <Col xs="3" sm="3">
                        {BB.InputWithoutLabelProjectNumber(
                          "selectedProjectNumber",
                          "text",
                          this,
                          false
                        )}
                      </Col>
                      <Col xs="5" sm="5">
                        <Button
                          style={{ float: "right", marginTop: 6 }}
                          color={"info"}
                          outline
                          onClick={this.changeProjectNumber}
                        >
                          {t("kaydet")}
                        </Button>
                        {/* {BB.TextAreaWithLabel('selectedDegreeOfUrencyDesc', t('açıklama'), '', 5, this)} */}
                      </Col>
                    </Row>
                  </CustomCard>
                </Col>

                {/* <Col xs="12" sm="3">
                  <CustomCard
                    cardStyle="card-dashboard-calendar pb-0"
                    isTaskDetail={true}
                  >
                    <LabelGroup
                      title={t("Açık Talepler")}
                      description={"Havuzdaki Diğer Talepler"}
                    />

                    <div style={{ height: 313, overflowY: "scroll" }}>
                      {this.props.poolTasks.map((item, i) => {
                        return (
                          <Col
                            style={{
                              backgroundColor: "ghostwhite",
                              cursor: "pointer",
                              zIndex: 0,
                            }}
                            key={i}
                            onClick={() => this.getData(item.ID)}
                          >
                            <Row className="d-flex justify-content-between">
                              <Col style={{ marginTop: 10 }}>
                                <strong>{"#" + item.TaskNumber}</strong>{" "}
                                {" - " + item.CategoryName}
                              </Col>
                            </Row>
                            <Row>
                              <Col style={{ textAlign: "start" }}>
                                {"Aciliyet : " + item.DegreeOfUrgencyStr}
                              </Col>
                            </Row>
                            <Row>
                              <Col style={{ textAlign: "start" }}>
                                {"Oluşturan : " + item.CreateUserName}
                              </Col>
                            </Row>
                            <hr />
                          </Col>
                        );
                      })}
                    </div>
                  </CustomCard>
                </Col> */}
              </Row>

              <Row>
                <Col xs="12" sm="12" xl={12}>
                  <CustomCard>
                    <div className="table-task-div" style={{ marginTop: 0 }}>
                      <Row>
                        <Col xl={8}>
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classNames({
                                  active: activeTab === "1",
                                })}
                                onClick={() =>
                                  this.setState({ activeTab: "1" })
                                }
                              >
                                {t("aciklamalar")}
                                {this.state.taskLogDescription.length > 0 && (
                                  <Badge
                                    pill
                                    color="success"
                                    style={{
                                      top: -8,
                                      position: "absolute",
                                    }}
                                  >
                                    {this.state.taskLogDescription.length}
                                  </Badge>
                                )}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classNames({
                                  active: activeTab === "2",
                                })}
                                onClick={() =>
                                  this.setState({ activeTab: "2" })
                                }
                              >
                                {t("talephareketleri")}
                              </NavLink>
                            </NavItem>
                            {/* <div style={display="none"}> */}
                            <NavItem
                              style={{
                                display: this.state.isLastUser,
                              }}
                            >
                              <NavLink
                                className={classNames({
                                  active: activeTab === "3",
                                })}
                                onClick={() =>
                                  this.setState({ activeTab: "3" })
                                }
                              >
                              {t("gorevler")}

                                
                                {this.state.myJobs.length > 0 && (
                                  <Badge
                                    pill
                                    color="warning"
                                    style={{
                                      top: -8,
                                      position: "absolute",
                                    }}
                                  >
                                    {this.state.myJobs.length}
                                  </Badge>
                                )}
                              </NavLink>
                            </NavItem>
                            {/* </div> */}
                            <NavItem>
                              <NavLink
                                className={classNames({
                                  active: activeTab === "4",
                                })}
                                onClick={() =>
                                  this.setState({ activeTab: "4" })
                                }
                              >
                                {t("talepHareketleri")}
                                {this.state.recentlyViewed.length > 0 && (
                                  <Badge
                                    pill
                                    color="warning"
                                    style={{
                                      top: -8,
                                      position: "absolute",
                                    }}
                                  >
                                    {this.state.recentlyViewed.length}
                                  </Badge>
                                )}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classNames({
                                  active: activeTab === "5",
                                })}
                                onClick={() =>
                                  this.setState({ activeTab: "5" })
                                }
                              >
                                {t("izleyiciler")}
                                {this.state.WatcherUsers.length > 0 && (
                                  <Badge
                                    pill
                                    color="warning"
                                    style={{
                                      top: -8,
                                      position: "absolute",
                                    }}
                                  >
                                    {this.state.WatcherUsers.length}
                                  </Badge>
                                )}
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col xl={4}>
                          <div
                            style={{
                              float: "right",
                            }}
                          >
                            <Button
                              style={{ backgroundColor: "aliceblue" }}
                              onClick={() => this.taskActions("aciklamaekle")}
                            >
                              {t("aciklamaDosyaEkle")}
                            </Button>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <TabContent
                            activeTab={activeTab}
                            style={{ marginTop: 0 }}
                          >
                            <TabPane tabId="1">
                              {BB.DxDataGridForTaskLogDescription(
                                this.state.taskLogDescription,
                                this,
                                isMobile
                              )}
                            </TabPane>

                            <TabPane tabId="2">
                              {BB.DxDataGridForTaskLog(
                                this.state.taskLog,
                                this
                              )}
                            </TabPane>

                            <TabPane tabId="3">
                              {BB.DxDataGridJobs(this.state.myJobs, this)}

                              {/* <Jobs
                                  taskID={this.state.taskID}
                                  isTaskDetail={true}
                              /> */}
                            </TabPane>

                            <TabPane tabId="4">
                              {BB.DxDataGridRecentlyViewed(
                                this.state.recentlyViewed,
                                this
                              )}
                            </TabPane>

                            <TabPane tabId="5">
                              {BB.DxDataGridWatcherUsers(
                                this.state.WatcherUsers,
                                this,
                                this.state.loginUser
                              )}
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </div>
                  </CustomCard>
                </Col>
              </Row>
            </Col>
            
            <AdditionalDescription t={t} event={this} />

            <TaskUpdateDescription t={t} event={this} />

            <TaskChangeUrgencyModal t={t} event={this} />

            <TaskTerminationModal t={t} event={this} />

            <AddJobModal t={t} event={this} />

            <TaskTransferModal t={t} event={this} />

            <TaskAddWatcher t={t} event={this} />

            <TaskViewDescription event={this} />

            <TaskAddDescription t={t} event={this} />

            <TaskChangeProcessStatus t={t} event={this} />

            <TaskViewForm t={t} event={this} />

            <TaskViewSheet t={t} event={this} />

            <TakeOverUser t={t} event={this} />
            <AdditionalConfirmationModal t={t} event={this} />

            <ChangeCategoryByTask t={t} event={this} />
          </Row>
        );
      } else {
        return (
          <div className="animated fadeIn pt-1 text-center">{t("yukleniyor")}</div>
        );
      }
    } else {
      if (
        this.state.loginUser.Role !== "d45342ab-14d1-e811-80f6-005056b839bb"
      ) {
        return <Redirect push to="/TaskTracking" />;
      } else {
        return <Redirect push to="/OwnTask" />;
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.taskReducer,
    loginUser: state.loginReducer,
    poolTasks: state.poolReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      //getPoolTaskCount: bindActionCreators(poolActions.getPoolCount, dispatch),
      isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
    },
  };
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskDetail))
);
