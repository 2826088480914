import React, { Component } from 'react';
import { CardBody, Col, Row, Button, Label } from 'reactstrap';
import { withTranslation } from "react-i18next";
import * as BB from "../../../../components/BBComponent";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import * as poolActions from "../../../../redux/actions/poolActions";
import { CustomCard } from "../../../../components/custom";
import jwt_decode from 'jwt-decode';
import Modal from "reactstrap/es/Modal";
import ModalHeader from "reactstrap/es/ModalHeader";
import ModalBody from "reactstrap/es/ModalBody";
import notify from "devextreme/ui/notify";



import {
    callAPI,
    callApiGet,
    callApiPost,
    callApiPut
} from "../../../../components/Axios";
class TaskPool extends Component {
    constructor(props) {
        super(props);

        var token = localStorage.getItem("token");
        var user = jwt_decode(token);

        this.state = {
            collapse: true,
            fadeIn: true,
            timeout: 50,
            poolTasks: [],
            currentTask: [],
            talepEden: '',
            firma: '',
            departman: '',
            gorev: '',
            kategori: '',
            aciklama: '',
            aciliyet: '',
            etki: '',
            showOwnTask: false,
            showDetail: false,
            gridData: [],
            loginUser: user,
            isAssignJob: false,
            poolUsers: [],
            selectedUserId: undefined,
            selectedMessage: '',
            taskID: undefined,
            jobResponseUser: undefined,
            buttonVisible: "block",
            acceptDelete: "none",
            myPools: undefined,
            selectedPools: "00000000-0000-0000-0000-000000000000",
            isMobile: window.innerWidth <= 768
        };
        let { t } = this.props;

        this.languages = {
            hata: t("hata"),
            onayVer: t("onayVer"),
            iptal: t("iptal"),
            bilgimdeOlanTalepler: t("bilgimdeOlanTalepler"),
            onayladıgımTalepler: t("onayladığımTalepler"),
            reddettiğimTalepler: t("reddettiğimTalepler"),
            oluşturduğumTalepler: t("oluşturduğumTalepler"),
            üzerimdekiTalepler: t("üzerimdekiTalepler"),
            ekibinÜzerindekiTalepler: t("ekibinÜzerindekiTalepler"),
            tamamlananTalepler: t("tamamlananTalepler"),
            firma: t("firma"),
            uygula: t("uygula"),
            vazgec: t("vazgec"),
            vazgec2: t("talepAciklamasi"),
            devral: t("devral"),
            tamam: t("tamam"),
            toplamTalepSayisi: t("toplamTalepSayisi"),
            talebinizBaşkaBirKullanıcıTarafındanDevranıldığıİçinBuTalepÜzerindeİptalİşlemiYapamazsınız: t("talebinizBaşkaBirKullanıcıTarafındanDevranıldığıİçinBuTalepÜzerindeİptalİşlemiYapamazsınız!"),
            Görevbaşarıylasilindi: t("Görevbaşarıylasilindi"),
            talebiDevralacaksiniz: t("talebiDevralacaksiniz"),
            onayAciklamasiGiriniz: t("onayAciklamasiGiriniz"),
            iptalAciklamasiGiriniz: t("iptalAciklamasiGiriniz"),
            onayBasariylaVerilmistir: t("onayBasariylaVerilmistir"),
            talepBasariylaIptalEdilmistir: t("talepBasariylaIptalEdilmistir"),
            talepBasariylaDevredildi: t("talepBasariylaDevredildi"),
            havuzAlaniZorunludur: t("havuzAlaniZorunludur"),
            durumAlaniZorunludur: t("durumAlaniZorunludur"),
            talepDurumDegistirmeBasarili: t("talepDurumDegistirmeBasarili"),
            aciklamaAlaniZorunludur: t("aciklamaAlaniZorunludur"),
            dosyaEklemeZorunludur: t("dosyaEklemeZorunludur"),
            hizliTalepOlusturAciklama: t("hizliTalepOlusturAciklama"),
            iptalAciklamasıGiriniz: t("iptalAciklamasiGiriniz"),
            toplamTalepSayisi: t("toplamTalepSayisi"),
            oluşturmaBaşlangıçTarihi: t("oluşturmaBaşlangıçTarihi"),
            kapanışBaşlangıçTarihi: t("kapanışBaşlangıçTarihi"),
            detaylıFiltre: t("detaylıFiltre"),
            kapanışBitişTarihi: t("kapanışBitişTarihi"),
            kapananlarıDahilEt: t("kapananlarıDahilEt"),
            oluşturmaBitişTarihi: t("oluşturmaBitişTarihi"),
            işlemDurumu: t("işlemDurumu"),
            talepDurumu: t("talepDurumu"),
            talepBasariylaSonlandirildi: t("talepBasariylaSonlandirildi"),
            ProjeNumarası: t("ProjeNumarası"),
            sonlandirmaAciklamasiAlaniZorunludur: t(
                "sonlandirmaAciklamasiAlaniZorunludur"
            ),
            talepBasariylaIptalEdilmistir: t("talepBasariylaIptalEdilmistir"),
            talebinAciliyetiBasariylaDegistirildi: t(
                "talebinAciliyetiBasariylaDegistirildi"
            ),
            talebinAciliyetiDegistirilirkenHataAlindi: t(
                "talebinAciliyetiDegistirilirkenHataAlindi"
            ),
            talepHavuuzu: t("talepHavuuzu"),
            görevatanamadi: t("görevatanamadi"),
            Görevbaşarıylaatandı: t("Görevbaşarıylaatandı"),
        };
    }







    componentDidMount() {
        this.getData();
        this.getMyPoolUser();
        this.getMyPools();
        window.addEventListener("resize", this.handleResize);
        this.handleResize();

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize); // Event listener'ı temizle
    }
    handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768  // Eğer ekran genişliği 768'den küçükse mobil olarak kabul et
        });
    }

    
    async getData() {

        this.props.actions.isLoading(true)
        await this.props.actions.getAllTaskInPools(this.state.loginUser.ID, this.state.selectedPools);
        this.props.actions.isLoading(false)
    }


    getMyPoolUser() {
        callAPI(
            callApiGet,
            '/api/TaskTracking/GetMyPoolUsers',
            {},
            (poolUserss) => {
                this.setState({ poolUsers: poolUserss.data })

            },
            true
        );

    }
    checkAssignAJob(ID) {
        callAPI(
            callApiGet,
            '/api/TaskTracking/CheckAssignAJob?TaskID=' + ID,
            {},
            (res) => {
                if (res?.data) {
                    this.setState({ jobResponseUser: res.data })
                    this.setState({ buttonVisible: "none" })

                    if (res?.data.IsWieverTaskCreator) {
                        this.setState({ acceptDelete: "block" })


                    }
                    else {
                        this.setState({ acceptDelete: "none" })

                    }

                }
                else {
                    this.setState({ buttonVisible: "block" })
                }



            },
            true
        );
    }
    getMyPools() {

        var url = 'api/TaskTracking/GetMyPool'
        callAPI(
            callApiGet,
            url,
            {},
            async (res) => {
                {
                    this.setState({ myPools: res.data });
                }

            })

    }
    deleteJob(ID) {
        callAPI(
            callApiGet,
            '/api/TaskTracking/DeleteJob?TaskID=' + ID,
            {},
            (res) => {
                if (res?.data) {
                    this.setState({ isAssignJob: false, selectedUserId: undefined, taskID: undefined, selectedMessage: "", jobResponseUser: undefined, acceptDelete: "none" })
                    notify(this.languages.Görevbaşarıylasilindi, 'success', 2000);

                }


            },
            true
        );
    }

    handleChange = (key, value) => {
        if (key === 'redirect') {
            this.props.history.push('/TaskDetail?' + value)
        }
        if (key === 'isLoading') {
            this.props.actions.isLoading(true);
        }
        if (key === 'selectedUserId') {

            this.setState({ selectedUserId: value })

        }
        if (key === 'selectedMessage') {

            this.setState({ selectedMessage: value })

        }
    }

    onToolbarPreparing = (e) => {
        let ths = this;
        let {
            showDetail,
            selectedMainDropdown,
            isLoading,
            selectedTaskCreateDateStart,
            selectedPools,
            myPools,
            selectedTaskCreateDateEnd,
        } = this.state;
        let toolbarItems = e.toolbarOptions.items;
        let totalRow = this.languages.toplamTalepSayisi;
        totalRow += ths.props.poolTasks.length;
        const isMobile = window.innerWidth <= 768;
        toolbarItems.push({
            widget: "dxSelectBox",
            options: {
                dataSource: myPools,
                valueExpr: "ID",
                displayExpr: "Name",
                value: selectedPools,
                onValueChanged: function (e) {
                    ths.setState({ selectedPools: e.value })
                    ths.getData()
                },
                width: isMobile ? 100 : 170,

                textColor: "white",
                validationstatus: isLoading === true ? "pending" : "valid",
            },
            location: "after",
        });

        //Toplam Checkbox
        toolbarItems.unshift({
            location: 'center',
            template: 'totalGroupCount'
        });

        //Toplam Checkbox
        toolbarItems.unshift({
            location: 'before',
            template: 'pageName'
        });
    }

    toolbarItemRender = (e) => {
        const isMobile = window.innerWidth <= 768;

        return (
            isMobile ? (
                <div style={{
                    width: '150px', height: '70px', textAlign: 'center', color: 'white', margin: '0px -350px 0px 90px', float: 'none'
                }}>
                    <span className="name">
                        {this.languages.toplamTalepSayisi} {this.props.poolTasks ? this.props.poolTasks.length : "0"}
                    </span>
                    <br />
                </div>
            ) : (
                <div className="informer">
                    <h3 className="count">{this.props.poolTasks.length}</h3>
                    <span className="name">{this.languages.toplamTalepSayisi}</span>
                </div>
            )
        );
    }

    addAssignAJob() {

        let jobDto = {
            TaskID: this.state.taskID,
            ResponsibleUserID: this.state.selectedUserId,
            Description: this.state.selectedMessage
        }
        let formData = new FormData();
        formData.append('jsonText', JSON.stringify(jobDto));
        this.props.actions.isLoading(true)
        callAPI(
            callApiPost,
            'api/TaskTracking/AddAssignAJob',
            formData,
            (res) => {
                if (res.data) {

                    this.getData();

                    this.setState({ isAssignJob: false })
                    notify(this.languages.Görevbaşarıylaatandı, 'success', 2000);
                    this.setState({ selectedUserId: undefined, taskID: undefined, selectedMessage: "", jobResponseUser: undefined, acceptDelete: "none" })
                    this.props.actions.isLoading(false)
                }
                else {
                    notify(this.languages.görevatanamadi, 'error', 2000);
                    this.props.actions.isLoading(false)
                }


            })
    }

    pageNameRender = (e) => {
        const isMobile = window.innerWidth <= 768;
        return (
            <div className="informer">
                <h5
                    className="count"
                    style={{
                        marginTop: 7,
                        marginLeft: 10,
                        display: isMobile ? 'none' : 'block'
                    }}
                >
                    <strong>{this.languages.talepHavuuzu}</strong>
                </h5>
            </div>
        );
    }


    render() {
        const { t } = this.props;
        const { isMobile } = this.state;
        return (
            <>
                <Row>
                    <Col xs="12" sm="12">
                        <CustomCard cardStyle='card-dashboard-calendar pb-0'>
                            <CardBody>
                                {BB.DxDataGridForOwnTaskPool(this.props.poolTasks, this, this.state.loginUser, this.state.showDetail, isMobile)}
                            </CardBody>
                        </CustomCard>
                    </Col>
                </Row>

                <Modal
                    style={{ zIndex: 1 }}
                    centered={true}
                    isOpen={this.state.isAssignJob}
                >
                    <ModalHeader>{t("görevAta")}</ModalHeader>
                    <ModalBody>

                        {BB.DxDropDownWithJSON(
                            "selectedUserId",
                            t("havuzKullanıcıları"),
                            this,
                            this.state.poolUsers
                        )}



                        {BB.TextAreaWithLabel('selectedMessage', t("mesaj"), t("mesaj"), 9, this)}

                        {/* <Row style={{ marginLeft: 0 }}>

           {this.state.jobResponseUser != undefined ? "Talep "+this.state.jobResponseUser.UserName+"' Kullanıcısına Görev Olarak Atanmıştır" :"" } 

           <div style={{display:this.state.acceptDelete}}>
           <Label
              color="danger"
             onClick={() => this.deleteJob(this.state.taskID)}
              className="ml-2"
              style={{cursor:"pointer",color:"red"}}
            >
             X
            </Label> 
                      </div>
                        </Row>  */}

                        <Row style={{ float: 'right', marginRight: 10 }}>
                            <Button style={{ marginRight: 7 }}
                                color={'info'}
                                onClick={() => this.setState({ isAssignJob: false, acceptDelete: "none", selectedUserId: undefined, taskID: undefined, selectedMessage: "", jobResponseUser: undefined })}>
                                {t("vazgec")}
                            </Button>
                            {/* <div style={{  display: this.state.buttonVisible}}> */}
                            <div>
                                <Button

                                    color={'success'}
                                    onClick={() => this.addAssignAJob()}


                                >{t("onayVer")}</Button></div>

                        </Row>

                    </ModalBody>
                </Modal>
            </>



        );
    }
}

function mapStateToProps(state) {
    return {
        loginUser: state.loginReducer,
        poolTasks: state.poolReducer
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
            getAllTaskInPools: bindActionCreators(poolActions.getAllPoolTask, dispatch),
        }
    }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TaskPool)))

