import React, {PureComponent} from 'react';
import * as BB from "../../../BBComponent";
import {Table} from "../../../custom";
import notify from "devextreme/ui/notify";
import {bindActionCreators} from "redux";
import * as loadingActions from "../../../../redux/actions/loadingActions";
import * as mainActions from "../../../../redux/actions/mainActions";
import * as taskActions from "../../../../redux/actions/taskActions";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getWatcherTasks} from "../../../../redux/actions/getWatcherTasksActions";

class LastCreatedTasks extends PureComponent
{
   constructor(props)
   {
      super(props);

      this.state = {
         getLast10TaskList: [],
         redirect: false,
         taskID: undefined
      };
   }

   componentDidMount() {
      this.props.actions.getWatcherTasks();
   }

   handleDetail(id)
   {
      this.props.props.history.push('/TaskDetail?' + id)
   }

   handleDelete(id)
   {
      this.props.actions.getWatcherTasks();
      notify('Başarılı', 'success', 2000);
   }

   render()
   {
       const { t } = this.props;
       const isMobile = window.innerWidth <= 768;

      return (
         <>
            {
               this.props.watcherTasks !== null && (
                  <Table
                     title={t("izleyicisiOlduğumTalepler")}
                     description={t("izleyicisiOldugumTaleplerAciklama")}
                     data={this.props.watcherTasks}
                     handleDetail={(id) => this.handleDetail(id)}
                     handleDelete={(id) => this.handleDelete(id)}
                     t={t}
                     isMobile={isMobile}
                  />
               )
            }

         </>
      )
   }

}

function mapStateToProps(state)
{
   return {
      watcherTasks: state.getWatcherTasksReducer,
   }
}

function mapDispatchToProps(dispatch)
{
   return {
      actions: {
         getWatcherTasks: bindActionCreators(getWatcherTasks, dispatch),
      }
   }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LastCreatedTasks)))
