import React, { Component } from "react";
import {
    Button,
    CardBody,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as BB from "../../../components/BBComponent";
import * as loadingActions from "../../../redux/actions/loadingActions";
import { bindActionCreators } from "redux";
import notify from "devextreme/ui/notify";
import { CustomCard } from "../../../components/custom";

import { post } from "axios";
import ReactHtmlParser from "react-html-parser";
import { callAPI, callApiGet } from "../../../components/Axios";
import { t } from "i18next";

let answers = [];

class Surveys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: true,
            fadeIn: true,
            timeout: 50,
            talepEden: "",
            firma: "",
            departman: "",
            gorev: "",
            kategori: "",
            aciklama: "",
            aciliyet: "",
            etki: "",
            gridData: [],
            editRowVisible: false,
            QuestionsAll: [],
            selectedTaskSurveyID: "",
            selectedTaskID: "",
            selectedTaskDescription: "",
            surveyRowLenght: 0,
            showDetail: true,
            selectedSurveyReply: [],
            isLoadingSurveyDetail: false,
            isAnswer: false,
            isMobile: window.innerWidth <= 768
        };

        this.changeSelectionPriority = this.changeSelectionPriority.bind(this);
        this.answer = this.answer.bind(this);
        this.gridRef = React.createRef();

        let { t } = this.props;

        // this.languages = {
        //   toplamTalepSayısı:t('toplamTalepSayısı')

        // }
    }


    handleResize = () => {
        this.setState({
            isMobile: window.innerWidth <= 768  // Eğer ekran genişliği 768'den küçükse mobil olarak kabul et
        });
    }


    componentDidMount() {
        this.getData();
    }

    getData() {
        //this.props.actions.isLoading(true);

        callAPI(
            callApiGet,
            "api/TaskSurvey/GetAllSurveys",
            {},
            (res) => {
                console.log("data", res);
                const data = res.data;
                this.setState({ gridData: data, selectedTaskID: data.TaskNumber });
            },
            true
        );
        // this.props.actions.isLoading(false);
    }

    getTaskID(TaskNumber) {
        BB.FetchJSON(
            "api/TaskDetail/GetTaskIDBYTaskNumber?TaskNumber=" + TaskNumber
        ).then((data) => {
            this.getTaskData(data.data);
        });
    }

    getTaskData(ID) {




        // BB.FetchJSON("api/TaskDetail/GetGridData?ID=" + ID).then((data) => {
        //   this.setState({ selectedTaskDescription: data.Description });
        // });


        let url = "api/TaskDetail/GetGridData?ID=" + ID;
        callAPI(
            callApiGet,
            url,
            {},
            async (res) => {
                this.setState({ selectedTaskDescription: res.data.Description });

            }
            ,
            true
        );

    }

    delete(ID) {
        this.props.actions.isLoading(true);
        BB.FetchJSON("api/TaskSurvey/Delete?ID=" + ID).then((data) => {
            if (data.MessageStatus === true) {
                this.setState({
                    editRowVisible: false,
                    editPoolVisible: false,
                    selectedName: "",
                });
                this.getData();
                this.props.actions.isLoading(false);
                notify("Anket Yoksayıldı!", "success", 1000);
            } else {
                this.setState({
                    editRowVisible: false,
                    editPoolVisible: false,
                    selectedName: "",
                });
                notify("Anket Silme Hata!", "warning", 2000);
                this.props.actions.isLoading(false);
            }
        });
    }

    answer(ID) {
        // this.props.actions.isLoading(true);
        //  callAPI(
        //   callApiGet,
        //   "api/TaskSurvey/TaskAnswer?ID=" + ID,
        //   {},
        //   async (res) => {
        //     const data = res.data;
        //     var questions = [];

        //     for (var i = 0; i < data.Survey.SurveyQuestion.length; i++) {
        //       answers.push({
        //         q: i,
        //         a: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
        //       });
        //       questions.push({
        //         Question: data.Survey.SurveyQuestion[i].Question,
        //         Answer1: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
        //         Answer2: data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
        //         Answer3: data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
        //         Answer4: data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
        //       });
        //     }
        //     this.setState({ QuestionsAll: questions });
        //     this.getTaskID(this.state.selectedTaskID.toString());    },
        //   true
        // );





        let url = "api/TaskSurvey/TaskAnswer?ID=" + ID;
        callAPI(
            callApiGet,
            url,
            {},
            async (res) => {
                if (res?.data) {
                    if (!res || !res.data || res.data.length < 1) return;

                    var questions = [];
                    for (var i = 0; i < res.data.Survey.SurveyQuestion.length; i++) {

                        questions.push({
                            Question: res.data.Survey.SurveyQuestion[i].Question,
                            Answer1: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
                            Answer2: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
                            Answer3: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
                            Answer4: res.data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
                        });
                    }
                    this.setState({ QuestionsAll: questions });
                    if (res.data.Survey.TaskSurveyReply.length > 0) {
                        for (let i = 0; i < res.data.Survey.TaskSurveyReply.length; i++) {
                            answers.push({
                                q: i,
                                a: res.data.Survey.TaskSurveyReply[i].Reply,
                            });
                            this.setState({ selectedSurveyReply: res.data.Survey.TaskSurveyReply, isLoadingSurveyDetail: true });
                        }
                    }
                }
            },
            true
        );



        // BB.FetchJSON("api/TaskSurvey/TaskAnswer?ID=" + ID).then((data) => {


        //   var questions = [];

        //   for (var i = 0; i < data.Survey.SurveyQuestion.length; i++) {
        //     /*  answers.push({
        //             q: i,
        //             a: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option
        //          }); */
        //     questions.push({
        //       Question: data.Survey.SurveyQuestion[i].Question,
        //       Answer1: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
        //       Answer2: data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
        //       Answer3: data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
        //       Answer4: data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
        //     });
        //   }
        //   this.setState({ QuestionsAll: questions });
        // });
    }





    // BB.FetchJSON("api/TaskSurvey/TaskAnswer?ID=" + ID).then((data) => {
    //   var questions = [];

    //   for (var i = 0; i < data.Survey.SurveyQuestion.length; i++) {
    //     answers.push({
    //       q: i,
    //       a: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
    //     });
    //     questions.push({
    //       Question: data.Survey.SurveyQuestion[i].Question,
    //       Answer1: data.Survey.SurveyQuestion[i].SurveyQuestionOption[0].Option,
    //       Answer2: data.Survey.SurveyQuestion[i].SurveyQuestionOption[1].Option,
    //       Answer3: data.Survey.SurveyQuestion[i].SurveyQuestionOption[2].Option,
    //       Answer4: data.Survey.SurveyQuestion[i].SurveyQuestionOption[3].Option,
    //     });
    //   }
    //   this.setState({ QuestionsAll: questions });
    //   this.getTaskID(this.state.selectedTaskID.toString());
    //  // this.props.actions.isLoading(false);
    // });



    changeSelectionPriority(e) {
        let q = e.target.id.slice(0, 1);
        let a = e.target.value;
        let reply = this.state.selectedSurveyReply;
        reply[q].Reply = a;

        this.setState({ selectedSurveyReply: reply });

        answers.map((item, index) => {
            if (q == item.q) {
                answers.splice(index, 1);
            }
        });
        answers.push({
            q: parseInt(q),
            a: a.trim(),
        });
    }


    onToolbarPreparing = (e) => {
        let ths = this;
        let toolbarItems = e.toolbarOptions.items;
        let totalRow = t("toplamTalepSayisi");
        //totalRow += ths.gridData.length;
        //Toplam Checkbox
        //  toolbarItems.unshift({
        //     location: 'before',
        //     template: 'pageName'
        //  });
        toolbarItems.unshift({
            location: "center",
            template: "totalGroupCount",
        });
        toolbarItems.unshift({
            location: "before",
            template: "pageName",
        });
    };

    toolbarItemRender = (e) => {
        const isMobile = window.innerWidth <= 768;
        return (
            isMobile ? (
                <div className="informer" style={{
                    width: '150px', height: '80px', textAlign: 'center', color: 'white', margin: '0px -120px 0px 155px', float: 'none'
                }}>                    
                    <span className="name" >{t("toplamAnketSayisi")} {":"} {this.state.gridData ? this.state.gridData.length : "0"}</span>
                </div>
            ) : (
                <div className="informer">
                    <h3 className="count" >
                        {this.state.gridData ? this.state.gridData.length : "0"}
                    </h3>
                    <span className="name" >{t("toplamAnketSayisi")}</span>
                </div>
            )
        );
    };
    pageNameRender = (e) => {
        return (
            <div className="informer">
                <h5 className="count" style={{ marginTop: 7, marginLeft: 10 }}>
                    <strong>{t("anketlerim")}</strong>
                </h5>
            </div>
        );
    };

    answerSave() {
        const formData = new FormData();

        formData.append("jsonText", JSON.stringify(answers));
        formData.append("TaskSurveyID", this.state.selectedTaskSurveyID);
        formData.append("TaskID", this.state.selectedTaskID);
        let answersLenght = JSON.stringify(answers).split("},{");


        let questionsAll = this.state.QuestionsAll;


        let lenght1 = answersLenght.length;
        let lenght2 = questionsAll.length;

        if (lenght1 === lenght2) {

            post("api/TaskSurvey/TaskAnswer", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            }).then((res) => {
                if (res.data.MessageStatus === true) {
                    this.setState({
                        editRowVisible: false,
                        addRowVisible: false,
                        selectedName: "",
                        selectedMasterPoolID: null,
                    });
                    this.getData(this.state.selectedParentID);

                    //this.props.actions.isLoading(false);
                    notify("Anket Tamamlandı!", "success", 1000);
                    answers = [];
                } else {
                    this.setState({
                        editRowVisible: false,
                        addRowVisible: false,
                        selectedName: "",
                        selectedMasterPoolID: null,
                    });
                    notify("Anket Oluşturma Hata! : " + res.Message, "warning", 1000);
                    // this.props.actions.isLoading(false);
                }
            });
        }

        else {
            notify("Anket Oluşturma Hata! : Tüm Soruları Cevaplamanız Gerekmektedir. ", "warning", 1000);

        }
    }

    actionButton = (cellData) => {
        console.log("cellData", cellData);
        //debugger
        return (
            <div>
                {/*{data.map((item, index) => (*/}
                {/*    <div key={index}>*/}
                {/*        {item.IsCompleted ?*/}
                {cellData.data.IsCompleted ?
                    <Button
                        style={{ marginLeft: 5 }}
                        color={"info"}
                        outline
                        onClick={() => {
                            this.setState(
                                {
                                    editRowVisible: true,
                                    selectedTaskSurveyID: cellData.data.ID,
                                    selectedTaskID: cellData.data.TaskNumber,
                                },
                                this.answer(cellData.data.ID)
                            );
                        }}
                    >
                        {t("duzenle")}
                    </Button>
                    :
                    <Button
                        style={{ marginLeft: 5 }}
                        color={"info"}
                        outline
                        onClick={() => {
                            this.setState(
                                {
                                    editRowVisible: true,
                                    selectedTaskSurveyID: cellData.data.ID,
                                    selectedTaskID: cellData.data.TaskNumber,
                                },
                                this.answer(cellData.data.ID)
                            );
                        }}
                    >
                        {t("cevapla")}
                    </Button>
                }
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}
            </div>
        );
    };


    render() {
        return <Render ths={this} />;
    }
}

export const Render = (ths) => {
    const { t } = useTranslation();
    let state = ths.ths.state;
    const event = ths.ths;
    const { isMobile } = ths.ths.state;
    return (
        <>
            <Row>
                <Col xs="12" sm="12">
                    <CustomCard cardStyle="card-dashboard-calendar pb-0">
                        <CardBody>
                            {BB.DxDataGridForSurvey(
                                ths.ths.state.gridData,
                                event,
                                state.showDetail, isMobile
                            )}
                        </CardBody>
                    </CustomCard>
                </Col>
                <Modal isOpen={state.editRowVisible} centered={true}>
                    <ModalHeader toggle={() => event.setState({ editRowVisible: false })}>
                        {`${state.selectedTaskID} numaralı talep anketi`}
                    </ModalHeader>
                    <ModalHeader>
                        {t("talepAciklamasi")}
                        <p style={{ fontWeight: "lighter" }}>
                            {ReactHtmlParser(state.selectedTaskDescription)}
                        </p>
                    </ModalHeader>

                    <ModalBody>
                        <div className="survey-list">
                            {state.QuestionsAll.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <p>
                                            <span className="survey-index">{index + 1}</span>
                                            {item.Question}
                                        </p>

                                        <div className="rating">
                                            <input
                                                type="radio"
                                                id={`${index}-star4`}
                                                name={`${index}-rating`}
                                                value={item.Answer1}
                                                onClick={event.changeSelectionPriority}
                                                checked={
                                                    state.selectedSurveyReply.length > 0
                                                        ? state.selectedSurveyReply[index].Reply.trim() === item.Answer1.trim()
                                                        : false
                                                }
                                            />
                                            <label
                                                className="star"
                                                htmlFor={`${index}-star4`}
                                                title={item.Answer1}
                                                aria-hidden="true"
                                            >
                                                {" "}
                                            </label>
                                            <input
                                                type="radio"
                                                id={`${index}-star3`}
                                                name={`${index}-rating`}
                                                value={item.Answer2}
                                                onClick={event.changeSelectionPriority}
                                                checked={
                                                    state.selectedSurveyReply.length > 0
                                                        ? state.selectedSurveyReply[index].Reply.trim() === item.Answer2.trim()
                                                        : false
                                                }
                                            />
                                            <label
                                                className="star"
                                                htmlFor={`${index}-star3`}
                                                title={item.Answer2}
                                                aria-hidden="true"
                                            >
                                                {" "}
                                            </label>
                                            <input
                                                type="radio"
                                                id={`${index}-star2`}
                                                name={`${index}-rating`}
                                                value={item.Answer3}
                                                onClick={event.changeSelectionPriority}
                                                checked={
                                                    state.selectedSurveyReply.length > 0
                                                        ? state.selectedSurveyReply[index].Reply.trim() === item.Answer3.trim()
                                                        : false
                                                }
                                            />
                                            <label
                                                className="star"
                                                htmlFor={`${index}-star2`}
                                                title={item.Answer3}
                                                aria-hidden="true"
                                            >
                                                {" "}
                                            </label>
                                            <input
                                                type="radio"
                                                id={`${index}-star1`}
                                                name={`${index}-rating`}
                                                value={item.Answer4}
                                                onClick={event.changeSelectionPriority}
                                                checked={
                                                    state.selectedSurveyReply.length > 0
                                                        ? state.selectedSurveyReply[index].Reply.trim() === item.Answer4.trim()
                                                        : false
                                                }
                                            />
                                            <label
                                                className="star"
                                                htmlFor={`${index}-star1`}
                                                title={item.Answer4}
                                                aria-hidden="true"
                                            >
                                                {" "}
                                            </label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => event.answerSave()}
                            color={"primary"}
                            style={{ marginLeft: 20, marginRight: 0 }}
                        >
                            {t("kaydet")}
                        </Button>
                    </ModalFooter>
                </Modal>
            </Row>
        </>
    );
};

function mapStateToProps(state) {
    return {
        ownsurveys: state.surveyReducer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            isLoading: bindActionCreators(loadingActions.setLoading, dispatch),
        },
    };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Surveys));

