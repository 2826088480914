import React from "react";
import './styles.scss'
import {Badge, CustomCard, Empty, LabelGroup} from "../index";
import {Link} from "react-router-dom";
import * as BB from '../../BBComponent';

const {htmlToText} = require('html-to-text');

const Table = ({ title, description, data = [], handleDetail, handleDelete, t, isMobile}) =>
{
   return (
      <CustomCard cardStyle='mg-b-20'>
         <LabelGroup
            title={title}
            description={description}
         />
         {
            data.length > 0 ? (
               <div className="table-responsive tasks" style={{height: 350, overflowY: 'scroll'}}>
                  <table className="table card-table table-vcenter text-nowrap mb-0  border">
                           <thead>
                               <tr>
                                   <th className="wd-lg-10p text-center hidden-mobile">No</th>
                                   {!isMobile && (
                                       <>
                                           <th className="wd-lg-10p">{t("durum")}</th>
                                           <th className="wd-lg-10p">{t("kategori")}</th>
                                           <th className="wd-lg-10p text-center">{t("havuz")}</th>
                                       </>
                                   )}
                                   <th className="wd-lg-40p">{t("açıklama")}</th>
                                   {!isMobile && (
                                       <> <th className="wd-lg-10p hidden-mobile">{t("islemler")}</th></>
                                   )}
                               </tr>
                           </thead>
                           <tbody>
                               {
                                   data.map((item, inx) =>
                                   {
                                       let a = htmlToText(item.Description).replaceAll('//', '/');
                                       let str = a.length > 0 ? a.split('[')[0] : '';
                                       let end = a.length > 0 ? a.split(']').length > 0 ? a.split(']')[1] : '' : '';
                                       return (
                                           <tr key={inx}>
                                               <td className="text-center">
                                                   <Link to={'/TaskDetail?' + item.ID}>
                                                       {item.TaskNumber}
                                                   </Link>
                                               </td>
                                               {!isMobile && (
                                                   <>
                                                       <td>
                                                           <Badge title={item.StatusName} status={item.StatusName} />
                                                       </td>
                                                       <td className="font-weight-semibold d-flex">
                                                           <span className="mt-1">{item.CategoryName}</span>
                                                       </td>
                                                       <td className="text-nowrap text-center">
                                                           <span className="mt-1">{item.PoolName}</span>
                                                       </td>
                                                 
                                                       <td
                                                           className={`${item.DegreeOfUrgency === 0 ? 'text-primary' : (item.DegreeOfUrgency === 1 ? 'text-warning' : 'text-danger')}`}>
                                                           {str.toString().substr(0, 80)}
                                                       </td>
                                                     </>
                                               )}
                                               {!isMobile ? (
                                               <td>
                                                   <button
                                                       className='ml-2 btn btn-danger taskButton mb-1 mr-2'
                                                       onClick={() =>
                                                       {
                                                           BB.askYesNo(t("eminMisiniz"), t("talebintakibibırakılacaktır"), t("evet"), t("no")).then(res =>
                                                           {
                                                               if (res === true)
                                                               {
                                                                   BB.FetchJSON('api/Dashboard/DeleteMyWatcherTasks?ID=' + item.ID).then(res =>
                                                                   {
                                                                       handleDelete(true);
                                                                   })
                                                               }
                                                           })
                                                       }}
                                                   >
                                                       {t("takibiiptalet")}
                                                   </button>
                                                   </td>
                                               ) : (
                                                   // Mobil görünümde butonu açıklamadan farklı konumlandırmak için:
                                                       <td colSpan="5">
                                                           <div className="d-flex justify-content-between">
                                                               <span>{str.toString().substr(0, 80)}</span>
                                                           </div>
                                                       </td>
                                               )}
                                           </tr>
                                       )
                                   })
                               }
                           </tbody>
                       </table>
                   </div>
               ) : (
                       <Empty title={t("Herhangibirveriyok")} />
               )
           }
       </CustomCard>
    )
}

export default Table;
